import * as React from 'react';
import { ChangeEvent, Component } from 'react';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';
import './VirtualGridIndexDisplay.scss';
import DynamicInputField from "common/components/DynamicInputField/DynamicInputField";

interface VGIndexDisplayProps {
    startIndex: number;
    numItems: number;
    endIndex: number;
    onValueChange?: (val: number) => void;
}

interface VGIndexDisplayState {
    value: number;
}

export default class VirtualGridIndexDisplay extends Component<
    VGIndexDisplayProps,
    VGIndexDisplayState
> {
    private inputBouncer = new DebouncingExecutor();

    constructor(props: VGIndexDisplayProps) {
        super(props);
        const { startIndex } = props;
        this.state = {
            value: startIndex,
        };
    }

    componentDidUpdate(prevProps: VGIndexDisplayProps) {
        const { startIndex } = this.props;
        if (prevProps.startIndex !== startIndex) {
            this.setState({
                value: startIndex,
            });
        }
    }

    inputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const { onValueChange } = this.props;
        const match = /^(\d)*$/.test(value);
        if (match) {
            const numVal: number = +value;
            this.setState({
                value: numVal,
            });
            this.inputBouncer.bufferedExecute(() => {
                onValueChange(numVal - 1);
            }, 500);
        }
    };

    onClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const field = event.target as HTMLInputElement;
        field.select();
    };

    goToTop = () => {
        const { onValueChange } = this.props;
        onValueChange(0);
    }
    render() {
        const { endIndex, numItems } = this.props;
        const { value } = this.state;
        return (
            <div styleName="vgIndexDisplay">
                Items shown
                <div>
                    <DynamicInputField
                        type="number"
                        value={value}
                        onChange={this.inputChange}
                        onClick={this.onClick}
                    />
                    to: <span>{`${endIndex}`}</span>
                </div>
                <div>
                    of:<span>{`${numItems}`}</span>
                </div>
                <button onClick={this.goToTop}>Go to TOP</button>
            </div>
        );
    }
}
