import {
    getMonthAgo,
    getTodaysDate,
    getWeekAgo,
    getYearAgo,
    yearRange,
} from 'common/components/LibraryController/FilteringController/FilterConfigs/DateRangeFunctions';
import {
    filtersPerLabel,
    readFilterConfigs,
} from 'common/components/LibraryController/FilteringController/FilterConfigs/FilterConfigs';

export const suggestsPerAvailableFilter = {
    Site: {
        filter: {
            name: 'Site',
            type: 'STRING',
        },
        suggests: [
            {
                values: ['Eporner', null],
                label: 'Eporner',
            },
            {
                values: ['Pornhub', null],
                label: 'Pornhub',
            },
            {
                values: ['xVideos', null],
                label: 'xVideos',
            },
        ],
    },
    Quality: {
        filter: {
            name: 'Quality',
            type: 'INT',
        },
        suggests: [
            {
                values: [719, 10000000],
                label: 'HD',
            },
        ],
    },
    Duration: {
        filter: {
            name: 'Duration',
            type: 'TIME',
        },
        suggests: [
            {
                values: [60, 600],
                label: '1-10mins',
            },
            {
                values: [600, 1800],
                label: '10-30mins',
            },
            {
                values: [1800, 14400],
                label: '30mins+',
            },
        ],
    },
    'Date Created': {
        filter: {
            name: 'Date Created',
            type: 'DATE',
        },
        suggests: [
            {
                values: [getWeekAgo(), getTodaysDate()],
                label: 'past week',
            },
            {
                values: [getMonthAgo(), getTodaysDate()],
                label: 'past month',
            },
            {
                values: [getYearAgo(), getTodaysDate()],
                label: 'past year',
            },
            yearRange(4, 4, true),
            yearRange(10, 6, true),
            // yearRange(4, 2, true),
            // yearRange(7, 3, true),
        ],
    },
    'Average Date Created': {
        filter: {
            name: 'Average Date Created',
            type: 'DATE',
        },
        suggests: [
            {
                values: [getYearAgo(), getTodaysDate()],
                label: 'newest',
            },
        ],
    },
    'Total Videos': {
        filter: {
            name: 'Total Videos',
            type: 'INT',
        },
        suggests: [
            {
                values: [1, 5],
                label: '<5vids',
            },
            {
                values: [5, 15],
                label: '5-15vids',
            },
            {
                values: [15, 100000],
                label: '15+vids',
            }
        ],
    },
};

export const filterConfigs = readFilterConfigs(suggestsPerAvailableFilter);

export const filterConfigsPerLabel = filtersPerLabel(filterConfigs);
