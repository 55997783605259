const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function formatViews(views: number | string): string {
    views = +views;
    if (views < 1000) return `${views}`;
    if (views < 1000000) return `${Math.round(views / 100) / 10}K`;
    if (views < 1000000000) return `${Math.round(views / 100000) / 10}M`;
    return `${Math.round(views / 100000000) / 10}B`;
}

export function formatDate(date: string): string {
    const d = new Date(date);
    let month = d.toLocaleString('en-us', { month: 'short' });
    if (month.length > 5) {
        month = months.find((monthVal) => {
            return month.indexOf(monthVal) > -1;
        });
    }
    return `${month}, ${d.getFullYear()}`;
}
