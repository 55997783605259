export function forLoopIt(n: number, apply: (i: number) => void): void {
    Array(n)
        .fill(0)
        .forEach((_, i) => apply(i));
}

export function forLoopGen<T>(n: number, map: (i: number) => T): Array<T> {
    return Array(n)
        .fill(0)
        .map((_, i) => map(i));
}

export function randomInt(upTo: number) {
    return Math.floor(Math.random() * upTo);
}

export function floorInStepsOf(num: number, step: number) : number {
    return Math.floor(num/step) * step;
}