import * as React from 'react';
import { Component, createRef, ReactNode } from 'react';
import './LinkWithPreview.scss';
import AppLink from 'common/components/AppLink/AppLink';
import { AppAction } from 'adult/state/AdultappStateStore';
import { sendRequest } from 'adult/components/ApiComs/ClientApiComs';
import { calcPreviewPosition } from 'common/components/LinkWithPreview/PreviewPosCalc';
import { connect } from 'react-redux';
import {
    contentToDom,
    getImageDims,
    getThumbnail,
    PgoMediaItemData,
} from 'adult/components/PgoMediaItem/PgoMediaItem';
import { PreviewParams, setPreviewParams } from 'adult/state/LayoutState/LayoutState';

interface LinkWithPreivewProps {
    href: string;
    stateAction: AppAction;
    itemPath: string;
    children?: ReactNode;
    setPreviewParams: (p: PreviewParams) => void;
}

const previewsCache: Record<string, unknown> = {};

export class LinkWithPreviewComponent extends Component<LinkWithPreivewProps> {
    private isCancelled = false;

    private isRunning = false;

    private ref = createRef<HTMLAnchorElement>();

    private hasTouchStarted = false;

    componentWillUnmount() {
        this.mouseLeave();
    }

    fetchItem = async (callback: (i: PgoMediaItemData) => void) => {
        const { itemPath } = this.props;
        const promise = new Promise((resolve, reject) => {
            const data = previewsCache[itemPath];
            if (data) {
                resolve(data);
            } else {
                sendRequest('item', { itemPath }, (res) => {
                    previewsCache[itemPath] = res;
                    resolve(res);
                });
            }
        });
        const res = await promise;
        callback(res as PgoMediaItemData);
    };

    mouseEnter = () => {
        if (this.hasTouchStarted) {
            this.touchStart = false;
            return;
        }
        const { setPreviewParams } = this.props;
        const { current } = this.ref;
        if (current) {
            this.isCancelled = false;
            this.isRunning = true;
            this.fetchItem((res: PgoMediaItemData) => {
                if (!this.isCancelled) {
                    const shouldBeVisibleDims = current.getBoundingClientRect();
                    const areaDims = { width: window.innerWidth, height: window.innerHeight };
                    const itemDims = getImageDims(getThumbnail(res));
                    const mult = 1;
                    itemDims.width *= mult;
                    itemDims.height *= mult;
                    const dims = calcPreviewPosition(areaDims, shouldBeVisibleDims, itemDims);
                    setPreviewParams({
                        style: dims,
                        content: contentToDom(res),
                    });
                }
            });
        }
    };

    mouseLeave = () => {
        const { setPreviewParams } = this.props;
        if (this.isRunning) {
            this.isCancelled = true;
            setPreviewParams(null);
            this.isRunning = false;
        }
    };

    touchStart = () => {
        this.hasTouchStarted = true;
    };

    render() {
        const { href, stateAction, children } = this.props;
        return (
            <AppLink
                href={href}
                stateAction={stateAction}
                onMouseEnter={this.mouseEnter}
                onMouseLeave={this.mouseLeave}
                onTouchStart={this.touchStart}
                htmlRef={this.ref}
            >
                {children}
            </AppLink>
        );
    }
}

const mapDispatchToProps = {
    setPreviewParams,
};

const LinkWithPreview = connect(null, mapDispatchToProps)(LinkWithPreviewComponent);

export default LinkWithPreview;
