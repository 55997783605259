import * as React from 'react';
import { Component, createRef } from 'react';
import './SlideOutMenu.scss';
import Observable from 'common/functions/Observable/Observable';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';

function isScrollDown(scroll: number, scrolBefore: number, scrollBefore2: number): boolean {
    if (scroll > scrolBefore && scrolBefore > scrollBefore2) {
        return true;
    }
    if (scroll < scrolBefore && scrolBefore < scrollBefore2) {
        return false;
    }
    return false;
}

interface SlideOutMenuProps {
    scrollStream: Observable<number>;
    actionListener?: (action: SlideOutMenuActions) => void;
    shouldCollapse: () => boolean;
}

interface SlideOutMenuState {
    isShown: boolean;
}

export enum SlideOutMenuActions {
    HideByScroll,
    ShowByScroll,
    HideByMouse,
    ShowByMouse,
}

export default class SlideOutMenu extends Component<SlideOutMenuProps, SlideOutMenuState> {
    private ref = createRef<HTMLDivElement>();

    private lastScroll: number;

    private scrollBeforeLast: number;

    private touchStarted: boolean;

    private isMouseOver = false;

    private isShowingDueToMouse = false;

    private isShowingDueToScroll = false;

    private mouseEventDebouncer = new DebouncingExecutor();

    constructor(props: SlideOutMenuProps) {
        super(props);
        this.state = {
            isShown: true,
        };
        props.scrollStream.subscribe((val) => {
            // console.log(val)
            if (this.lastScroll && this.scrollBeforeLast) {
                const shouldHide = isScrollDown(val, this.lastScroll, this.scrollBeforeLast);
                const { shouldCollapse } = this.props;
                if (shouldHide && shouldCollapse) {
                    this.isShowingDueToScroll = false;
                    this.hide();
                    this.actionListen(SlideOutMenuActions.HideByScroll);
                } else {
                    this.isShowingDueToScroll = true;
                    this.show();
                    this.actionListen(SlideOutMenuActions.ShowByScroll);
                }
            }
            this.scrollBeforeLast = this.lastScroll;
            this.lastScroll = val;
        });
        if (typeof window !== 'undefined') {
            window.addEventListener('touchstart', () => {
                this.touchStarted = true;
            });
            window.addEventListener('mousemove', (event) => {
                this.mouseEventDebouncer.bufferedExecute(
                    () => {
                        if (this.ref.current && !this.touchStarted) {
                            this.touchStarted = false;
                            const box = this.ref.current.getBoundingClientRect();
                            if (
                                box.left < event.clientX &&
                                box.left + box.width > event.clientX &&
                                box.top - 75 < event.clientY &&
                                box.top + box.height + 150 > event.clientY
                            ) {
                                this.isShowingDueToMouse = true;
                                this.show();
                                this.actionListen(SlideOutMenuActions.ShowByMouse);
                            } else {
                                if (this.isShowingDueToMouse) {
                                    this.isShowingDueToMouse = false;
                                    this.hide();
                                }
                                const { shouldCollapse } = this.props;
                                if (
                                    this.lastScroll &&
                                    this.lastScroll !== 0 &&
                                    !this.isMouseOver &&
                                    shouldCollapse()
                                ) {
                                    this.actionListen(SlideOutMenuActions.HideByMouse);
                                }
                            }
                        }
                    },
                    250,
                    true,
                );
            });
        }
    }

    actionListen = (action: SlideOutMenuActions) => {
        const { actionListener } = this.props;
        if (actionListener) {
            actionListener(action);
        }
    };

    show = () => {
        if (!this.state.isShown) {
            this.setState((prevState) => {
                return { ...prevState, isShown: true };
            });
        }
    };

    hide = () => {
        if (this.state.isShown && this.lastScroll && this.lastScroll !== 0 && !this.isMouseOver) {
            this.setState((prevState) => {
                return { ...prevState, isShown: false };
            });
        }
    };

    onMouseEnter = () => {
        this.isMouseOver = true;
    };

    onMouseLeave = () => {
        setTimeout(() => {
            this.isMouseOver = false;
        }, 1); // timeout needed to work with touch end on mobiles
    };

    render() {
        const { children, shouldCollapse } = this.props;
        const { isShown } = this.state;
        const style = isShown ? {} : { style: { transform: `translateY(-100%)` } };
        const styleName = `slideOutMenu${shouldCollapse() ? '' : ' nosticky'}`;
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div
                styleName={styleName}
                ref={this.ref}
                {...style}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onTouchEnd={this.onMouseLeave}
            >
                {children}
            </div>
        );
    }
}
