import { applyMiddleware, combineReducers, createStore } from 'redux';
import undoable from 'redux-undo';
import {
    initialLibraryBrowserState,
    LibBrowserNoneUndoableActionHandlers,
    LibraryBrowserActions,
    LibraryBrowserState,
    LibraryBrowserStateActionHandlers,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import {
    ContentViewerActionHandlers,
    ContentViewerState,
    ContentViewerStateActions,
    initialContentViewerState,
} from 'adult/state/ContentViewerState/ContentViewerState';
import {
    initialLayoutState,
    LayoutState,
    LayoutStateActions,
    LayoutStateReducers,
} from 'adult/state/LayoutState/LayoutState';
import thunk from 'redux-thunk';
import {AdsState, AdsStateActionHandlers, AdsStateActions, initialAdsState} from "adult/state/AdsState";

const initialState: AppState = {
    ...initialLibraryBrowserState,
    ...initialContentViewerState,
    ...initialLayoutState,
    ...initialAdsState,
};

export type AppState = LibraryBrowserState | ContentViewerState | LayoutState | AdsState;

const actionHandlers = { ...LibraryBrowserStateActionHandlers, ...ContentViewerActionHandlers };

const noneUndoableActionHandlers = {
    ...LibBrowserNoneUndoableActionHandlers,
    ...LayoutStateReducers,
    ...AdsStateActionHandlers,
};

export type AppAction = LibraryBrowserActions | ContentViewerStateActions | LayoutStateActions | AdsStateActions;

export function topReducer(state = initialState, action: AppAction): AppState {
    const reducer = actionHandlers[action.type];
    if (reducer) {
        return reducer(state, action);
    }
    return state;
}

export function noneUndoableReducer(state = initialState, action: AppAction): AppState {
    const reducer = noneUndoableActionHandlers[action.type];
    if (reducer) {
        return reducer(state, action);
    }
    return state;
}

export const rootReducer = combineReducers({
    undoable: undoable(topReducer),
    notUndoable: noneUndoableReducer,
});
const pgoStore = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;

export default pgoStore;
