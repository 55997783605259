import { Dims } from 'common/types/AppTypes';
import {
    getNumPerRow,
    IndicesInView
} from 'common/components/VirtualGrid/VirtualGridCanvas/calculators/VirtualGridCanvasCalculators';

export function calcuSlidingViewItemDims(itemDims: Dims, viewWidth: number): Dims {
    const numPerRow = getNumPerRow(viewWidth, itemDims);
    const itemWidth = viewWidth / numPerRow;
    const itemHeight = (itemWidth / itemDims.width) * itemDims.height;
    return { width: itemWidth, height: itemHeight };
}

export function calcSlidingViewIndiciesInView(
    scroll: number,
    viewPortLength: number,
    itemLength: number,
) {
    // console.log("here")
    const numItemsOnLeft = Math.floor(scroll / itemLength);
    const numItemsToRightBoundry = Math.ceil((scroll + viewPortLength) / itemLength);
    const topItemHidden = scroll % itemLength;
    return { startIndex: numItemsOnLeft, numToTake: numItemsToRightBoundry - numItemsOnLeft , autoplayIndex: topItemHidden/itemLength > 0.2 ? numItemsOnLeft + 1 : numItemsOnLeft};
}

export function calcSlidingViewIndiciesInViewNew(
    scroll: number,
    viewPortLength: number,
    itemLength: number,
) :IndicesInView {
    const ans = calcSlidingViewIndiciesInView(scroll, viewPortLength, itemLength);
    ans.contentNum = ans.numToTake;
    ans.numPerRow = 1;
    delete ans.numToTake;
    return ans;
}

export function calculateSlidingScrollPos(index: number, itemWidth: number){
    // ret
}