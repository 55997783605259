import VirtualGridDataGetterCache, {
    VGContentRequest,
    VGContentResponse,
} from 'common/components/VirtualGrid/VirtualGridDataGetterCache/VirtualGridDataGetterCache';
import { sendRequest } from 'adult/components/ApiComs/ClientApiComs';
import { HomePageSlidingView } from 'adult/components/ApiComs/ApiTypes';
import { InitialGridData } from 'common/components/VirtualGrid/VirtualGridDataGetter/VirtualGridDataGetter';
import { IndicesInView } from 'common/components/VirtualGrid/VirtualGridCanvas/calculators/VirtualGridCanvasCalculators';

export class HomePageCachedDataGetter {
    private homeViewPathToNumItemsCache: Record<string, number> = {};
    // private lastKey =

    private viewItemsGetter = (
        key: VGContentRequest,
        callback: (res: VGContentResponse) => void,
    ) => {
        sendRequest('home-view-items', key, (res) => {
            callback({
                content: (res as unknown) as Array<Record<string, unknown>>,
                numItems: this.homeViewPathToNumItemsCache[key.homeViewPath],
                dims: null,
            });
        });
    };

    private cachedViewItemsGetter = new VirtualGridDataGetterCache(this.viewItemsGetter, 10);

    // private viewGetter = (key: VGContentRequest, callback: (res: VGContentResponse) => void) => {
    //     sendRequest('home-views', key, (res) => {
    //         const views = res.homePageSlidingViews as Array<HomePageSlidingView>;
    //         views.forEach((view) => {
    //             this.homeViewPathToNumItemsCache[view.path] = view.numItems;
    //         });
    //         callback({
    //             content: (views as unknown) as Array<Record<string, unknown>>,
    //             numItems: res.maxNum,
    //             dims: null,
    //         });
    //     });
    // };

    // private cachedViewsGetter = new VirtualGridDataGetterCache(this.viewGetter, 10);

    // getViews = (key: VGContentRequest, consumer: (r: VGContentResponse) => void) => {
    //     this.cachedViewsGetter.getContent(key, consumer);
    // };

    getViewItems = (key: VGContentRequest, consumer: (r: VGContentResponse) => void) => {
        this.cachedViewItemsGetter.getContent(key, consumer);
    };

    addInitialViewData = (views: Array<HomePageSlidingView>, key: VGContentRequest) => {
        const initialData: InitialGridData<VGContentRequest> = {
            dataKey: key,
            content: (views as unknown) as Array<Record<string, unknown>>,
            numItems: 500,
            dims: null,
        };
        this.cachedViewItemsGetter.addInitialData(initialData);
    };

    getInitialData = () => {
        return {};
    };

    onDataChange = (callback) => {};

    getItemsAsync = async (indices: IndicesInView) => {};
}
