import * as React from 'react';
import { Component } from 'react';
import './FilterConfigsPanel.scss';
import { DataItemField, FilterCriteria } from 'common/types/AppTypes';
import { connect } from 'react-redux';
import { RootState } from 'adult/state/AdultappStateStore';
import {
    addFilter,
    LibraryBrowserState,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { getFilterCriteria } from 'common/components/LibraryController/FilteringController/FilterConfigs/FilterConfigs';
import { suggestsPerAvailableFilter } from 'adult/confg/FilterConfigs';
import AppLink from 'common/components/AppLink/AppLink';
import { addFilterM } from 'common/components/AppLink/MemoActionCreators';
import { LayoutState } from 'adult/state/LayoutState/LayoutState';
import EpLogo from 'adult/components/logos/EpLogo/EpLogo';
import PhLogo from 'adult/components/logos/PhLogo/PhLogo';
import XvLogo from 'adult/components/logos/XvLogo/XvLogo';

interface FilterConfigsPanelProps {
    appliedFilters: Array<FilterCriteria<unknown>>;
    availableFilters: Array<DataItemField>;
    addFilter: (f: FilterCriteria<unknown>) => void;
    isMobile: boolean;
}

function labelToComp(label: string) {
    let siteIcon = null;
    switch (label) {
        case 'Eporner':
            siteIcon = <EpLogo />;
            break;
        case 'xVideos':
            siteIcon = <XvLogo />;
            break;
        case 'Pornhub':
            siteIcon = <PhLogo />;
            break;
        default:
            siteIcon = label;
    }
    return siteIcon;
}

export class FilterConfigsPanelComponent extends Component<FilterConfigsPanelProps> {
    render() {
        const { appliedFilters, availableFilters, isMobile } = this.props;
        if (!availableFilters) return <></>;
        const filterConfigs = getFilterCriteria(
            availableFilters,
            appliedFilters,
            suggestsPerAvailableFilter,
        );
        const label = filterConfigs.label === 'Eporner' ? <EpLogo /> : filterConfigs.label;
        return filterConfigs
            .filter((config) => (!config.notMobile && isMobile) || !isMobile)
            .map((filterConfig) => (
                <AppLink
                    key={filterConfig.label}
                    stateAction={addFilterM(filterConfig)}
                    stateActionLink
                >
                    {labelToComp(filterConfig.label)}
                </AppLink>
            ));
    }
}

const mapStateToProps = (state: RootState) => {
    const undoable = state.undoable.present as LibraryBrowserState;
    const noneUndoable = state.notUndoable as LibraryBrowserState;
    const layout = state.notUndoable as LayoutState;

    return {
        appliedFilters: undoable.libraryBrowserDataKey.filters || [],
        availableFilters: noneUndoable.libBrowserConfig.availableFilters,
        isMobile: layout.isMobile,
    };
};

const mapDispatchToProps = {
    addFilter,
};

const FilterConfigsPanel = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterConfigsPanelComponent);

export default FilterConfigsPanel;
