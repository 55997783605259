import {
    LibDataKey,
    LibraryBrowserState,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { libraryPathSeperator } from 'adult/confg/AppConfig';
import { FilterCriteria, HasId, Sort, VideoItem } from 'common/types/AppTypes';
import { RootState } from 'adult/state/AdultappStateStore';
import { ContentViewerState } from 'adult/state/ContentViewerState/ContentViewerState';

export function getPathString(path: string) {
    if (path) {
        path = path
            .split(libraryPathSeperator)
            .filter((s) => s !== 'root')
            .map((s) => s.toLowerCase().split(' ').join('-'))
            .join('/');
        return path;
    }
    return '';
}
export function getFilterPathString(filters: Array<FilterCriteria<unknown>>) {
    if(filters){
        for (let i = 0; i < filters.length; i++) {
            const { label } = filters[i];
            if (label) {
                return `${label.toLowerCase().split(' ').join('-')}`;
            }
        }
    }
    return '';
}

export function titleToUrlString(title: string) {
    return title
        .trim()
        .toLowerCase()
        .split(/[!"£$%^&*()_\-@'#~?¬`,.<>]/)
        .join('')
        .split(/\s+/)
        .join('-');
}

export function singleVideoLink(title: VideoItem) {
    const urlStringFull = `${title.urlString}${
        title.urlStringIndex > 0 ? `--${title.urlStringIndex}` : ''
    }`;
    return `video/${urlStringFull}`;
}

function getSearchParam(searchString: string) {
    if (!searchString) {
        return '';
    }
    return `search=${searchString}`;
}

function areSameSort(sort: Sort, defaultSort: Sort) {
    if ((sort && !defaultSort) || (!sort && defaultSort)) return false;
    return (
        (!sort && !defaultSort) ||
        (JSON.stringify(sort.sortField) === JSON.stringify(defaultSort.sortField) &&
            sort.isAsc === defaultSort.isAsc)
    );
}

export function getSortParam(sort: Sort, defaultSort: Sort) {
    if (!sort || !sort.sortField || areSameSort(sort, defaultSort)) {
        return '';
    }
    return `sort=${sort.sortField.name},${sort.isAsc ? 'asc' : 'desc'}`;
}

function getFilterParams(filters: Array<FilterCriteria<unknown>>) {
    if (!filters || filters.length === 0) {
        return '';
    }
    let filterParam = 'filters=';
    let firstLabel = true;
    filters.forEach((filter) => {
        if (filter.label && firstLabel) {
            firstLabel = false;
        } else {
            const valuesString = filter.label
                ? `(${encodeURIComponent(filter.label)})`
                : `(${
                    filter.min === filter.max || !filter.max
                        ? filter.min
                        : `${filter.min}:${filter.max}`
                })`;
            const filterString = filter.field.name + valuesString;
            filterParam = `${filterParam + filterString},`;
        }
    });
    if (filterParam === 'filters=') return '';
    return filterParam.slice(0, -1);
}

function getVideosPlayedParams(videos: Array<HasId>): string {
    if (videos && videos.length > 0) {
        const videoIds = videos.map((video) => video.id).join(',');
        return `videos=${videoIds}`;
    }
    return '';
}

export function getOrientationsPath(orientations: Array<string>) {
    if (
        !orientations ||
        orientations.length === 0 ||
        (orientations.length === 1 && orientations[0] === 's')
    ) {
        return '';
    }
    const pathItems = [];
    if (orientations.indexOf('s') > -1) {
        pathItems.push('straight');
    }
    if (orientations.indexOf('g') > -1) {
        pathItems.push('gay');
    }
    if (orientations.indexOf('b') > -1) {
        pathItems.push('bi');
    }
    if (orientations.indexOf('t') > -1) {
        pathItems.push('trans');
    }
    return `/${pathItems.join('-')}`;
}

function getOrientationsParam(orientations: Array<string>) {
    if (
        !orientations ||
        orientations.length === 0 ||
        (orientations.length === 1 && orientations[0] === 's')
    ) {
        return '';
    }
    return `orientations=${orientations.join(',')}`;
}

function getRelatedPath(vids: Array<VideoItem>, filters: Array<FilterCriteria<unknown>>): string {
    if (vids.length !== 1 || (filters && filters.length > 0)) return 'related';
    return singleVideoLink(vids[0]);
}

export function convertStateToUrl(
    state: LibDataKey,
    defaultSort: Sort,
    videos: Array<VideoItem>,
): string {
    let params = [];
    let pathStart = '';
    let isSingleVid = false;
    if (state.relatedTo) {
        pathStart = getRelatedPath(videos, state.filters);
        isSingleVid = pathStart.indexOf('video') > -1;
    } else if(state.isHomePage){
        pathStart = '';
    } else {
        pathStart = getPathString(state.parentPath);
    }
    const filterPathString = getFilterPathString(state.filters);
    const urlPath = pathStart + (pathStart && filterPathString ? '/' : '') + filterPathString;
    params.push(getSearchParam(state.searchTerm));
    params.push(getSortParam(state.sort, defaultSort));
    params.push(getFilterParams(state.filters));
    if (!state.relatedTo || videos.length > 1 || (state.filters && state.filters.length > 0)) {
        params.push(getVideosPlayedParams(videos));
    }
    const orientationsPath = getOrientationsPath(state.orientations);
    params = params.filter((s) => s.length > 0);
    return `${isSingleVid ? '' : orientationsPath}/${urlPath}${
        params.length > 0 ? `?${params.join('&')}` : ''
    }`;
}

export function fullStateToUrl(state: RootState) {
    const libBrowserState = state.undoable.present as LibraryBrowserState;
    const noneUndoState = state.notUndoable as LibraryBrowserState;
    const videosState = state.undoable.present as ContentViewerState;
    const dataKey = libBrowserState.libraryBrowserDataKey;
    const { libBrowserConfig } = noneUndoState;
    return convertStateToUrl(dataKey, libBrowserConfig.defaultSort, videosState.videos);
}
