import * as React from 'react';
import { Component, createRef, ReactElement } from 'react';
import './TopDirsView.scss';
import { RootState } from 'adult/state/AdultappStateStore';
import {
    LibraryBrowserState,
    showRecommendations,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { connect } from 'react-redux';
import { Dir, HasId } from 'common/types/AppTypes';
import AppLink from 'common/components/AppLink/AppLink';
import { isSfw, libraryPathSeperator } from 'adult/confg/AppConfig';
import {
    getOrientationsPath,
    getPathString,
} from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import { openHomePageAction, setLibPathM } from 'common/components/AppLink/MemoActionCreators';
import { ContentViewerState } from 'adult/state/ContentViewerState/ContentViewerState';
import '../../../adult-media-beast/resources/masonry.jpeg';

interface TopDirsViewProps {
    topDirs: Array<Dir>;
    heightListener: (height: number) => void;
    offset: number;
    orientations: Array<string>;
    children: ReactElement;
    onMouseLeave: () => void;
    parentPath: string;
    isHomePage: boolean;
    relatedTo: unknown;
    openVideos: Array<HasId>;
}

interface DirItemProps {
    dir: Dir;
    orientations: Array<string>;
    openVideos: Array<HasId>;
    hidden?: boolean;
}

export class DirItem extends Component<DirItemProps> {
    private divRef = createRef<HTMLDivElement>();

    getDivRef = () => {
        return this.divRef.current;
    };

    render() {
        const { dir, orientations, openVideos, hidden } = this.props;
        const { path } = dir;
        const pathItems = path.split(libraryPathSeperator);
        const name = pathItems[pathItems.length - 1];
        let stateAction = setLibPathM(dir);
        let href = `${getOrientationsPath(orientations)}/${getPathString(path)}`;
        if (path === 'Home') {
            stateAction = openHomePageAction;
            href = '/';
        }
        if (path === 'Related') {
            stateAction = showRecommendations(openVideos.map((vid) => vid.id));
            href = `${getOrientationsPath(orientations)}/related`;
        }
        const styleName = `dirItem${hidden ? ' hidden' : ''}`;
        return (
            <div styleName={styleName} ref={this.divRef}>
                <AppLink key={path} href={href} stateAction={stateAction}>
                    <span>{name}</span>
                </AppLink>
                <img
                    src={`/dir-icon/${orientations[0]}/${
                        isSfw ? 'sfw' : name.toLowerCase().replace(' ', '-')
                    }.png`}
                />
            </div>
        );
    }
}

export class TpdLink extends Component {
        private divRef = createRef<HTMLDivElement>();

    getDivRef = () => {
        return this.divRef.current;
    };

    render() {
        const styleName = `dirItem tpd`;
        return (
            <div styleName={styleName} ref={this.divRef}>
                <a href="/infinite-masonry"  target="_blank" title={"Demo of an infinite masonry content grid."}>
                    <span>Infinite Masonry</span>
                </a>
                <img src="/static/masonry.jpeg" />
            </div>
        );
    }
}

export class TopDirsViewComponent extends Component<TopDirsViewProps> {
    private container: HTMLDivElement;

    private dirDivRefs: Array<HTMLDivElement> = [];

    containerRef = (elem: HTMLDivElement) => {
        if (elem) {
            this.container = elem;
        }
    };

    dirItemRef = (ref: DirItem) => {
        if (ref) {
            this.dirDivRefs.push(ref.getDivRef());
        }
    };

    getDirs = () => {
        return this.dirDivRefs;
    };

    getContainerRef = () => {
        return this.container;
    };

    render() {
        const {
            topDirs,
            offset,
            orientations,
            children,
            onMouseLeave,
            parentPath,
            isHomePage,
            relatedTo,
            openVideos,
        } = this.props;

        const finalDirs = topDirs.slice();
        finalDirs.unshift({ path: 'Home', isMediaParent: true });
        finalDirs.push({ path: 'Related', isMediaParent: true });

        const dirItems = finalDirs.map((dir) => (
            <DirItem
                ref={this.dirItemRef}
                key={dir.path}
                orientations={orientations}
                dir={dir}
                openVideos={openVideos}
                hidden={
                    dir.path === parentPath ||
                    (isHomePage && dir.path === 'Home') ||
                    ((relatedTo || !openVideos || openVideos.length < 1) && dir.path === 'Related')
                }
            />
        ));
        dirItems.push(<TpdLink ref={this.dirItemRef} key="Tpd" />);
        let numItemsStyle = '';
        if (dirItems.length === 5) numItemsStyle = 'fiveDirs';
        if (dirItems.length === 6) numItemsStyle = 'sixDirs';
        if (dirItems.length === 7) numItemsStyle = 'sevenDirs';
        if (dirItems.length === 8) numItemsStyle = 'eightDirs';

        const styleName = `container ${numItemsStyle}${
            openVideos && openVideos.length > 0 ? ' vidOpen' : ''
        }`;
        return (
            <div
                styleName={styleName}
                ref={this.containerRef}
                style={{ top: `${offset}px` }}
                onMouseLeave={onMouseLeave}
            >
                {children}
                {dirItems}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const libState = state.undoable.present as LibraryBrowserState;
    const dataKey = libState.libraryBrowserDataKey;
    return {
        topDirs: (state.notUndoable as LibraryBrowserState).libBrowserConfig.topDirs,
        parentPath: dataKey.parentPath,
        isHomePage: dataKey.isHomePage,
        relatedTo: dataKey.relatedTo,
        orientations: libState.libraryBrowserDataKey.orientations,
        openVideos: (state.undoable.present as ContentViewerState).videos,
    };
};

const TopDirsView = connect(mapStateToProps, null, null, { forwardRef: true })(
    TopDirsViewComponent,
);

export default TopDirsView;
