import * as React from 'react';
import './PhLogo.scss';
import { Component } from 'react';

interface PhLogoProps {}

export default class PhLogo extends Component<PhLogoProps> {
    render() {
        return (
            <span styleName="ph">
                <span>Porn</span>
                <span>hub</span>
            </span>
        );
    }
}
