import 'common/client/wdyr';
// // eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import React from 'react';
import runClient from 'common/client/client';
import AdultApp from 'adult/components/App/AdultApp';
import 'adult/components/InformationPanel/InfoApp/InfoApp.scss';
import 'adult/components/ErrorPage/ErrorPage.scss';
import pgoStore from "adult/state/AdultappStateStore";
import {setIsServerSide} from "adult/state/LayoutState/LayoutState";

// const events = ['click', 'mousewheel', 'touchmove'];

function runApp(e) {
    // let moved = null;
    // let linkToClick = null;
    // let isScroll = null;
    // if (e){
    //     e.preventDefault();
    //     moved = e.type === 'touchmove';
    //     linkToClick = e.target;
    //     isScroll = e.type === "mousewheel";
    // }
    // events.forEach((e) => {
    //     document.removeEventListener(e, runApp);
    // });
    // setTimeout(() =>{
    //     const onLoad = () => {
    //         if (linkToClick) {
    //             switch (linkToClick.nodeName) {
    //                 case 'SPAN':
    //                 case 'I':
    //                 case 'DIV':
    //                 case 'A':
    //                     if (!moved && !isScroll){
    //                         linkToClick.click();
    //                         break;
    //                     }
    //                 default:
    //                     linkToClick.focus();
    //             }
    //         }
    //     };
        const ClientApp = () => {
            return <AdultApp initialState={window.initialState} />;
        };
        runClient(ClientApp, hot, ()=> {
            pgoStore.dispatch(setIsServerSide(false));
        });
    // });
}

runApp();
// events.forEach((e) => {
//     document.addEventListener(e, runApp, {passive: false});
// });
