import * as React from 'react';
import {Component, createRef} from 'react';
import './VideoPlayer.scss';
import { connect } from 'react-redux';
import { RootState } from 'adult/state/AdultappStateStore';
import { ContentViewerState } from 'adult/state/ContentViewerState/ContentViewerState';
import { PgoMediaItemData } from 'adult/components/PgoMediaItem/PgoMediaItem';
import PgoMediaPlayer, {
    PgoMediaPlayerComponent,
} from 'adult/components/PgoMediaPlayer/PgoMediaPlayer';
import { LayoutState, setPlayerFullScreen } from 'adult/state/LayoutState/LayoutState';
import { Coord, Dims, HasId, VideoItem } from 'common/types/AppTypes';
import { VideoPlayerController } from 'common/components/VideoPlayer/VideoPlayerController';
import SingleVideoPlayerController from 'common/components/VideoPlayer/SingleVideoPlayerController/SingleVideoPlayerController';
import SingleVideoPlayerControllerPanel from 'common/components/VideoPlayer/SingleVideoPlayerController/SingleVideoPlayerControllerPanel';
import MultiVideoPlayerController from 'common/components/VideoPlayer/MultiVideoPlayerController/MultiVideoPlayerController';
import fscreen from 'fscreen';
import { closeVideo } from 'adult/state/ComposedActions';
import SwipeListener from 'common/functions/SwipeListener/SwipeListener';
import {
    emptySpaceOnGrid,
    getSpaceTakenUpByPlayerBoxes,
} from 'common/components/VideoPlayer/VideoPlayerCalculator';
import VideoInfoPanel from 'common/components/VideoPlayer/VideoInfoPanel/VideoInfoPanel';
import SwipeTip from 'common/components/VideoPlayer/SwipeTip/SwipeTip';
import { getViewportDims } from 'common/functions/Utils/LayoutHelpers';
import AppIconButton from "common/components/AppIconButton/AppIconButton";

export interface ContentViewerConfig {
    maxDims: Dims;
    isRow: boolean;
}
interface VideoPlayerProps {
    videos: Array<VideoItem>;
    maxDims: Dims;
    isRow: boolean;
    closeVideo: (video: VideoItem) => void;
    isSingle: boolean;
    isFullScreen: boolean;
    setPlayerFullScreen: (b: boolean) => void;
    isServerSide: boolean;
}

interface VideoPlayerState {
    indexOfPlayerShown: number;
    isSwipeTipShown: boolean;
    // indexOfPlayerInfoShown: number;
}

function getContentViewerDims(config: ContentViewerConfig, isFullScreen: boolean): Dims {
    if (isFullScreen) {
        if (!fscreen.fullscreenEnabled) {
            return getViewportDims();
        }
        return { width: window.screen.width, height: window.screen.height };
    }
    const width = config.isRow ? config.maxDims.width * 0.4 : config.maxDims.width;
    const height = config.isRow ? config.maxDims.height : config.maxDims.height * 0.4;
    return { width, height };
}

function containerStyle(containerDims: Dims, config: ContentViewerConfig): unknown {
    return { style: { flexBasis: config.isRow ? containerDims.width : containerDims.height } };
}

export function countDupes(counted: Record<string, number>, video: HasId): number {
    const numTimesCounted = counted[video.id] || 0;
    counted[video.id] = numTimesCounted + 1;
    return numTimesCounted;
}

function isFreeSpaceUsable(
    dims: Dims,
    spaceFree: { top: number; left: number; width: number; height: number },
) {
    // console.log(dims.width)
    // console.log(spaceFree.width);
    return !dims || dims.width !== spaceFree.width || (dims.width === 0 && dims.height === 0);
}

export class VideoPlayerComponent extends Component<VideoPlayerProps, VideoPlayerState> {
    private singleVideoPlayerController: VideoPlayerController = new SingleVideoPlayerController();

    private multiVideoPlayerController: VideoPlayerController = new MultiVideoPlayerController();

    // private playerSurfaceRef = createRef<HTMLDivElement>();
    private containerRef: HTMLDivElement;

    private prevNumVids = 0;

    private videoPlayerRefs = new Array<PgoMediaPlayerComponent>();

    private infoPanelRef = createRef<HTMLDivElement>();

    constructor(props: VideoPlayerProps) {
        super(props);
        this.state = { indexOfPlayerShown: 0, isSwipeTipShown: false };
        if (fscreen.fullscreenEnabled) {
            fscreen.addEventListener('fullscreenchange', () => {
                if (!fscreen.fullscreenElement) {
                    props.setPlayerFullScreen(false);
                }
            });
            fscreen.addEventListener('fullscreenerror', () => {
                props.setPlayerFullScreen(false);
            });
        }
    }

    componentDidUpdate(prevProps: VideoPlayerProps): void {
        const { isFullScreen } = this.props;
        if (
            fscreen.fullscreenEnabled &&
            isFullScreen &&
            !fscreen.fullscreenElement &&
            this.containerRef
        ) {
            fscreen.requestFullscreen(this.containerRef);
        } else if (
            !isFullScreen &&
            fscreen.fullscreenElement &&
            fscreen.fullscreenElement.tagName !== 'IFRAME' // TODO: may not work with videos
        ) {
            fscreen.exitFullscreen();
        }
        this.processSwipeTip(prevProps);
    }

    processSwipeTip = (prevProps: VideoPlayerProps) => {
        const { videos, isSingle } = this.props;
        if (
            videos &&
            (videos.length === 1 || videos.length === 2) &&
            (!prevProps.videos || prevProps.videos.length < videos.length) &&
            isSingle
        ) {
            this.setState(
                (prevState) => ({ ...prevState, isSwipeTipShown: true }),
                () => {
                    setTimeout(() => {
                        this.setState((prevState) => ({ ...prevState, isSwipeTipShown: false }));
                    }, 1500);
                },
            );
        }
    };

    changePlayerShown = (i: number) => {
        this.setState({ indexOfPlayerShown: i });
    };

    updatePlayerShown = (n: number) => {
        if (this.prevNumVids !== n) {
            this.state.indexOfPlayerShown = n - 1;
            this.prevNumVids = n;
        }
    };

    closeCurrentPlayer = () => {
        const { indexOfPlayerShown } = this.state;
        this.videoPlayerRefs[indexOfPlayerShown].closeVid();
    };

    shrinkFullScreen = () => {
        const { setPlayerFullScreen } = this.props;
        setPlayerFullScreen(false);
    }
    playerSurfaceRef = (ref: HTMLDivElement) => {
        if (ref) {
            this.containerRef = ref;
            SwipeListener.detectswipe(this.containerRef, (res) => {
                const { videos } = this.props;
                const { indexOfPlayerShown } = this.state;
                let newIndex = indexOfPlayerShown;
                if (res === 'l' && newIndex + 1 < videos.length) {
                    newIndex += 1;
                } else if (res === 'r' && newIndex - 1 >= 0) {
                    newIndex -= 1;
                }
                if (newIndex !== indexOfPlayerShown) {
                    this.setState((prevState) => ({ ...prevState, indexOfPlayerShown: newIndex }));
                }
            }, this.infoPanelRef);
        }
    };

    // infoButtonAction = (i: number) => (toShow: boolean) => {
    //     if (toShow) {
    //         this.setState((prevState) => ({ ...prevState, indexOfPlayerInfoShown: i }));
    //     } else {
    //         this.setState((prevState) => ({ ...prevState, indexOfPlayerInfoShown: null }));
    //     }
    //     // console.log(`${i} ${toShow}`);
    // };

    render() {
        const { videos, maxDims, isRow, isSingle, isFullScreen, isServerSide } = this.props;
        const numVidsOpen = videos ? videos.length : 0;
        this.updatePlayerShown(numVidsOpen);
        const { indexOfPlayerShown, isSwipeTipShown } = this.state;
        const dims =
            maxDims && numVidsOpen > 0
                ? getContentViewerDims({ maxDims, isRow }, isFullScreen)
                : { width: 0, height: 0 };
        const videoPlayerController = isSingle
            ? this.singleVideoPlayerController
            : this.multiVideoPlayerController;
        const {
            playerStyles,
            playerCoords,
            areaTakenUp,
            areShown,
        } = videoPlayerController.getPlayerPositionings(
            dims,
            { maxDims, isRow },
            numVidsOpen,
            indexOfPlayerShown,
            isFullScreen,
        );
        const dupeCounter = {};
        const finalPlayerStyles = playerStyles;
        if (playerStyles.length === 0) {
            // finalPlayerStyles = videos.map((v) => {
            //     return { width: '100%', paddingTop: '63%' };
            // });
        }
        finalPlayerStyles.map((finalPlayerStyle) => {
            if ((finalPlayerStyle.width === 0 || finalPlayerStyle.height === 0)) {
                delete finalPlayerStyle.width;
                delete finalPlayerStyle.height;
            }
        });
        const clientSideDimsSupportStyle = isServerSide
            ? {}
            : {
                position: 'relative',
                width: !isRow ? '50%' : '100%',
                paddingTop: !isRow ? '31.5%' : '63%',
            };
        const videoComponents = videos.map((item: VideoItem, i: number) => (
            <PgoMediaPlayer
                isHidden={areShown ? !areShown[i] : false}
                style={finalPlayerStyles[i] ? finalPlayerStyles[i] : clientSideDimsSupportStyle}
                key={item.Title + countDupes(dupeCounter, item)}
                mediaItem={item as PgoMediaItemData}
                ref={(ref) => (this.videoPlayerRefs[i] = ref)}
            />
        ));
        const boxesTakingSpace =
            isSingle && playerCoords.length > 0 ? [playerCoords[indexOfPlayerShown]] : playerCoords;
        const spaceFree = emptySpaceOnGrid(dims, getSpaceTakenUpByPlayerBoxes(boxesTakingSpace));
        const styleName = `container${videos && numVidsOpen > 0 ? ' playerThere' : ''}${
            videos && numVidsOpen > 0 && isFullScreen && !fscreen.fullscreenEnabled
                ? ' fullScreenView'
                : ''
        }`;

        let containerStyle1 = {};

        if (!isServerSide && maxDims) {
            containerStyle1 = containerStyle(areaTakenUp, { maxDims, isRow });
        }
        //     // containerStyle1.style.flexBasis = '35%';
        // }
        let videoInfoItem = null;
        let isInfoHidden = true;
        let infoStyle = {};
        const infoEmptySpaceAvailable = !(
            (numVidsOpen !== 1 && !isSingle) ||
            !isFreeSpaceUsable(maxDims, spaceFree) ||
            isFullScreen
        );
        if (infoEmptySpaceAvailable) {
            videoInfoItem = videos[indexOfPlayerShown];
            isInfoHidden = false;
            if (spaceFree.width > 0 && spaceFree.height > 0) {
                infoStyle = spaceFree;
            }
            if (Object.keys(infoStyle).length === 0) {
                infoStyle = { position: 'relative' };
            }
            // console.log(infoStyle);
        } /* else if (indexOfPlayerInfoShown || indexOfPlayerInfoShown === 0) {
            videoInfoItem = videos[indexOfPlayerInfoShown];
            isInfoHidden = false;
            infoStyle = finalPlayerStyles[indexOfPlayerInfoShown];
        } */
        // const finalSpaceFree = spaceFree;
        return (
            <div styleName={styleName} {...containerStyle1} ref={this.playerSurfaceRef}>
                <SingleVideoPlayerControllerPanel
                    isHidden={!isSingle}
                    indexShown={indexOfPlayerShown}
                    vidCoord={
                        playerCoords.length > 0 ? playerCoords[indexOfPlayerShown] : ({} as Coord)
                    }
                    videos={videos}
                    onItemClick={this.changePlayerShown}
                    closeClick={this.closeCurrentPlayer}
                />
                {videoComponents}
                <VideoInfoPanel
                    containerRef={this.infoPanelRef}
                    videoItem={videoInfoItem}
                    isHidden={isInfoHidden}
                    style={infoStyle}
                    // onMouseLeave={() => this.infoButtonAction(indexOfPlayerInfoShown)(false)}
                />
                <SwipeTip isShown={isSwipeTipShown} />
                {isFullScreen && <i styleName={"shrinkScreen"} className={"icon-shrinkScreen"} onClick={this.shrinkFullScreen}/>}

            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layoutState = state.notUndoable as LayoutState;
    const presentState = state.undoable.present as ContentViewerState;
    return {
        isServerSide: layoutState.isServerSide,
        videos: presentState.videos,
        maxDims: layoutState.contentViewMaxDims,
        isRow: layoutState.contentViewIsRow,
        isSingle: layoutState.isSingleVideoPlayer,
        isFullScreen: layoutState.isPlayerFullScreen,
    };
};

const mapDispatchToProps = {
    closeVideo,
    setPlayerFullScreen,
};

const VideoPlayer = connect(mapStateToProps, mapDispatchToProps)(VideoPlayerComponent);

export default VideoPlayer;
