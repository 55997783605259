import * as React from 'react';
import './XvLogo.scss';
import {Component} from 'react';

interface XvLogoProps {

}

export default class XvLogo extends Component<XvLogoProps> {
    render() {
        return (
            <span styleName="xv">
                <span>X</span>
                <span>VIDEOS</span>
            </span>
        );
    }
}