import * as React from 'react';
import './AppLink.scss';
import { Component, ReactNode, RefObject } from 'react';
import pgoStore, { AppAction, rootReducer, RootState } from 'adult/state/AdultappStateStore';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { fullStateToUrl } from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import 'common/styles/CommonStyles.scss';

interface AppLinkProps {
    href?: string;
    stateAction: AppAction;
    actionDispatch: (a: AppAction) => void;
    // state: RootState;
    children: ReactNode;
    onMouseMove?: (e: React.MouseEvent<unknown>) => void;
    onMouseLeave?: () => void;
    onMouseEnter?: () => void;
    htmlRef?: RefObject<HTMLAnchorElement>;
    hidden?: boolean;
    stateActionLink?: boolean;
    onTouchStart?: () => void;
    isVidOpen?: boolean;
}

interface AppLinkState {
    hrefS: string;
}

let rightClicked = 0;

export class AppLinkComponent extends Component<AppLinkProps, AppLinkState> {

    constructor(props: AppLinkProps) {
        super(props);
        this.state = { hrefS: null };
    }

    onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const { actionDispatch, stateAction } = this.props;
        actionDispatch(stateAction);
    };

    getStateActionUrl = () => {
        const { stateAction } = this.props;
        let nextState = pgoStore.getState();
        if (!stateAction.type) {
            // if type not present then this is a compound action (thunk)
            stateAction(
                (action) => {
                    nextState = rootReducer(nextState, action);
                },
                () => {
                    return nextState;
                },
            );
        } else {
            nextState = rootReducer(pgoStore.getState(), stateAction);
        }
        const url = fullStateToUrl(nextState);
        return url;
    };

    rightClick = (e) => {
        const { isVidOpen } = this.props;
        if (rightClicked < 2 && isVidOpen) {
            rightClicked++;
            // e.preventDefault();
            this.onClick({preventDefault: ()=>{}});
        } else {
            const { stateActionLink, stateAction } = this.props;
            if (!stateActionLink) {
                this.setState({ hrefS: this.getStateActionUrl() });
            }
        }
    };

    render() {
        const {
            children,
            href,
            onMouseMove,
            onMouseLeave,
            htmlRef,
            onMouseEnter,
            hidden,
            stateActionLink,
            onTouchStart,
        } = this.props;
        let { hrefS } = this.state;
        if (stateActionLink) {
            hrefS = this.getStateActionUrl();
        }
        const styleName = `appLink ${hidden ? 'hidden' : ''}`;
        return (
            <a
                className="link"
                href={hrefS || href}
                onClick={this.onClick}
                onContextMenu={this.rightClick}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                onTouchStart={onTouchStart}
                ref={htmlRef}
                styleName={styleName}
            >
                {children}
            </a>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actionDispatch: dispatch,
    };
}

const AppLink = connect(null, mapDispatchToProps)(AppLinkComponent);

export default AppLink;
