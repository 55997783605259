import {
    EmptyGrid,
    VGDataGetter,
    VGDataKeyConfig,
} from 'common/components/VirtualGrid/VirtualGrid';
// eslint-disable-next-line max-len
import { IndicesInView } from 'common/components/VirtualGrid/VirtualGridCanvas/calculators/VirtualGridCanvasCalculators';
import * as React from 'react';
import { ReactElement } from 'react';
import { LibDataKey } from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { Dims } from 'common/types/AppTypes';
import { ContentRequest } from 'adult/components/ApiComs/ApiTypes';
import VirtualGridDataGetterCache, {
    DataFetcher,
    getCacheKey,
    VGContentRequest,
    VGContentResponse,
} from 'common/components/VirtualGrid/VirtualGridDataGetterCache/VirtualGridDataGetterCache';
import { clone } from 'common/components/utils/Helpers';
import { DataGetterLibraryConfig } from 'common/components/VirtualGrid/DataGetterLibraryConfig/DataGetterLibraryConfig';
import { log } from 'util';
import { heightStream } from 'adult/components/HomePage/HomePage';
import {
    indiciesWithAds,
    injectAds,
    showingAds,
} from 'common/components/IntegratedAd/IntegrationCalcs';

export type ContentTransformer = (contentItem: Record<string, unknown>) => ReactElement;

export interface InitialGridData<T extends VGContentRequest> extends VGContentResponse {
    dataKey: T;
}

export default class VirtualGridDataGetter<T extends VGContentRequest, K extends VGContentResponse>
implements VGDataGetter {
    private initialData: InitialGridData<T>;

    public dataKey: T;

    private dataChangeListener: (dataConfig: VGDataKeyConfig) => void;

    private contentTransformer: (dataKey: unknown) => ContentTransformer;

    private contentResponseListener: (res: K) => void;

    private cachedBackendComms: VirtualGridDataGetterCache;

    private isEnabled = true;

    private pendingUpdateKey: T;

    private dataKeyStartIndicesInView: Record<string, number> = {};

    private libConfigs;

    private heightChangeListener;

    constructor(initialData: InitialGridData<T>, libConfigs: Array<DataGetterLibraryConfig>) {
        this.cachedBackendComms = new VirtualGridDataGetterCache((key, callback) => {
            const config = this.getLibConfig(key, libConfigs);
            config.dataFetcher(key, callback);
        });

        if (initialData) {
            this.cachedBackendComms.addInitialData(initialData);
            this.initialData = initialData;
            const dataKey = clone(initialData.dataKey);
            delete dataKey.startIndex;
            delete dataKey.numToTake;
            this.dataKey = dataKey;
            // this.dataKeyStartIndicesInView[JSON.stringify(this.dataKey)] = 0;
        }

        this.contentTransformer = (dataKey: unknown) => (
            data: Record<string, unknown>,
            i: number,
        ): ReactElement => {
            const config = this.getLibConfig(dataKey, libConfigs);
            let elem = config.contentTransformer(data);
            if (i === 0 && config.heightStream) {
                elem = React.cloneElement(elem, {
                    ...elem.props,
                    onResize: (n) => config.heightStream.next(n),
                });
            }
            return elem;
        };
        libConfigs
            .filter((c) => c.heightStream)
            .forEach((c) => {
                c.heightStream.subscribe((height) => {
                    this.heightChangeListener(height);
                });
            });
        this.libConfigs = libConfigs;
    }

    getLibConfig = (
        key: VGContentRequest,
        configs: Array<DataGetterLibraryConfig>,
    ): DataGetterLibraryConfig => {
        if (key.isHomePage || configs.length === 1) {
            return configs[0];
        }
        return configs[1];
    };

    getInitialData = () => {
        if (!this.initialData) return null;
        return {
            config: this.initialData as VGDataKeyConfig,
            items: this.initialData.content.map(this.contentTransformer(this.initialData.dataKey)),
        };
    };

    getItemsAsync = (indices: IndicesInView) => {
        return new Promise((resolve) => {
            const adIndices = indiciesWithAds(indices);
            // console.log(adIndices);
            // console.log(this.dataKey);
            const request: ContentRequest = {
                ...this.dataKey,
                startIndex: showingAds(this.dataKey) ? adIndices.startIndex : indices.startIndex,
                numToTake: showingAds(this.dataKey) ? adIndices.contentNum : indices.contentNum,
            };
            this.dataKeyStartIndicesInView[JSON.stringify(this.dataKey)] = indices.visualStart
                ? indices.visualStart
                : indices.startIndex;
            this.cachedBackendComms.getContent(request, (data) => {
                const config = this.getLibConfig(request, this.libConfigs);
                const content = data.content as Array<Record<string, unknown>>;
                const elems = content.map(this.contentTransformer(request));
                if (showingAds(this.dataKey)) {
                    injectAds(indices, elems);
                }
                resolve(elems);
            });
        });
    };

    getItems = (
        dataKey: LibDataKey,
        indices: IndicesInView,
        callback: (returnItems: Array<React.ReactElement>) => void,
    ) => {
        const request: ContentRequest = {
            ...dataKey,
            startIndex: indices.startIndex,
            numToTake: indices.contentNum,
        };
        this.cachedBackendComms.getContent(request, (data) => {
            const content = data.content as Array<Record<string, unknown>>;
            callback(content.map(this.contentTransformer(request)));
        });
    };

    onDataChange = (dataChangeListener: (dataConfig: VGDataKeyConfig) => void) => {
        this.dataChangeListener = dataChangeListener;
    };

    setContentResponseListener = (listener: (res: K) => void) => {
        this.contentResponseListener = listener;
    };

    onHeightChange = (listener: (height: number) => void) => {
        this.heightChangeListener = listener;
    };

    setEnabled = (is: boolean) => {
        if (!this.isEnabled && is && this.pendingUpdateKey) {
            this.setDataKey(this.pendingUpdateKey);
        }
        this.isEnabled = is;
    };

    setDataKey = (key: T) => {
        if (this.isEnabled) {
            if (JSON.stringify(this.dataKey) !== JSON.stringify(key)) {
                this.dataKey = key;
                const indexMem = this.dataKeyStartIndicesInView[JSON.stringify(this.dataKey)] || 0;
                this.dataKeyStartIndicesInView[JSON.stringify(this.dataKey)] = indexMem;
                const request = JSON.parse(JSON.stringify(this.dataKey));
                request.startIndex = 0;
                request.numToTake = 0;
                this.cachedBackendComms.getContent(request, (data) => {
                    if (this.contentResponseListener) {
                        this.contentResponseListener(data as K);
                    }
                    if (this.dataChangeListener) {
                        const config = this.getLibConfig(request, this.libConfigs);
                        this.dataChangeListener({
                            dims: data.dims as Dims,
                            numItems: data.numItems as number,
                            dataKey: key,
                            startIndex: indexMem,
                            numPerRow: data.numPerRow,
                            dynHeight: !!config.heightStream,
                        });
                    }
                });
            }
        } else {
            this.pendingUpdateKey = key;
        }
    };

    clearCache = (keyBit: string) => {
        this.cachedBackendComms.clearCache(keyBit);
    };

    refreshGrid = () => {
        const key = this.dataKey;
        this.dataKey = null;
        this.cachedBackendComms.clearCache(getCacheKey(key));
        this.setDataKey(key);
    };

    refreshUserPath = (parentItemId: string) => {
        if (
            this.dataKey.contentBrowserDataKey &&
            this.dataKey.contentBrowserDataKey.parentItemId === parentItemId
        ) {
            this.refreshGrid();
        } else {
            this.clearCache(`"parentItemId":"${parentItemId}"`);
        }
    };
}
