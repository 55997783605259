import * as React from 'react';
import {Component, createRef, ReactElement} from 'react';
import './SlidingContentView.scss';
import {Dims} from 'common/types/AppTypes';
import SlidingArrowButton from 'common/components/SlidingArrowButton/SlidingArrowButton';
import {RootState} from 'adult/state/AdultappStateStore';
import {LayoutState} from 'adult/state/LayoutState/LayoutState';
import {connect} from 'react-redux';
import {VirtualGridController} from 'common/components/VirtualGridAsync/VirtualGridCanvas/VirtualGridController';
import Observable from 'common/functions/Observable/Observable';
import VirtualGridDataGetter from 'common/components/VirtualGrid/VirtualGridDataGetter/VirtualGridDataGetter';
import {ContentRequest, ContentResponse,} from 'adult/components/ApiComs/ApiTypes';
import {contentToDom} from 'adult/components/PgoMediaItem/PgoMediaItem';
import {
    VGContentRequest,
    VGContentResponse,
} from 'common/components/VirtualGrid/VirtualGridDataGetterCache/VirtualGridDataGetterCache';
import {sendRequest} from 'adult/components/ApiComs/ClientApiComs';
import {ContentViewerState} from "adult/state/ContentViewerState/ContentViewerState";

export interface SlidingContentViewDataGetter {
    getItems: (
        start: number,
        num: number,
        itemConsumer: (returnItems: Array<ReactElement>) => void,
    ) => void;
    initialItems: Array<ReactElement>;
}

interface SlidingContentViewProps {
    vidsOpen: boolean;
    numItems: number;
    dims: Dims;
    initialItems: Array<Record<string, unknown>>;
    // getItems: (
    //     start: number,
    //     num: number,
    //     itemConsumer: (returnItems: Array<ReactElement>) => void,
    // ) => void;
    isServerSide: boolean;
    title: ReactElement;
    style?: unknown;
    parentWidth?: number;
    onStartIndexChange?: (i: number) => void;
    startIndex?: number;
    autoplay?: boolean;
    isMobile?: boolean;
    data: Record<string, unknown>;
    onResize?: (height: number) => void;
}

interface SlidingContentViewState {
    width: number;
    items: Array<ReactElement>;
    startIndex: number;
    autoplayIndex: number;
    slidingViewHeight: number;
    viewport: HTMLDivElement;
}


// export const slidingViewDataFetcher: DataFetcher = (
//     key: VGContentRequest,
//     callback: (res: VGContentResponse) => void,
// ) => {
//     sendRequest('home-views', key, (res) => {
//         const views = res.homePageSlidingViews as Array<HomePageSlidingView>;
//         callback({
//             content: (views as unknown) as Array<Record<string, unknown>>,
//             numItems: res.maxNum,
//             dims: null,
//             numPerRow: 1,
//             itemHeight: '250px',
//         });
//     });
// };
const viewItemsGetter = (key: VGContentRequest, callback: (res: VGContentResponse) => void) => {
    sendRequest('home-view-items', key, (res) => {
        callback({
            content: (res as unknown) as Array<Record<string, unknown>>,
            dims: null,
        });
    });
};

const libConfigs = [
    {
        type: 'homeView',
        dataFetcher: viewItemsGetter,
        contentTransformer: contentToDom,
    },
];

export class SlidingContentViewComponent extends Component<
    SlidingContentViewProps,
    SlidingContentViewState
> {
    private titleRef = createRef<HTMLDivElement>();

    private leftSliderOver: number = null;

    private rightSliderOver: number = null;

    private leftSliderInterval: number = null;

    private rightSliderInterval: number = null;

    private scrollStream = new Observable<number>();

    private viewDataGetter: VirtualGridDataGetter<ContentRequest, ContentResponse>;

    private slidingViewRef = createRef<HTMLDivElement>();

    private indexStream = new Observable<number>();

    private gridRef = createRef<VirtualGridController>();

    constructor(props: SlidingContentViewProps) {
        super(props);
        const { data } = props;
        const { content, numItems, dims, path, numPerRow } = data;
        const initialData = {
            dataKey: { homeViewPath: path, orientations: ['s'] },
            content,
            numItems,
            dims,
            numPerRow: 1,
        };
        this.viewDataGetter = new VirtualGridDataGetter<ContentRequest, ContentResponse>(
            initialData,
            libConfigs,
        );
        this.state = {
            viewport: null,
        };
    }

    scrollDetected = () => {
        const { viewport } = this.state;
        this.scrollStream.next(viewport.scrollLeft);
    };

    viewPortRef = (ref: HTMLDivElement) => {
        if (ref && ref !== this.state.viewport) {
            this.setState((prevState) => ({ ...prevState, viewport: ref }));
        }
    };

    scrollNext = (left: boolean) => {
        const { current } = this.gridRef;
        if (current) {
            current.scrollToItem(current.getStartIndex() + (left ? -1 : 1));
        }
    };

    calcScrollSpeedValue = (pos: number) => {
        return pos / 7;
    };

    leftSliderMouseOver = (perc: number) => {
        if (perc === -1) {
            this.scrollNext(true);
        } else if (perc) {
            this.leftSliderOver = perc;
            if (!this.leftSliderInterval) {
                const { viewport } = this.state;
                this.leftSliderInterval = window.setInterval(() => {
                    const newScroll =
                        viewport.scrollLeft - this.calcScrollSpeedValue(this.leftSliderOver);
                    viewport.scrollLeft = newScroll > 0 ? newScroll : 0;
                }, 20);
            }
        } else {
            window.clearInterval(this.leftSliderInterval);
            this.leftSliderInterval = null;
        }
    };

    rightSliderMouseOver = (perc: number) => {
        if (perc === -1) {
            this.scrollNext(false);
        } else if (perc) {
            this.rightSliderOver = perc;
            if (!this.rightSliderInterval) {
                const { viewport } = this.state;
                this.rightSliderInterval = window.setInterval(() => {
                    viewport.scrollLeft += this.calcScrollSpeedValue(this.rightSliderOver);
                }, 20);
            }
        } else {
            window.clearInterval(this.rightSliderInterval);
            this.rightSliderInterval = null;
        }
    };

    itemResize = (dims: Dims) => {
        const { onResize } = this.props;
        if (onResize) {
            const itemHeight = dims.height;
            const titleHeight = this.titleRef.current?.getBoundingClientRect().height;
            onResize(itemHeight + titleHeight);
        }
    };

    render() {
        const {
            title,
            style,
            autoplay,
            isMobile,
            vidsOpen,
        } = this.props;
        const { viewport } = this.state;

        const viewportStyle = `viewport${isMobile ? ' vMobile' : ''}`;
        return (
            <div styleName="container" ref={this.slidingViewRef}>
                <div styleName="viewTitle" ref={this.titleRef} style={style}>
                    {title}
                </div>
                <SlidingArrowButton left mouseOverOvserver={this.leftSliderMouseOver} />
                <SlidingArrowButton left={false} mouseOverOvserver={this.rightSliderMouseOver} />
                <div
                    styleName={viewportStyle}
                    ref={this.viewPortRef}
                    onScroll={this.scrollDetected}
                >
                    <VirtualGridController
                        autoplayDisable={!autoplay}
                        ref={this.gridRef}
                        itemGetter={this.viewDataGetter}
                        scrollStream={this.scrollStream}
                        indexStream={this.indexStream}
                        viewPort={viewport}
                        onResize={this.itemResize}
                        horizontal
                        vidsOpen={vidsOpen}
                        noPaging
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layoutState = state.notUndoable as LayoutState;
    const presentState = state.undoable.present as ContentViewerState;

    return {
        isMobile: layoutState.isMobile,
        isServerSide: layoutState.isServerSide,
        vidsOpen: presentState.videos && presentState.videos.length > 0,
    };
};

const SlidingContentView = connect(mapStateToProps, null, null, { forwardRef: true })(
    SlidingContentViewComponent,
);

export default SlidingContentView;
