import * as React from 'react';
import { Component, RefObject } from 'react';
import './VideoInfoPanel.scss';
import 'common/styles/CommonStyles.scss';
import { titleToUrlString } from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import { setLibPathM } from 'common/components/AppLink/MemoActionCreators';
import { libraryPathSeperator } from 'adult/confg/AppConfig';
import LinkWithPreview from 'common/components/LinkWithPreview/LinkWithPreview';
import { getDuration, PgoMediaItemData } from 'adult/components/PgoMediaItem/PgoMediaItem';
import { formatDate, formatViews } from 'common/components/MediaItem/MediaItemFormatter';
import AutoLinkText from 'react-autolink-text2';

interface VideoInfoPanelProps {
    style?: unknown;
    isHidden?: boolean;
    videoItem: PgoMediaItemData;
    onMouseLeave?: () => void;
    containerRef?: RefObject<HTMLDivElement>;
}

export function navLink(parent: string, item: string) {
    const path = `root${libraryPathSeperator}${parent}${libraryPathSeperator}${item}`;
    return (
        <LinkWithPreview
            key={path}
            href={`/${titleToUrlString(parent)}/${
                parent === 'Tags' ? item : titleToUrlString(item)
            }`}
            itemPath={path}
            stateAction={setLibPathM({ path, isMediaParent: true })}
        >
            {item}
        </LinkWithPreview>
    );
}

export default class VideoInfoPanel extends Component<VideoInfoPanelProps> {
    render() {
        const { style, isHidden, videoItem, onMouseLeave, containerRef } = this.props;
        if (!videoItem) return <></>;
        const styleName = `container${isHidden ? ' hidden' : ''}`;
        const starLinks = videoItem.Pornstars
            ? videoItem.Pornstars.map((star) => {
                return navLink('Pornstars', star);
            })
            : [];
        const catLinks = videoItem.Categories
            ? videoItem.Categories.map((category) => {
                return navLink('Categories', category);
            })
            : [];
        const tagLinks = videoItem.Tags
            ? videoItem.Tags.map((tag) => {
                return navLink('Tags', tag);
            })
            : [];
        let setStyle = style;
        if (!style || style.width === '0' || style.height == 0) {
            setStyle = {};
        }
        const videoPageLink = videoItem.pageUrl;
        // console.log(videoItem)
        return (
            <div
                style={setStyle}
                styleName={styleName}
                onMouseLeave={onMouseLeave}
                ref={containerRef}
            >
                <a href={videoPageLink} target="_blank">
                    <h1>{videoItem.Title}</h1>
                </a>
                <div styleName="stats">
                    <span>
                        Duration: {getDuration(videoItem)}
                        <i className="icon-duration" />
                    </span>
                    <span>
                        Views: {formatViews(videoItem.Views)}
                        <i className="icon-views" />
                    </span>
                    {videoItem.Likes ? (
                        <span>
                            Likes: {formatViews(videoItem.Likes)}
                            <i className="icon-thumbs-up" />
                        </span>
                    ) : (
                        <></>
                    )}
                    {videoItem.DownVotes ? (
                        <span>
                            DownVotes: {formatViews(videoItem.DownVotes)}
                            <i className="icon-thumbs-down" />
                        </span>
                    ) : (
                        <></>
                    )}
                    <span>
                        Date Created: {formatDate(videoItem['Date Created'])}
                        <i className="icon-date" />
                    </span>
                </div>
                {starLinks.length > 0 ? (
                    <p>
                        <b>Pornstars:</b> {starLinks}
                    </p>
                ) : (
                    <></>
                )}
                {catLinks.length > 0 ? (
                    <p>
                        <b>Categories:</b> {catLinks}
                    </p>
                ) : (
                    <></>
                )}
                <p>
                    <b>Channel:</b> {navLink('Channels', videoItem.Channel)}
                </p>
                <p>
                    <b>Category:</b> {navLink('Categories', videoItem.Category[0])}
                </p>
                {tagLinks.length > 0 ? (
                    <p>
                        <b>Tags:</b> {tagLinks}
                    </p>
                ) : (
                    <></>
                )}
                <p styleName={"descr"}><b>Description: </b><AutoLinkText text={videoItem.description} linkProps={{target: '_blank'}} /></p>
            </div>
        );
    }
}
