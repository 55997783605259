import * as React from 'react';
import { Component } from 'react';
import SmoothImage from 'common/components/SmoothImage/SmoothImage';
import { processThumbnailSource } from 'adult/components/PgoMediaItem/PgoMediaItem';
import VideoPreview from 'common/components/VideoPreview/VideoPreview';
import { Dims } from 'common/types/AppTypes';
import styles from './MediaItemGallery.scss';

interface MediaItemGalleryProps {
    thumbnail: string;
    gallery?: Array<string>;
    alt: string;
    onImageLoad?: () => void;
    imageIndex?: number;
    autoplay?: boolean;
    itemDims?: Dims;
    thumbDims?: Dims;
}

interface MediaItemGalleryState {
    indexOfLastImageLoaded: number;
    autoplayIndex: number;
}

// export function processGallerySource(source: string): string {
//     return source.split('|')[1].replace('/ai.', '/ci.');js get width of text
// }

export function getGallerySource(galleryImage: string): string {
    return galleryImage.indexOf('|') >= 0 ? processThumbnailSource(galleryImage) : galleryImage;
}

function shouldAddBackground(itemDims: Dims, imageDims: Dims) {
    if (!itemDims || !imageDims) return false;
    // console.log(itemDims)
    // console.log(imageDims);
    const rat1 = itemDims.width / itemDims.height;
    const rat2 = imageDims.width / imageDims.height;
    //
    // return Math.abs(rat1 - rat2) > 0.2;
}

export default class MediaItemGallery extends Component<
    MediaItemGalleryProps,
    MediaItemGalleryState
> {
    private galleryImages: Array<SmoothImage>;

    private loadedImages: Array<number> = [];

    private timeout: ReturnType<typeof setTimeout>;

    private index: number;

    constructor(props: MediaItemGalleryProps) {
        super(props);
        const { gallery } = props;
        this.galleryImages = new Array<SmoothImage>(
            gallery && gallery.length ? gallery.length : 0,
        ).fill(undefined);
        this.state = { indexOfLastImageLoaded: null, autoplayIndex: null };
    }

    imageLoaded = (indexOfLastImageLoaded: number) => () => {
        this.loadedImages.push(indexOfLastImageLoaded);
        this.setState({ indexOfLastImageLoaded });
    };

    updateIndexShown = () => {
        this.timeout = setTimeout(() => {
            const gal = this.props.gallery;
            if (gal && gal.length > 0) {
                let index = this.state.autoplayIndex;
                if (index === null || index >= gal.length - 1) {
                    index = -1;
                }
                index += 1;
                this.setState((prevState) => ({ ...prevState, autoplayIndex: index }));
            }
            this.updateIndexShown();
        }, 700);
    };

    setupAutoplay = (autoplay: boolean) => {
        if (autoplay) {
            if (!this.timeout) {
                this.updateIndexShown();
            }
        } else {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    };

    render() {
        const {
            thumbnail,
            gallery,
            alt,
            onImageLoad,
            imageIndex,
            autoplay,
            itemDims,
            thumbDims,
        } = this.props;
        // console.log(imageIndex);
        const { indexOfLastImageLoaded, autoplayIndex } = this.state;
        this.setupAutoplay(autoplay);
        this.galleryImages = (this.galleryImages.map((item, i) => {
            // if (!item) return undefined;
            const vidSplit = gallery[0].split('|');
            let isVidPreview = false;
            if (vidSplit[1] === 'V') {
                isVidPreview = true;
            }
            if (item || i === imageIndex || autoplay) {
                const className =
                    (i === autoplayIndex && autoplay) ||
                    i === imageIndex ||
                    (i === indexOfLastImageLoaded &&
                        imageIndex !== null &&
                        this.loadedImages.indexOf(imageIndex) < 0)
                        ? styles.foreGround
                        : styles.hidden;
                if (isVidPreview) {
                    return (
                        <VideoPreview
                            key={vidSplit[0]}
                            vidUrl={vidSplit[0]}
                            isHovered={i === imageIndex || (i === autoplayIndex && autoplay)}
                            className={className}
                        />
                    );
                }
                const gallerySource = getGallerySource(gallery[i]);
                return (
                    <React.Fragment key={`fragment${i}`}>
                        <SmoothImage
                            key={`${gallery[i]}background`}
                            src={gallerySource}
                            alt="background"
                            background
                            className={className}
                        />
                        <SmoothImage
                            key={gallery[i]}
                            src={gallerySource}
                            alt={alt}
                            className={className}
                            onImageLoaded={this.imageLoaded(i)}
                        />
                    </React.Fragment>
                );
            }
            return item;
        }) as unknown) as Array<SmoothImage>;
        // console.log(thumbDims)
        // console.log(itemDims)

        // const backgroundNeeded = shouldAddBackground(thumbDims, itemDims);
        // console.log(backgroundNeeded)
        return (
            <>
                <SmoothImage
                    key="backThumb"
                    src={thumbnail}
                    alt="background"
                    background
                    className={imageIndex === null ? '' : styles.hidden}
                />
                <SmoothImage
                    key="thumb"
                    src={thumbnail}
                    alt={alt}
                    onImageLoaded={onImageLoad}
                    className={imageIndex === null ? '' : styles.hidden}
                />
                {this.galleryImages}
            </>
        );
    }
}
