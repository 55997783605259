import {VideoPlayerController, VideoPlayerPositioning,} from 'common/components/VideoPlayer/VideoPlayerController';
import {ContentViewerConfig} from 'common/components/VideoPlayer/VideoPlayer';
import {Coord, Dims} from 'common/types/AppTypes';
import {forLoopGen} from 'common/functions/Utils/utils';

function getPlayerDims(gridDims: Dims): Dims {
    const maxRatio = gridDims.width / gridDims.height;
    const width = maxRatio > 1.6 ? gridDims.height * 1.6 : gridDims.width;
    const height = maxRatio > 1.6 ? gridDims.height : gridDims.width / 1.6;
    return { width, height };
}
const centerCoord = (surfaceDims: Dims, shouldCenter: boolean) => (coord: Coord): Coord => {
    if (!shouldCenter) return coord;
    return {
        ...coord,
        x: coord.x + (surfaceDims.width - coord.width) / 2,
        y: coord.y + (surfaceDims.height - coord.height) / 2,
    };
};

export default class SingleVideoPlayerController implements VideoPlayerController {
    getPlayerPositionings = (
        maxDims: Dims,
        config: ContentViewerConfig,
        numPlayers: number,
        shownPlayerIndex: number,
        centerCoords: boolean,
    ): VideoPlayerPositioning => {
        const playerCoords = this.getPlayerCoordinates(
            maxDims,
            config,
            numPlayers,
            shownPlayerIndex,
        ).map(centerCoord(maxDims, centerCoords));
        const playerStyles = playerCoords.map((coord, i) => ({
            transform: `translate(${coord.x}px,${coord.y}px)`,
            width: coord.width,
            height: coord.height,
            opacity: i === shownPlayerIndex ? 1 : 0,
        }));
        const areaTakenUp = playerCoords.length > 0 ? playerCoords[0] : { width: 0, height: 0 };
        const areShown = Array(playerCoords.length).fill(false);
        if (shownPlayerIndex || shownPlayerIndex === 0) {
            areShown[shownPlayerIndex] = true;
        }
        return { playerCoords, playerStyles, areaTakenUp, areShown };
    };

    getPlayerCoordinates = (
        maxDims: Dims,
        config: ContentViewerConfig,
        numPlayers: number,
        shownPlayerIndex: number,
    ): Array<Coord> => {
        const maxPlayerDims = getPlayerDims(maxDims);
        return forLoopGen(numPlayers, (i) => ({
            ...maxPlayerDims,
            x: (i - shownPlayerIndex) * maxPlayerDims.width,
            y: 0,
        }));
    };
}
