import * as React from 'react';
import { Component, createRef, ReactElement, ReactNode } from 'react';
import './SlidingContentViewFeed.scss';
import { Dims } from 'common/types/AppTypes';
import SlidingContentView from 'common/components/SlidingContentView/SlidingContentView';
import ReactResizeDetector from 'react-resize-detector';
import {
    calcSlidingViewIndiciesInView,
    calcuSlidingViewItemDims,
} from 'common/components/SlidingContentView/SlidingContentViewCalc';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';
import {
    adjustScrollForFiller, areCurrentlyShown,
    getBufferedIndicies,
    getBufferedOffset, getQuadOffset, getQuadrantIndicies,
} from 'common/components/VirtualGrid/VirtualGridCanvas/calculators/GeneralVirtualCalcs';

export interface SlidingViewParams {
    dims: Dims;
    numItems: number;
    title: ReactElement;
    initialItems: Array<ReactElement>;
    getItems: (
        start: number,
        num: number,
        itemConsumer: (returnItems: Array<ReactElement>) => void,
    ) => void;
    key: string;
}

interface SlidingContentViewFeedProps {
    initialData: Array<SlidingViewParams>;
    viewGetter: (
        start: number,
        num: number,
        itemConsumer: (returnItems: Array<SlidingViewParams>) => void,
    ) => void;
    isServerSide?: boolean;
    topFillerHeight: number;
    scrollListener?: (n: number) => void;
    widthListener?: (width: number) => void;
    isShown: boolean;
    numItems: number;
    // children?: Array<ReactNode>;
}

interface SlidingContentViewFeedState {
    slidingViews: Array<SlidingViewParams>;
    startIndex: number;
    width: number;
    autoplayIndex: number;
}

const bufferedRows = 2;

export default class SlidingContentViewFeed extends Component<
    SlidingContentViewFeedProps,
    SlidingContentViewFeedState
> {
    private containerRef: HTMLDivElement;

    private viewRef: SlidingContentView = null;

    private gridRef: HTMLDivElement;

    private scrollDebouncer: DebouncingExecutor = new DebouncingExecutor();

    private viewIndexPositions: Record<number, number> = {};

    private viewRefs: Record<number, SlidingContentView> = {};

    private hasBeenShown = false;

    private lastGoodHeight: number = null;

    constructor(props: SlidingContentViewFeedProps) {
        super(props);
        this.state = {
            slidingViews: props.initialData,
            startIndex: 0,
            width: null,
            autoplayIndex: 0,
        };
    }

    componentDidUpdate(
        prevProps: Readonly<SlidingContentViewFeedProps>,
        prevState: Readonly<SlidingContentViewFeedState>,
        snapshot?: any,
    ): void {
        const { isShown } = this.props;
        if (!prevProps.isShown && isShown || !this.hasBeenShown && isShown) {
            this.hasBeenShown = true;
            this.onScroll();
        }
    }

    getViewHeight = () => {
        let height = null;
        if (this.viewRef) {
            height = this.viewRef.getHeight();
        } else {
            const { slidingViews, width } = this.state;
            if (this.gridRef && slidingViews && slidingViews.length > 0 && width) {
                const itemDims = calcuSlidingViewItemDims(slidingViews[0].dims, width);
                height = itemDims.height + 20;
            }
        }
        if (height !== null) {
            this.lastGoodHeight = height;
        }
        return this.lastGoodHeight;
    };

    getItemsInView = () => {
        if (this.containerRef) {
            const { topFillerHeight } = this.props;
            const { scrollTop } = this.containerRef;
            const viewPortHeight = this.containerRef.getBoundingClientRect().height;
            const viewHeight = this.getViewHeight();
            if(!viewHeight) return {startIndex: 0, numToTake: 5};
            const indicies = calcSlidingViewIndiciesInView(
                adjustScrollForFiller(scrollTop, topFillerHeight),
                viewPortHeight,
                viewHeight,
            );
            return indicies;
        }
        return { startIndex: 0, numToTake: 0, autoplayIndex: 0};
    };

    justLoaded = (indiciesInView) => {
        const { slidingViews, startIndex } = this.state;
        return (
            this.containerRef.scrollTop === 0 &&
            indiciesInView.numToTake <= slidingViews.length &&
            startIndex === indiciesInView.startIndex
        );
    };

    onScroll = () => {
        const { viewGetter, scrollListener, isShown, numItems, isServerSide } = this.props;
        scrollListener(this.containerRef.scrollTop);
        this.scrollDebouncer.bufferedExecute(() => {
            const { startIndex, slidingViews } = this.state;
            const indiciesInView = this.getItemsInView();
            if (!isServerSide &&
                isShown && /*areCurrentlyShown(indiciesInView.startIndex, indiciesInView.numToTake, startIndex, slidingViews.length)*/
                (indiciesInView.startIndex !== startIndex ||
                    indiciesInView.numToTake !== slidingViews.length) &&
                !this.justLoaded(indiciesInView)
            ) {
                const bufferedInds = getBufferedIndicies(
                    indiciesInView.startIndex,
                    indiciesInView.numToTake,
                    bufferedRows,
                    numItems,
                );
                // const bufferedInds = getQuadrantIndicies(indiciesInView.startIndex, indiciesInView.numToTake, 10, numItems);

                viewGetter(bufferedInds.startIndex, bufferedInds.numToTake, (views) => {
                    this.setState({
                        startIndex: indiciesInView.startIndex,
                        slidingViews: views,
                        autoplayIndex: indiciesInView.autoplayIndex,
                    });
                });
            }
        }, 35);
    };

    refresh = () => {
        const { viewGetter } = this.props;
        const { startIndex, slidingViews, autoplayIndex } = this.state;
        viewGetter(startIndex, slidingViews.length, (views) => {
            this.setState({
                startIndex,
                slidingViews: views,
                autoplayIndex,
            });
        });
    }

    onResize = () => {
        if (this.gridRef) {
            const { widthListener } = this.props;
            const { startIndex } = this.state;
            const { width } = this.gridRef.getBoundingClientRect();
            widthListener(width);
            if (this.state.width !== width) {
                this.setState(
                    (prevState) => ({
                        ...prevState,
                        width,
                    }),
                    () => {
                        this.jumpToIndex(startIndex);
                    },
                );
            } else {
                this.onScroll();
            }
        }
    };

    refReceive = (ref: HTMLDivElement) => {
        if (ref) {
            this.containerRef = ref;
        }
    };

    gridRefGet = (ref: HTMLDivElement) => {
        if (ref) {
            this.gridRef = ref;
            this.onResize();
        }
    };

    viewStartIndexChange = (viewIndex: number) => (startIndex: number) => {
        this.viewIndexPositions[viewIndex] = startIndex;
    };

    processVieRefs = (viewIndex: number, isFirst: boolean) => (ref: SlidingContentView) => {
        if (ref) {
            if (isFirst) this.viewRef = ref;
            if (this.viewRefs[viewIndex] !== ref) {
                this.viewRefs[viewIndex] = ref;
                const viewIndexPosition = this.viewIndexPositions[viewIndex];
                if (viewIndexPosition && viewIndexPosition > 0) {
                    setTimeout(() => {
                        ref.jumpToIndex(viewIndexPosition);
                    });
                }
            }
        }
    };

    paramsToView = (view: SlidingViewParams, i: number) => {
        const { isServerSide } = this.props;
        const { width, startIndex, autoplayIndex } = this.state;
        let bufferIndex = startIndex - bufferedRows;
        if(bufferIndex < 0) bufferIndex = 0;
        return (
            <SlidingContentView
                ref={this.processVieRefs(startIndex + i, i === 0)}
                title={view.title}
                numItems={view.numItems}
                dims={view.dims}
                initialItems={view.initialItems}
                getItems={view.getItems}
                isServerSide={isServerSide}
                key={view.key}
                parentWidth={width}
                onStartIndexChange={this.viewStartIndexChange(startIndex + i)}
                autoplay={autoplayIndex === bufferIndex + i}
            />
        );
    };

    jumpToIndex = (index: number) => {
        const { topFillerHeight } = this.props;
        const viewHeight = this.getViewHeight();
        const scroll = index === 0 ? 0 : index * viewHeight + topFillerHeight + 3;
        this.containerRef.scrollTop = scroll;
    };

    scroll = (amount: number) => {
        const { scrollTop } = this.containerRef;
        let nextScroll = scrollTop + amount;
        if(nextScroll < 0) nextScroll = 0;
        this.containerRef.scrollTop = nextScroll;
    }

    render() {
        const { topFillerHeight, children, numItems, isServerSide } = this.props;
        const { slidingViews, startIndex } = this.state;
        const viewHeight = this.getViewHeight();
        const topOffset = getBufferedOffset(startIndex, bufferedRows, viewHeight);
        // const topOffset = getQuadOffset(startIndex, 10, viewHeight);

        const shownStyle = { transform: `translateY(${topOffset + topFillerHeight}px)` };
        // const shownStyle = { transform: `translateY(${topOffset}px)` };

        const style = viewHeight ? { height: `${numItems * viewHeight + topFillerHeight}px` } : {};
        return (
            <div styleName="viewPort" ref={this.refReceive} onScroll={this.onScroll}>
                <div styleName="fullGrid" style={style} ref={this.gridRefGet}>
                    {children}
                    <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
                    <ul styleName="shownItems" style={shownStyle}>
                        {slidingViews.map(this.paramsToView)}
                    </ul>
                </div>
            </div>
        );
    }
}
