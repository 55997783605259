import * as React from 'react';
import './AppIconButton.scss';
import { Component } from 'react';
import 'common/styles/CommonStyles.scss';

interface AppIconButtonProps {
    text?: string;
    iconName: string;
    onClick?: () => void;
    hidden?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export default class AppIconButton extends Component<AppIconButtonProps> {
    render() {
        const { text, iconName, onClick, hidden, onMouseEnter, onMouseLeave } = this.props;
        const className = `icon-${iconName}`;
        const styleName = `appIconButton${hidden ? ' hidden' : ''}`;
        return (
            <button
                styleName={styleName}
                type="button"
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <i className={className} />
                <br />
                {text}
            </button>
        );
    }
}
