import * as React from 'react';
import './PhMobileEmbedOverlay.scss';
import { Component } from 'react';
import SwipeListener from 'common/functions/SwipeListener/SwipeListener';

interface PhMobileEmbedOverlayProps {
    onUpSwipe?: () => void;
}

export default class PhMobileEmbedOverlay extends Component<PhMobileEmbedOverlayProps> {
    setUpSwipeListener = (ref: HTMLDivElement) => {
        if (ref) {
            const { onUpSwipe } = this.props;
            SwipeListener.detectswipe(ref, (swipe: string) => {
                if (swipe === 'u' && onUpSwipe) {
                    onUpSwipe();
                }
            });
        }
    };

    render() {
        return (
            <div styleName="phMobileEmbedOverlay" ref={this.setUpSwipeListener}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        );
    }
}
