import * as React from 'react';
import { Component, createRef } from 'react';
import './FoldingTopDirsPanel.scss';
import TopDirsView, { TopDirsViewComponent } from 'common/components/TopDirsView/TopDirsView';
import { RootState } from 'adult/state/AdultappStateStore';
import { LayoutState } from 'adult/state/LayoutState/LayoutState';
import { LibraryBrowserState } from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { connect } from 'react-redux';
import { TimelineMax } from 'gsap';

interface FoldingTopDirsPanelProps {
    orientations: Array<string>;
    isExpanded: boolean;
    onShowHide: (b: boolean) => void;
}

export class FoldingTopDirsPanelComponent extends Component<FoldingTopDirsPanelProps> {
    private topDirsRef = createRef<TopDirsViewComponent>();

    private spanRef = createRef<HTMLSpanElement>();

    private click = false;

    private isFullyExpandedFlag = true;

    hide = () => {
        this.isFullyExpandedFlag = false;
        const container = this.topDirsRef.current.getContainerRef();
        const dirs = this.topDirsRef.current.getDirs();
        for (let i = 0; i < dirs.length; i++) {
            const t = new TimelineMax();
            const v = -(i * 100);
            t.to(dirs[i], 0.4, { scale: 0.01, xPercent: v, transformOrigin: '0px 0px' });
        }

        let t = new TimelineMax();
        t.to(this.spanRef.current, 0.4, {
            scale: 1,
            transformOrigin: '0px 0px',
            onComplete: () => {
                const buttonHeight = this.spanRef.current.getBoundingClientRect().height;
                const buttonWidth = this.spanRef.current.getBoundingClientRect().width;
                t.to(container, 0, { height: buttonHeight, width: buttonWidth });
            },
        });
        t = new TimelineMax();
        t.to(container, 0.4, { height: '45px', paddingTop: 0 });
    };

    show = () => {
        const container = this.topDirsRef.current.getContainerRef();
        const dirs = this.topDirsRef.current.getDirs();
        for (let i = 0; i < dirs.length; i++) {
            const t = new TimelineMax();
            t.to(dirs[i], 0.4, { scale: 1, xPercent: 0 });
        }
        let t = new TimelineMax();
        t.to(this.spanRef.current, 0.4, { scale: 0 });
        t = new TimelineMax();
        t.to(container, 0.4, {
            height: 'auto',
            width: '100%',
            paddingTop: '2vw',
            onComplete: () => {
                this.isFullyExpandedFlag = true;
            },
        });
    };

    componentDidUpdate(
        prevProps: Readonly<FoldingTopDirsPanelProps>,
        prevState: Readonly<{}>,
        snapshot?: any,
    ): void {
        if (prevProps.isExpanded && !this.props.isExpanded) {
            this.hide();
        } else if (!prevProps.isExpanded && this.props.isExpanded) {
            this.show();
        }
    }


    mouseEnter = () => {
        setTimeout(() => {
            if (this.click) {
                this.click = false;
            } else {
                const { onShowHide } = this.props;
                onShowHide(true);
            }
        }, 1);
    };

    onClick = () => {
        this.click = true;
        const { isExpanded, onShowHide } = this.props;
        onShowHide(!isExpanded);
    };

    isFullyExpanded = () => {
        return this.isFullyExpandedFlag;
    };

    render() {
        const { orientations } = this.props;
        return (
            <>
                <TopDirsView
                    ref={this.topDirsRef}
                    orientations={orientations}
                    heightListener={this.heightListen}
                    offset={0}
                >
                    <span
                        ref={this.spanRef}
                        styleName="collapser"
                        className="icon-navDropDown"
                        onClick={this.onClick}
                        onMouseEnter={this.mouseEnter}
                    />
                </TopDirsView>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layout = state.notUndoable as LayoutState;
    const libState = state.undoable.present as LibraryBrowserState;
    return {
        isServerSide: layout.isServerSide,
        orientations: libState.libraryBrowserDataKey.orientations,
    };
};

const FoldingTopDirsPanel = connect(mapStateToProps, null, null, { forwardRef: true })(
    FoldingTopDirsPanelComponent,
);

export default FoldingTopDirsPanel;
