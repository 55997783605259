import { Sort } from 'common/types/AppTypes';
import { getSortParam } from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import { AppLinkResolverInstance } from 'common/functions/AppLinkResolver/AppLinkResolver';

function cleanUpUrl(url: string) {
    while (url.endsWith('&') || url.endsWith('?')) {
        url = url.slice(0, url.length - 1);
    }
    return url;
}

export function addSortToUrl(sort: Sort, defaultSort: Sort): string {
    if (!sort) return '';
    let linkString = '/';
    if (typeof window !== 'undefined') {
        linkString = window.location.pathname + window.location.search;
    } else {
        linkString = AppLinkResolverInstance.getRequestUrl();
    }
    const res = linkString.match(/(sort=.*?)(&|$)/);
    const sortString = getSortParam(sort, defaultSort);
    if (res) {
        if (sortString) {
            linkString = linkString.replace(res[1], sortString);
        } else {
            linkString = linkString.replace(res[0], sortString);
        }
    } else if (linkString.indexOf('?') > -1 && sortString) {
        linkString = `${linkString}&${sortString}`;
    } else if (sortString) {
        linkString = `${linkString}?${sortString}`;
    }
    return cleanUpUrl(linkString);
}
