import * as React from 'react';
import { Children, ReactElement, ReactNode, useMemo } from 'react';
import './VirtualGridCanvas.scss';
import ReactResizeDetector from 'react-resize-detector';

interface VirtualGridProps {
    startIndex: number;
    numItems: number;
    ratio?: number;
    itemHeight?: string;
    numPerRow?: number;
    horizontal?: boolean;
    style?: Record<string, any>;
    onResize?: () => void;
    vidOpen?: boolean;
}

export const VirtualGridPlaceHolder = ({}) => {
    return <div styleName="placeholder" />;
};

const cloneFiller = (el: ReactElement) => {
    return React.cloneElement(el, {
        key: 'topFiller',
    });
};

export const VirtualGridCanvas = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<VirtualGridProps>
>(
    (
        {
            children,
            style,
            startIndex,
            numItems,
            itemHeight,
            ratio,
            numPerRow,
            horizontal,
            onResize,
            vidOpen,
        },
        ref,
    ) => {
        const numShown = React.Children.count(children);
        const containerStyle = useMemo(() => {
            const styleObj = {
                ...style,
                '--start-index': startIndex,
                '--num-items': numItems,
                '--num-shown': numShown,
            } as Record<string, any>;
            if (itemHeight) styleObj['--item-height'] = itemHeight;
            if (ratio) styleObj['--ratio'] = ratio;
            if (numPerRow) styleObj['--num-per-row'] = numPerRow;
            return styleObj;
        }, [style, startIndex, numItems, numShown, itemHeight, ratio, numPerRow]);
        const styleName = `mainContainer ${horizontal ? 'horizontal' : 'vertical'}${
            vidOpen ? ' halved' : ''
        } ${ratio < 1.01 ? ' tallItem' : ''}`;
        return (
            <div styleName={styleName} style={containerStyle} ref={ref}>
                <div styleName="topFiller" />
                {Children.count(children) > 0 ? children : <div styleName="itemExample" />}
                <div styleName="bottomFiller" />
                <ReactResizeDetector handleWidth onResize={onResize} />
            </div>
        );
    },
);

export default VirtualGridCanvas;
