import * as React from 'react';
import { Component, createRef } from 'react';
import './CloseButton.scss';
import ReactResizeDetector from 'react-resize-detector';
import { floorInStepsOf } from 'common/functions/Utils/utils';

interface CloseButtonProps {
    onClick: () => void;
    style?: Record<string, unknown>;
}

interface CloseButtonState {
    buttonFontSize: number;
}

export default class CloseButton extends Component<CloseButtonProps, CloseButtonState> {
    private buttonRef = createRef<HTMLButtonElement>();

    constructor(props: CloseButtonProps) {
        super(props);
        this.state = {
            buttonFontSize: 45,
        };
    }

    resizeDetected = () => {
        const { width } = this.buttonRef.current.getBoundingClientRect();
        const fontSize = floorInStepsOf(width * 0.9, 5);
        const { buttonFontSize } = this.state;
        if (buttonFontSize !== fontSize) {
            this.setState({ buttonFontSize: fontSize });
        }
    };

    render() {
        const { onClick, style } = this.props;
        const { buttonFontSize } = this.state;
        const textStyle = { fontSize: buttonFontSize };
        return (
            <button
                styleName="closeButton"
                type="button"
                onClick={onClick}
                ref={this.buttonRef}
                style={style}
            >
                <ReactResizeDetector handleWidth handleHeight onResize={this.resizeDetected} />
                <i className="icon-closeFilter" style={textStyle} />
            </button>
        );
    }
}
