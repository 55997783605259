import * as React from 'react';
import { Component, createRef, FormEvent } from 'react';
import './SearchField.scss';
import AppIconButton from 'common/components/AppIconButton/AppIconButton';
import SearchSuggestionsInput, {
    Suggestion,
    SuggestionsFetcher,
} from 'common/components/SearchSuggestionsInput/SearchSuggestionsInput';
import { sendRequest } from 'adult/components/ApiComs/ClientApiComs';
import { getElementContentWidth } from 'common/components/utils/ElementSizes';
import { RootState } from 'adult/state/AdultappStateStore';
import {LibraryBrowserState, setLibraryPath} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { connect } from 'react-redux';
import { getOrientationsPath } from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import {openVideoA} from "adult/state/ContentViewerState/ContentViewerState";
import {Dir} from "common/types/AppTypes";

interface SearchFieldProps {
    onSubmit: (searchTerm: string) => void;
    placeholder?: string;
    orientations: Array<string>;
    setLibraryPath: (dir: Dir) => void;
}

interface SearchFieldState {
    isFocused: boolean;
}
const suggestionsFetcher: SuggestionsFetcher = (
    input: string,
    consumer: (r: Record<string, Array<Suggestion>>) => void,
) => {
    sendRequest('autocomplete', { name: 'search', input }, consumer);
};

export class SearchFieldComponent extends Component<SearchFieldProps, SearchFieldState> {
    private inputRef = createRef<SearchSuggestionsInput>();

    private formRef = createRef<HTMLFormElement>();

    private isMouseDown = false;

    constructor(props: SearchFieldProps) {
        super(props);
        this.state = { isFocused: null };
        if (typeof document !== 'undefined') {
            document.addEventListener('mousedown', () => {
                this.isMouseDown = true;
            });
            document.addEventListener('mouseup', () => {
                this.isMouseDown = false;
            });
        }
    }

    onSubmit = (e: FormEvent<HTMLFormElement>) => {
        this.props.onSubmit(e.target.children[0].value);
        e.preventDefault();
    };

    submitListener = (val: string) => {
        const { onSubmit } = this.props;
        const style = window.getComputedStyle(this.formRef.current);
        if (style.position === 'fixed') this.inputRef.current.blur();

        onSubmit(val);
    };

    getInput = (): string => {
        return this.inputRef.current ? this.inputRef.current.getInputFieldValue() : '';
    };

    iconClicked = () => {
        const { isFocused } = this.state;
        if (isFocused && this.inputRef.current && !this.inputRef.current.getInputFieldValue()) {
            this.inputRef.current.blur();
        } else if (this.inputRef.current && !this.inputRef.current.getInputFieldValue()) {
            this.inputRef.current.focusIn();
        } else if (this.inputRef.current) {
            this.inputRef.current.onSubmit(this.inputRef.current.getInputFieldValue());
        }
    };

    searchFocused = (b: boolean) => {
        if (b && this.formRef.current) {
            const left = this.formRef.current.offsetLeft;
            const width = getElementContentWidth(this.formRef.current);
            document.documentElement.style.setProperty('--search-bar-width', `${width}px`);
            document.documentElement.style.setProperty('--search-bar-left', `${left}px`);
        }
        if (!b && this.isMouseDown) {
            const onMouseUp = () => {
                setTimeout(
                    () => {
                        this.setState((prevState) => ({
                            ...prevState,
                            isFocused: b,
                        }));
                        document.removeEventListener('mouseup', onMouseUp);
                    },
                    this.inputRef.current.areSuggestsShowing() ? 200 : 0,
                );
            };
            document.addEventListener('mouseup', onMouseUp);
        } else {
            this.setState((prevState) => ({
                ...prevState,
                isFocused: b,
            }));
        }
    };

    render() {
        const { placeholder, onSubmit, orientations, setLibraryPath } = this.props;
        const { isFocused } = this.state;
        const styleName = `searchForm${
            isFocused ? ' focusedForm' : isFocused === null ? '' : ' unfocusedForm'
        }`;
        const orientationsPath = getOrientationsPath(orientations);
        return (
            <form
                styleName={styleName}
                onSubmit={this.onSubmit}
                ref={this.formRef}
                action={orientationsPath}
            >
                <AppIconButton iconName="search" onClick={this.iconClicked} />
                <SearchSuggestionsInput
                    placeholder={placeholder}
                    suggestionFetcher={suggestionsFetcher}
                    onSubmit={this.submitListener}
                    dirChose={setLibraryPath}
                    ref={this.inputRef}
                    onFocused={this.searchFocused}
                />
            </form>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const libState = state.undoable.present as LibraryBrowserState;
    return {
        orientations: libState.libraryBrowserDataKey.orientations,
    };
};

const mapDispatchToProps = { setLibraryPath };

const SearchField = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    SearchFieldComponent,

);

export default SearchField;
