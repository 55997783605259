import { Coord, Dims } from 'common/types/AppTypes';

function widthFromArea(boxArea: number, boxRatio: number): number {
    return Math.sqrt(boxArea * boxRatio);
}

function heightFromArea(boxArea: number, boxRatio: number) {
    return Math.sqrt(boxArea / boxRatio);
}

function getReduceRatio(
    fullWidth: number,
    fullHeight: number,
    numCols: number,
    numRows: number,
    width: number,
    height: number,
): number {
    const widthRatio = fullWidth / ((numCols + 1) * width);
    const heightRatio = fullHeight / ((numRows + 1) * height);
    const ratio = widthRatio > heightRatio ? widthRatio : heightRatio;
    return ratio < 1.0 ? ratio : 0.99;
}

function createBoxes(
    fullWidth: number,
    fullHeight: number,
    width: number,
    height: number,
    num: number,
    ratio: number,
    shouldCenterCoords: boolean,
): Array<Coord> {
    let boxes = [] as Array<Coord>;
    let recurseResult = [] as Array<Coord>;
    if (height === 0 || width === 0) return boxes;
    const rowPad = (fullWidth % width) / width;
    const colPad = (fullHeight % height) / height;
    if (rowPad < colPad) {
        boxes = boxes.concat(fillFirstRow(fullWidth, width, height));
        if (boxes.length !== num) {
            recurseResult = distributeArea(
                { width: fullWidth, height: fullHeight - height },
                ratio,
                num - boxes.length,
                shouldCenterCoords,
            );
            for (let i = 0; i < recurseResult.length; i++) {
                recurseResult[i].y += height;
            }
        }
    } else {
        boxes = boxes.concat(fillFirstColumn(fullHeight, width, height, num));
        if (boxes.length !== num) {
            recurseResult = distributeArea(
                { width: fullWidth - width, height: fullHeight },
                ratio,
                num - boxes.length,
                shouldCenterCoords,
            );
            for (let i = 0; i < recurseResult.length; i++) {
                recurseResult[i].x += width;
            }
        }
    }
    boxes = boxes.concat(recurseResult);

    return boxes;
}

function createCoord(x: number, y: number, width: number, height: number): Coord {
    return { x, y, width, height };
}

function fillFirstRow(fullWidth: number, width: number, height: number): Array<Coord> {
    const boxes = [];
    for (let x = 0; x + width <= fullWidth; x += width) {
        boxes.push(createCoord(x, 0, width, height));
    }
    return boxes;
}

function fillFirstColumn(
    fullHeight: number,
    width: number,
    height: number,
    num: number,
): Array<Coord> {
    const boxes = [];
    for (let y = 0, added = 0; y + height <= fullHeight && added < num; y += height, added++) {
        boxes.push(createCoord(0, y, width, height));
    }
    return boxes;
}

function getRightMostCoord(coords: Array<Coord>): number {
    let rightMost = 0;
    for (let i = 0; i < coords.length; i++) {
        const bound = coords[i].x + coords[i].width;
        if (bound > rightMost) {
            rightMost = bound;
        }
    }
    return rightMost;
}

function getBottomMostCoord(coords: Array<Coord>): number {
    let bottomMost = 0;
    for (let i = 0; i < coords.length; i++) {
        const bound = coords[i].y + coords[i].height;
        if (bound > bottomMost) {
            bottomMost = bound;
        }
    }
    return bottomMost;
}

function centerCoords(coords: Array<Coord>, width: number, height: number) {
    const rightMost = getRightMostCoord(coords);
    const bottomMost = getBottomMostCoord(coords);
    const rightSpace = width - rightMost;
    const bottomSpace = height - bottomMost;
    for (let i = 0; i < coords.length; i++) {
        coords[i].x += rightSpace / 2;
        coords[i].y += bottomSpace / 2;
    }
    return coords;
}

export default function distributeArea(
    dimensions: Dims,
    boxRatio: number,
    numBoxes: number,
    shouldCenterCoords: boolean,
): Array<Coord> {
    const fullHeight = dimensions.height;
    const fullWidth = dimensions.width;
    if (fullWidth === 0 || fullHeight === 0 || numBoxes === 0) return [];
    const fullArea = fullHeight * fullWidth;
    const boxArea = fullArea / numBoxes;
    let width = widthFromArea(boxArea, boxRatio);
    let height = heightFromArea(boxArea, boxRatio);
    let numCols = Math.floor(fullWidth / width);
    let numRows = Math.floor(fullHeight / height);
    let reduceRatio;
    while (numRows * numCols < numBoxes) {
        reduceRatio = getReduceRatio(fullWidth, fullHeight, numCols, numRows, width, height);
        width *= reduceRatio;
        height *= reduceRatio;
        numCols = Math.floor(fullWidth / width);
        numRows = Math.floor(fullHeight / height);
    }
    const centerFuc = shouldCenterCoords ? centerCoords : (a: Array<Coord>) => a;
    return centerFuc(
        createBoxes(
            Math.floor(fullWidth),
            Math.floor(fullHeight),
            Math.floor(width),
            Math.floor(height),
            numBoxes,
            boxRatio,
            shouldCenterCoords,
        ),
        fullWidth,
        fullHeight,
    );
    // return createBoxes(fullWidth, fullHeight, Math.floor(width), Math.floor(height), numBoxes, boxRatio);
}
