import { Dims, PosStyle } from 'common/types/AppTypes';
import { Reducer } from 'redux';
import { ReactNode } from 'react';

export enum MainViewItem {
    LIBRARY,
    RECOMMENDATIONS,
}

export interface LayoutState {
    contentViewMaxDims: Dims;
    contentViewIsRow: boolean;
    contentViewIsShown: boolean;
    isSingleVideoPlayer: boolean;
    isPlayerFullScreen: boolean;
    previewParams: PreviewParams;
    gridStartIndex: number;
    isServerSide: boolean;
    isMobile: boolean;
    popUpControlParams: PopupControlParams;
    numVidsTotal?: number;
    // mainViewShown: MainViewItem;
}

export const initialLayoutState: LayoutState = {
    contentViewMaxDims: null,
    contentViewIsRow: true,
    contentViewIsShown: false,
    isSingleVideoPlayer: false,
    isPlayerFullScreen: false,
    previewParams: null,
    gridStartIndex: 0,
    isServerSide: true,
    isMobile: false,
    popUpControlParams: null,
    // mainViewShown: MainViewItem.LIBRARY,
};

export interface PopupControlParams {
    content: ReactNode;
}

export interface PreviewParams {
    style: PosStyle;
    content: ReactNode;
}

export const setContentViewLayout = (dims: Dims, isRow: boolean) => ({
    type: 'SET_CONTENT_VIEW_LAYOUT',
    payload: { dims, isRow },
});

export const setIsSinglePlayer = (isSingle: boolean) => ({
    type: 'SET_IS_SINGLE_PLAYER',
    payload: isSingle,
});

export const setPlayerFullScreen = (isFullScreen: boolean) => ({
    type: 'SET_PLAYER_FULLSCREEN',
    payload: isFullScreen,
});

export const setPreviewParams = (params: PreviewParams) => ({
    type: 'SET_PREVIEW_PARAMS',
    payload: params,
});

export const setGridStartIndex = (index: number) => ({
    type: 'SET_GRID_START_INDEX',
    payload: index,
});

export const setIsServerSide = (isIt: boolean) => ({
    type: 'SET_IS_SERVER_SIDE',
    payload: isIt,
});

export const setIsMobile = (isMobile: boolean) => ({
    type: 'SET_IS_MOBILE',
    payload: isMobile,
});

export const setPopUpControlParams = (params: PopupControlParams) => ({
    type: 'SET_POP_UP_CONTROL_PARAMS',
    payload: params,
});

export const setNumVidsTotal = (numVids: number) => ({
    type: 'SET_NUM_VIDS_TOTAL',
    payload: numVids,
});

// export const setMainViewShown = (item: MainViewItem) => ({
//     type: 'SET_MAIN_VIEW_SHOWN',
//     payload: item,
// });

export type LayoutStateActions = ReturnType<
    | typeof setContentViewLayout
    | typeof setIsSinglePlayer
    | typeof setPlayerFullScreen
    | typeof setPreviewParams
    | typeof setGridStartIndex
    | typeof setIsMobile
    | typeof setPopUpControlParams
    | typeof setNumVidsTotal
    // | typeof setMainViewShown
>;

export const LayoutStateReducers: Record<string, Reducer> = {
    SET_CONTENT_VIEW_LAYOUT: (state: LayoutState, action: LayoutStateActions) => {
        const payload = action.payload as { dims: Dims; isRow: boolean };
        return {
            ...state,
            contentViewMaxDims: payload.dims,
            contentViewIsRow: payload.isRow,
        };
    },
    SET_IS_SINGLE_PLAYER: (state: LayoutState, action: LayoutStateActions) => {
        return {
            ...state,
            isSingleVideoPlayer: action.payload,
        };
    },
    SET_PLAYER_FULLSCREEN: (state: LayoutState, action: ReturnType<typeof setPlayerFullScreen>) => {
        return {
            ...state,
            isPlayerFullScreen: action.payload,
        };
    },
    SET_PREVIEW_PARAMS: (state: LayoutState, action: ReturnType<typeof setPreviewParams>) => {
        return {
            ...state,
            previewParams: action.payload,
        };
    },
    SET_GRID_START_INDEX: (state: LayoutState, action: ReturnType<typeof setGridStartIndex>) => {
        return {
            ...state,
            gridStartIndex: action.payload,
        };
    },
    SET_IS_SERVER_SIDE: (state: LayoutState, action: ReturnType<typeof setIsServerSide>) => {
        return {
            ...state,
            isServerSide: action.payload,
        };
    },
    SET_IS_MOBILE: (state: LayoutState, action: ReturnType<typeof setIsMobile>) => {
        return {
            ...state,
            isMobile: action.payload,
        };
    },
    SET_POP_UP_CONTROL_PARAMS: (
        state: LayoutState,
        action: ReturnType<typeof setPopUpControlParams>,
    ) => {
        return {
            ...state,
            popUpControlParams: action.payload,
        };
    },
    SET_NUM_VIDS_TOTAL: (state: LayoutState, action: ReturnType<typeof setNumVidsTotal>) => {
        return {
            ...state,
            numVidsTotal: action.payload,
        };
    },
    // SET_MAIN_VIEW_SHOWN: (state: LayoutState, action: ReturnType<typeof setMainViewShown>) => {
    //     return {
    //         ...state,
    //         mainViewShown: action.payload,
    //     };
    // },
};
