import * as React from 'react';
import { Component, createRef } from 'react';
import './MainTopBar.scss';
import '../../resources/icon-fonts/style.scss';
import SearchField from 'common/components/SearchField/SearchField';
import { connect } from 'react-redux';
import { RootState } from 'adult/state/AdultappStateStore';
import { submitSearch } from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import AppIconButton from 'common/components/AppIconButton/AppIconButton';
import {
    LayoutState,
    setIsSinglePlayer,
    setPlayerFullScreen,
} from 'adult/state/LayoutState/LayoutState';
import { ContentViewerState } from 'adult/state/ContentViewerState/ContentViewerState';
import { closeAllVideos } from 'adult/state/ComposedActions';
import InformationPanel from 'adult/components/InformationPanel/InformationPanel';
import AppLink from 'common/components/AppLink/AppLink';
import { openHomePageAction } from 'common/components/AppLink/MemoActionCreators';

interface MainTopBarProps {
    submitSearch: (search: string) => void;
    isSinglePlayer: boolean;
    setIsSinglePlayer: (b: boolean) => void;
    closeAllVideos: () => void;
    areVidsOpen: boolean;
    setPlayerFullScreen: (b: boolean) => void;
    numVids?: number;
}

// function commaViews(views: number) {
//     return `${views}`
// }

function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

class MainTopBarComponent extends Component<MainTopBarProps> {
    private searchFieldRef = createRef<SearchField>();

    showSearchClick = () => {
        if (this.searchFieldRef.current) {
            this.searchFieldRef.current.iconClicked();
        }
    };

    goFullScreen = () => {
        const { setPlayerFullScreen } = this.props;
        setPlayerFullScreen(true);
    };

    switchPlayerMode = () => {
        const { isSinglePlayer, setIsSinglePlayer } = this.props;
        setIsSinglePlayer(!isSinglePlayer);
    };

    render() {
        const { submitSearch, isSinglePlayer, closeAllVideos, areVidsOpen, numVids } = this.props;
        return (
            <div styleName="mainTopBar">
                <AppLink href="/" stateAction={openHomePageAction}>
                    {/* <span className="icon-butterfly" styleName="topBarIcon" /> */}
                    <h1 styleName="smallTitle">Y</h1>
                    <h1>YouLibs</h1>
                </AppLink>
                <AppIconButton iconName="search" onClick={this.showSearchClick} />
                {/* <OrientationsController /> */}
                {areVidsOpen ? (
                    <>
                        <AppIconButton
                            text={isSinglePlayer ? 'multi vid' : 'single vid'}
                            iconName={isSinglePlayer ? 'multiPlayer' : 'singlePlayer'}
                            onClick={this.switchPlayerMode}
                        />
                        <AppIconButton
                            iconName="closeAll"
                            text="close all vids"
                            onClick={closeAllVideos}
                        />
                        <AppIconButton
                            iconName="fullScreen"
                            text="full screen"
                            onClick={this.goFullScreen}
                        />
                    </>
                ) : (
                    <></>
                )}
                <SearchField
                    onSubmit={submitSearch}
                    placeholder={`Search${
                        numVids !== null ? ` ${numberWithCommas(numVids)} videos` : ''
                    }...`}
                    ref={this.searchFieldRef}
                />
                <InformationPanel />
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layoutState = state.notUndoable as LayoutState;
    const presentState = state.undoable.present as ContentViewerState;
    return {
        isSinglePlayer: layoutState.isSingleVideoPlayer,
        areVidsOpen: presentState.videos && presentState.videos.length > 0,
        numVids: layoutState.numVidsTotal,
    };
};

const mapDispatchToProps = {
    submitSearch,
    setIsSinglePlayer,
    closeAllVideos,
    setPlayerFullScreen,
};

const MainTopBar = connect(mapStateToProps, mapDispatchToProps)(MainTopBarComponent);

export default MainTopBar;
