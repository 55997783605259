import * as React from 'react';
import {Component} from 'react';
import './VideoPreview.scss';

interface VideoPreviewProps {
    vidUrl: string;
    isHovered: boolean;
    className?: string;
}

export default class VideoPreview extends Component<VideoPreviewProps> {
    vidRefObj: HTMLVideoElement;

    vidRef = (ref: HTMLVideoElement) => {
        if (ref) {
            this.vidRefObj = ref;
            const { isHovered } = this.props;
            if (isHovered) {
                ref.play();
            }
        }
    };

    shouldComponentUpdate(nextProps: Readonly<VideoPreviewProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return nextProps.vidUrl !== this.props.vidUrl || nextProps.className !== this.props.className || this.props.isHovered !== nextProps.isHovered;
    }

    render() {
        const { className, vidUrl, isHovered } = this.props;
        // console.log(isHovered)
        if (this.vidRefObj) {
            if (isHovered) {
                    this.vidRefObj.play();
            } else {
                this.vidRefObj.pause();
                this.vidRefObj.currentTime = 0;
            }
        }
        return (
            <video ref={this.vidRef} src={vidUrl} className={className} loop muted playsInline />
        );
    }
}
