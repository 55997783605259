export default class DebouncingExecutor {
    private executing: () => void;

    private scheduledTimeout: ReturnType<typeof setTimeout>;

    private performAction(): void {
        const toExecute = this.executing;
        this.executing = null;
        toExecute();
    }

    bufferedExecute(
        action: () => void,
        timeout: number,
        isResponsive?: boolean,
        firstResponse?: number,
    ): void {
        if (!this.executing) {
            this.executing = action;
            this.scheduledTimeout = setTimeout(
                () => this.performAction(),
                firstResponse || timeout,
            );
        } else {
            this.executing = action;
            if (!isResponsive) {
                clearTimeout(this.scheduledTimeout);
                this.scheduledTimeout = setTimeout(() => this.performAction(), timeout);
            }
        }
    }
}
