export interface PagingConfig {
    startGroup: Array<number>;
    middleGroup: Array<number>;
    endGroup: Array<number>;
}

export function getPageNumSplit(
    numPages: number,
    pagesAround: number,
    currentPage: number,
): PagingConfig {
    const startGroup = [];
    const middleGroup = [];
    const endGroup = [];
    const firstBreak = currentPage > 2 + pagesAround;
    const secondBreak = currentPage <= numPages - pagesAround - 2;
    if (!firstBreak && !secondBreak) {
        for (let i = 1; i <= numPages; i++) {
            startGroup.push(i);
        }
    } else if (firstBreak && secondBreak) {
        startGroup.push(1);
        for (let i = currentPage - pagesAround; i <= currentPage + pagesAround; i++) {
            middleGroup.push(i);
        }
        endGroup.push(numPages);
    } else if (firstBreak) {
        startGroup.push(1);
        for (let i = currentPage - pagesAround; i <= numPages; i++) {
            endGroup.push(i);
        }
    } else if (secondBreak) {
        endGroup.push(numPages);
        for (let i = 1; i <= currentPage + pagesAround; i++) {
            startGroup.push(i);
        }
    }
    return { startGroup, middleGroup, endGroup };
}

export function numPages(numItems: number, numPerPage: number): number {
    return Math.ceil(numItems / numPerPage);
}

export function currentPage(startIndex: number, numPerPage: number): number {
    return Math.ceil((startIndex + 7) / numPerPage);
}
