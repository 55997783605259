export function getElementContentWidth(element: HTMLElement) {
    const styles = window.getComputedStyle(element);
    const padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
    return element.clientWidth - padding;
}

export function getElementContentHeight(element: HTMLElement) {
    const styles = window.getComputedStyle(element);
    const padding = parseFloat(styles.paddingTop) + parseFloat(styles.paddingBottom);
    return element.clientHeight - padding;
}

export function getElementDocumentPos(el: HTMLDivElement) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

export function getPageWidth() {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth,
    );
}

export function getElementFullHeight(element: HTMLElement) {
    const list = [
        'margin-top',
        'margin-bottom',
        'border-top',
        'border-bottom',
        // 'height'
    ];
    // const contentHeight =
    const style = window.getComputedStyle(element)
    // console.log(element.clientHeight)
    return element.getBoundingClientRect().height + list
        .map(k =>  parseInt(style.getPropertyValue(k), 10) | 0)
        .reduce((prev, cur) => prev + cur);
}

export function getElementFullWidth(element: HTMLElement) {
    const list = [
        'margin-left',
        'margin-right',
        'border-left',
        'border-right',
        'padding-left',
        'padding-right',
        // 'height'
    ]
    // const contentHeight =
    const style = window.getComputedStyle(element)
    return element.getBoundingClientRect().width + list
        .map(k => parseInt(style.getPropertyValue(k), 10))
        .reduce((prev, cur) => prev + cur);
}