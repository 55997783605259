// export const isSfw = process.env.NODE_ENV !== 'production';
export const isSfw = false;

// export const dbHost = process.env.NODE_ENV !== 'production' ? 'snake' : 'localhost';
export const dbHost = 'localhost';

// export const dbHost = 'shazam';

export const backendPortPort = 8333;

export const libraryPathSeperator = '>';

export const sfwThumbs = [
    'https://images.hgmsites.net/med/lamborghini-huaracan_100730251_m.jpg|D|320:240',
    'https://www.classicdriver.com/sites/default/files/cars_images/avg_56.jpg|D|320:240',
    'https://cdn.shopify.com/s/files/1/0043/8471/8938/products/157660142016119757.jpg?v=1581118422|D|320:240',
    'https://www.autocar.co.uk/sites/autocar.co.uk/files/styles/body-image/public/mclaren-720s_0.jpg?itok=wZbSZ3FZ|D|320:240',
];

export const sfwVids = ['https://www.youtube.com/embed/E77jDu7V4w4'];

export const sfwAds = ['https://media.giphy.com/media/IR89hmGfAoz9PYp7k7/giphy-downsized.gif'];

export const gridNumPerRequest = 10;

export const numPerPage = gridNumPerRequest;

export const gridCacheCleanAfterReqs = 500;
