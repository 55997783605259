import * as React from 'react';
import { Component } from 'react';
import './LibraryBrowser.scss';
import { VGDataGetter } from 'common/components/VirtualGrid/VirtualGrid';
import { connect } from 'react-redux';
import { RootState } from 'adult/state/AdultappStateStore';
import SlideOutMenu, { SlideOutMenuActions } from 'common/components/SlideOutMenu/SlideOutMenu';
import LibraryController from 'adult/components/LibraryController/LibraryController';
import Observable from 'common/functions/Observable/Observable';
import {
    LibraryBrowserState,
    submitSearch,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { LayoutState, setGridStartIndex } from 'adult/state/LayoutState/LayoutState';
import { HomePageSlidingView } from 'adult/components/ApiComs/ApiTypes';
import { VirtualGridController } from 'common/components/VirtualGridAsync/VirtualGridCanvas/VirtualGridController';
import { ContentViewerState } from 'adult/state/ContentViewerState/ContentViewerState';
import ReactResizeDetector from 'react-resize-detector';

interface LibraryBrowserProps {
    dataGetter: VGDataGetter;
    isHomePage: boolean;
    submitSearch: (s: string) => void;
    initialStartIndex?: number;
    initialHomePageData?: Array<HomePageSlidingView>;
    initialHomePageNumItems: number;
    isMobile: boolean;
    vidsOpen?: boolean;
    setGridStartIndex: (n: number) => void;
}

const mapStateToProps = (state: RootState) => {
    const present = state.undoable.present as LibraryBrowserState;
    const layout = state.notUndoable as LayoutState;
    const presentState = state.undoable.present as ContentViewerState;

    return {
        vidsOpen: presentState.videos && presentState.videos.length > 0,
        isHomePage: present.libraryBrowserDataKey.isHomePage,
        isMobile: layout.isMobile,
    };
};

const mapDispatchToProps = {
    submitSearch,
    setGridStartIndex,
};

interface LibraryBrowserComponentState {
    viewportRef: HTMLElement;
    isLargeScroll: boolean;
}

export class LibraryBrowserComponent extends Component<
    LibraryBrowserProps,
    LibraryBrowserComponentState
> {
    private scrollListener = new Observable<number>();

    private libraryControllerScrollListener = new Observable<number>();

    private slideOutMenuListener = new Observable<SlideOutMenuActions>();

    private filtersShowStreamer = new Observable<boolean>();

    constructor(props: LibraryBrowserProps) {
        super(props);
        this.state = {
            viewportRef: null,
            isLargeScroll: false,
        };
        this.slideOutMenuListener.subscribe((action: SlideOutMenuActions) => {
            if (
                action === SlideOutMenuActions.HideByScroll ||
                action === SlideOutMenuActions.HideByMouse
            ) {
                this.filtersShowStreamer.next(false);
            } else if (action === SlideOutMenuActions.ShowByMouse) {
                this.filtersShowStreamer.next(true);
            }
        });
    }

    containerRef = (ref: HTMLElement) => {
        const { viewportRef } = this.state;
        if (ref && viewportRef !== ref) {
            this.setState((prevState) => ({ ...prevState, viewportRef: ref }));
        }
    };

    slideOutMenuAction = (action: SlideOutMenuActions) => {
        this.slideOutMenuListener.next(action);
    };

    viewPortScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { viewportRef } = this.state;
        if (e.target === viewportRef) {
            this.scrollListener.next(e.target.scrollTop);
        }
    };

    isLargeScroll = () => {
        const { viewportRef } = this.state;
        const container = viewportRef;
        const result = container?.scrollHeight > 1.5 * container?.offsetHeight;
        return result;
    };

    onResize = () => {
        const { viewportRef } = this.state;
        const result = viewportRef?.scrollHeight > 1.5 * viewportRef?.offsetHeight;
        if (this.state.isLargeScroll !== result) {
            this.setState((prevState) => ({ ...prevState, isLargeScroll: result }));
        }
    };

    render() {
        const { dataGetter, setGridStartIndex, initialStartIndex, isMobile, vidsOpen } = this.props;
        const { viewportRef, isLargeScroll } = this.state;
        return (
            <div styleName="libraryBrowser" ref={this.containerRef} onScroll={this.viewPortScroll}>
                <SlideOutMenu
                    shouldCollapse={this.isLargeScroll}
                    scrollStream={this.scrollListener}
                    actionListener={this.slideOutMenuAction}
                >
                    <LibraryController
                        shouldCollapse={isLargeScroll}
                        isMobile={isMobile}
                        showFiltersStream={this.filtersShowStreamer}
                        scrollStream={this.scrollListener}
                        scrollListener={this.libraryControllerScrollListener}
                    />
                </SlideOutMenu>
                <VirtualGridController
                    itemGetter={dataGetter}
                    initialStartIndex={initialStartIndex}
                    startIndexListener={setGridStartIndex}
                    viewPort={viewportRef}
                    scrollStream={this.scrollListener}
                    vidsOpen={vidsOpen}
                />
                <ReactResizeDetector handleHeight handleWidth onResize={this.onResize} />
            </div>
        );
    }
}

const LibraryBrowser = connect(mapStateToProps, mapDispatchToProps)(LibraryBrowserComponent);

export default LibraryBrowser;
