import {
    calcIndicesInView,
    calcNumItemsInView,
    calcNumPerRow,
    IndicesInView,
} from 'common/components/VirtualGrid/VirtualGridCanvas/calculators/VirtualGridCanvasCalculators';
import { getElementFullHeight, getElementFullWidth } from 'common/components/utils/ElementSizes';
import { calcSlidingViewIndiciesInViewNew } from 'common/components/SlidingContentView/SlidingContentViewCalc';

export const getGridParams = (grid: HTMLDivElement, viewPort: HTMLElement) => {
    if(!grid || !viewPort) return {};
    const gridItems = grid.children;
    let itemWidth = 0;
    let itemHeight = 0;
    if (gridItems.length > 3) {
        const gridItem = gridItems[1];
        itemWidth = parseFloat(window.getComputedStyle(gridItem).width);
        itemHeight = parseFloat(window.getComputedStyle(gridItem).height);
    }
    return {
        itemWidth,
        itemHeight,
        viewportTop: viewPort.offsetTop,
        gridTop: grid.offsetTop,
        gridLeft: grid.offsetLeft,
        viewportLeft: viewPort.offsetLeft,
        gridWidth: grid.offsetWidth,
        viewportHeight: viewPort.offsetHeight,
        vieportWidth: viewPort.offsetWidth,
    };
};

export const getIndiciesInView = (
    gridParams: ReturnType<typeof getGridParams>,
    horizontal: boolean,
    scroll: number,
    numItems: number,
) => {
    if (gridParams) {
        const posInGrid = scroll - gridParams.gridTop;
        const indicies = horizontal
            ? calcSlidingViewIndiciesInViewNew(
                scroll,
                gridParams.vieportWidth,
                gridParams.itemWidth,
            )
            : calcIndicesInView(
                posInGrid < 0 ? 0 : posInGrid,
                posInGrid + gridParams.viewportHeight,
                gridParams.gridWidth,
                gridParams.itemWidth,
                gridParams.itemHeight,
            );
        if (indicies.contentNum > 50) indicies.contentNum = 50;
        if (indicies.startIndex + indicies.contentNum > numItems) {
            indicies.contentNum = numItems - indicies.startIndex;
        }
        return indicies;
    }
    return null;
};

export function addBufferIndexes(
    indices: IndicesInView,
    gridParams: ReturnType<typeof getGridParams>,
    bufferRows: number,
): IndicesInView {
    let { numPerRow } = indices;
    if (!numPerRow) {
        numPerRow = calcNumPerRow(gridParams.gridWidth, gridParams.itemWidth);
    }
    const newStartIndex = indices.startIndex - bufferRows * numPerRow;
    const startIndex = newStartIndex < 0 ? 0 : newStartIndex;
    const addedAtStart = indices.startIndex - startIndex;
    const newIndicies = {
        visualStart: indices.startIndex,
        visualContentNum: indices.contentNum,
        startIndex,
        contentNum: indices.contentNum + numPerRow * bufferRows + addedAtStart,
        numPerRow,
        autoplayIndex: indices.autoplayIndex
    };
    return newIndicies;
}

export const getFullItemSize = (grid: HTMLDivElement) => {
    const gridItems = grid.children;
    let width = 0;
    let height = 0;
    if (gridItems.length > 3) {
        width = getElementFullWidth(gridItems[1]);
        height = getElementFullHeight(gridItems[1]);
    }
    return {
        width,
        height,
    };
};

export const getNearestStartIndex = (
    gridParams: ReturnType<typeof getGridParams>,
    index: number,
    horizontal: boolean,
    numItems: number,
) => {
    if (index < 0) return 0;
    if (index >= numItems) return numItems - 1;
    if (horizontal) return index;
    const numPerRow = calcNumPerRow(gridParams.gridWidth, gridParams.itemWidth);
    return index - (index % numPerRow);
};

export const getNumItemsInView = (
    gridParams: ReturnType<typeof getGridParams>,
    horizontal: boolean,
) => {
    return horizontal
        ? Math.round(gridParams.gridWidth / gridParams.itemWidth)
        : calcNumItemsInView(
            gridParams.itemWidth,
            gridParams.itemHeight,
            gridParams.gridWidth,
            gridParams.viewportHeight,
        );
};

// export const fillWithBuffers = (
//     children: Array<ReactElement>,
//     indicies: IndicesInView,
//     previousIndicies: IndicesInView,
// ) => {
//     const newChildren = [];
//     for (let i = indicies.startIndex; i < indicies.startIndex + indicies.contentNum; i++) {
//         if (
//             i >= previousIndicies.startIndex &&
//             i < previousIndicies.startIndex + previousIndicies.contentNum
//         ) {
//             const childArrayIndex = i - previousIndicies.startIndex;
//             newChildren.push(children[childArrayIndex]);
//         } else {
//             newChildren.push(<VirtualGridPlaceHolder key={`placeHolder${i}`} />);
//         }
//     }
//     return newChildren;
// };
