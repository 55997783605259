import * as React from 'react';
import './RangeSlider.scss';
import { Component } from 'react';
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';

interface RangeSliderProps {
    range: Array<number>;
    values: Array<number>;
    onUpdate: (values: number[]) => void;
}

export default class RangeSlider extends Component<RangeSliderProps> {
    render() {
        const { range, values, onUpdate } = this.props;
        return (
            <Slider
                className="sliderContainer"
                domain={[range[0], range[1]]}
                values={values}
                mode={2}
                onUpdate={onUpdate}
                step={0.001}
            >
                <Rail>
                    {({ getRailProps }) => {
                        return <div className="sliderRail" {...getRailProps()} />;
                    }}
                </Rail>
                <Handles>
                    {({ handles, getHandleProps }) => {
                        return (
                            <div className="sliderHandles">
                                {handles.map((handle) => {
                                    return (
                                        <div
                                            className="sliderHandle"
                                            key={handle.id}
                                            style={{ left: `${handle.percent}%` }}
                                            {...getHandleProps(handle.id)}
                                        >
                                            <div className="handleCenter" />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }}
                </Handles>
                <Tracks right={false} left={false}>
                    {({ tracks, getTrackProps }) => {
                        return (
                            <div className="sliderTracks">
                                {tracks.map(({ id, source, target }) => {
                                    return (
                                        <div
                                            className="sliderTrack"
                                            key={id}
                                            style={{
                                                left: `${source.percent}%`,
                                                width: `${target.percent - source.percent}%`,
                                            }}
                                            {...getTrackProps()}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }}
                </Tracks>
            </Slider>
        );
    }
}
