import * as React from 'react';
import MobileViewport from 'common/components/MobileViewport/MobileViewport';
import './App.scss';
import { FunctionComponent, PropsWithChildren } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppProps {}

const App: FunctionComponent<AppProps> = ({ children }: PropsWithChildren<AppProps>) => {
    return <MobileViewport>{children}</MobileViewport>;
};

export default App;
