import * as React from 'react';
import './MobileViewport.css';
import { FunctionComponent, PropsWithChildren, ReactChild, ReactChildren, useEffect } from 'react';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';

const executer = new DebouncingExecutor();

const onResize = () => {
    executer.bufferedExecute(
        () => {
            document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
            // document.body.style.height = `${window.innerHeight}px`;
            // document.documentElement.style.setProperty('--app-height', );
        },
        500,
        false,
        1,
    );
};

let resetScroll: ReturnType<typeof setTimeout>;

const scrollListener = (event: UIEvent) => {
    const element = event.target as HTMLDivElement | Document;
    let offset = 0;
    if (element === document && !offset) {
        offset = window.pageYOffset || document.documentElement.scrollTop;
    } else if ('scrollTop' in element) {
        offset = element.scrollTop;
    }
    document.documentElement.style.setProperty('--mobile-viewport-offset', `${offset}px`);
    clearTimeout(resetScroll);
    resetScroll = setTimeout(() => {
        if (element === document) {
            window.scrollTo(0, 0);
        } else if ('scrollTop' in element) {
            element.scrollTop = 0;
        }
    }, 1000);
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MobileViewportProps {}

// Tied to being inside root.
const MobileViewport: FunctionComponent<MobileViewportProps> = ({
    children,
}: PropsWithChildren<MobileViewportProps>) => {
    useEffect(() => {
        window.addEventListener('resize', onResize);
        let scrolled: HTMLElement | Window = document.getElementById('root').parentElement;
        if (scrolled === document.body) {
            scrolled = window;
        }
        scrolled.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('resize', onResize);
            scrolled.removeEventListener('scroll', scrollListener);
        };
    }, []);
    return <>{children}</>;
};

export default MobileViewport;
