import * as React from 'react';
import { Component } from 'react';
import './SingleVideoPlayerControllerPanel.scss';
import { Coord, Dims, VideoItem } from 'common/types/AppTypes';
import { countDupes } from 'common/components/VideoPlayer/VideoPlayer';
import { processThumbnailSource, sfwItemConvert } from 'adult/components/PgoMediaItem/PgoMediaItem';
import CloseButton from 'common/components/CloseButton/CloseButton';
import 'common/styles/CommonStyles.scss';
import ArrowButton from 'common/components/ArrowButton/ArrowButton';

interface SingleVideoPlayerControllerPanelProps {
    videos: Array<VideoItem>;
    onItemClick: (i: number) => void;
    vidCoord: Coord;
    indexShown: number;
    closeClick: () => void;
    isHidden?: boolean;
}

interface SingleVideoPlayerControllerPanelState {
    isShowing: boolean;
}

const padding = 5;

function getThumbDims(videoDims: Dims, numVids: number): Dims {
    if (numVids < 0 || !videoDims || !videoDims.width) return {} as Dims;
    const maxWidth = videoDims.width / numVids - 2 * padding;
    const maxHeight = videoDims.height / 4 - 2 * padding;
    const minSideSize = Math.min(maxHeight, maxWidth);
    return {
        width: minSideSize,
        height: minSideSize,
    };
}

function getImgStyleName(indexShown: number, thumbIndex: number) {
    return `thumb ${indexShown === thumbIndex ? ' activeThumb' : ''}`;
}

export default class SingleVideoPlayerControllerPanel extends Component<
    SingleVideoPlayerControllerPanelProps,
    SingleVideoPlayerControllerPanelState
> {
    constructor(props: SingleVideoPlayerControllerPanelProps) {
        super(props);
        this.state = {
            isShowing: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<SingleVideoPlayerControllerPanelProps>): void {
        const { indexShown } = this.props;
        if (prevProps.indexShown !== indexShown) {
            this.setState({ isShowing: true }, () => {
                setTimeout(() => {
                    this.setState({ isShowing: false });
                }, 500);
            });
        }
    }

    leftClicked = () => {
        const { onItemClick, indexShown } = this.props;
        const next = indexShown === 0 ? 0 : indexShown - 1;
        onItemClick(next);
    };

    rightClicked = () => {
        const { onItemClick, indexShown, videos } = this.props;
        const next = indexShown === videos.length - 1 ? videos.length - 1 : indexShown + 1;
        onItemClick(next);
    };

    render() {
        const { videos, vidCoord, onItemClick, indexShown, closeClick, isHidden } = this.props;
        const { isShowing } = this.state;
        const dupeCounter = {};
        const imgs = videos.map((item, i) => (
            <img
                styleName={getImgStyleName(indexShown, i)}
                style={getThumbDims(vidCoord, videos.length)}
                key={item.id + countDupes(dupeCounter, item)}
                src={processThumbnailSource(sfwItemConvert(item).thumbnail)}
                onClick={() => onItemClick(i)}
            />
        ));
        const panelStyle =
            vidCoord && vidCoord.width
                ? { width: vidCoord.width, top: vidCoord.y, left: vidCoord.x }
                : {};
        const closeButtonStyle = vidCoord && vidCoord.height ? { top: vidCoord.height * 0.07 } : {};
        const styleName = `panel${isHidden ? ' hidden' : ''}${isShowing ? ' shownPanel' : ''}`;
        const showArrows = videos && videos.length > 1 && panelStyle.width;
        return (
            <>
                <div styleName={styleName} style={panelStyle}>
                    {showArrows ? (
                        <>
                            <ArrowButton left onClick={this.leftClicked} />
                            <ArrowButton onClick={this.rightClicked} />{' '}
                        </>
                    ) : (
                        <></>
                    )}
                    <CloseButton onClick={closeClick} style={closeButtonStyle} />
                    {imgs}
                </div>
            </>
        );
    }
}
