import * as React from 'react';
import { ChangeEvent, Component, createRef } from 'react';
import './SearchSuggestionsInput.scss';
import Async from 'react-async';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';
import {
    getThumbnail,
    processThumbnailSource,
    sfwItemConvert,
} from 'adult/components/PgoMediaItem/PgoMediaItem';
import { flatMap } from 'common/functions/Utils/ArrayHelpers';
import 'common/styles/CommonStyles.scss';
import { libraryPathSeperator } from 'adult/confg/AppConfig';
import { Dir } from 'common/types/AppTypes';

export interface Suggestion {
    Title: string;
    thumbnail?: string;
    dir?: Dir;
}

export type SuggestionsFetcher = (
    input: string,
    consumer: (r: Record<string, Array<Suggestion>>) => void,
) => void;

interface SearchSuggestionsInputProps {
    suggestionFetcher: SuggestionsFetcher;
    onSubmit: (value: string) => void;
    dirChose: (dir: Dir) => void;
    onFocused?: (b: boolean) => void;
    placeholder: string;
    style?: unknown;
    minText?: number;
}

interface SearchSuggestionsInputState {
    inputFieldText: string;
    suggestionsQuery: string;
    suggestionIndexSelected: number;
    hoveringOnSuggestion: boolean;
    isHidden: boolean;
}

interface SuggestionsPanelProps {
    suggestions: Record<string, Array<Suggestion>>;
    onSubmit: (value: string | Dir, isDir?: boolean) => void;
    selected: number;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    suggestRefs: Array<HTMLDivElement>;
}

function getSuggestionsList(suggestions: Record<string, Array<Suggestion>>) {
    return flatMap(Object.keys(suggestions), (key) => {
        return suggestions[key].map((suggestion) => {
            suggestion.dir = {
                path: `root${libraryPathSeperator}${key}${libraryPathSeperator}${suggestion.Title}`,
                isMediaParent: true,
                title: suggestion.Title,
            };
            return suggestion;
        });
    });
}

function workOutActualSelectedIndex(numItems: number, unNormalisedIndex: number) {
    numItems += 1;
    unNormalisedIndex =
        unNormalisedIndex < 0 ? numItems + (unNormalisedIndex % numItems) : unNormalisedIndex;
    return (unNormalisedIndex % numItems) - 1;
}

function boldTitleFormat(text: string) {
    const first = text.split('<b>');
    if (first.length < 2) return text;
    const second = first[first[0] ? 0 : 1].split('</b>');
    if (second.length < 2) return first.join('');
    return (
        <>
            {first[0] ? first[0] : ''} <b>{second[0]}</b>
            {second[1] ? second[1] : ''}
        </>
    );
}

const fetchSuggestions = (fetcher: SuggestionsFetcher, minNum: number) => async (
    { input },
    { signal },
) => {
    const promise = new Promise((resolve, reject) => {
        if (input && input.length > minNum - 1) {
            fetcher(input, resolve);
        } else {
            resolve(null);
        }
    });
    return promise;
};

export function removeBoldTags(text: string): string {
    return text.replace('<b>', '').replace('</b>', '');
}

const SuggestionsPanel = ({
    suggestions,
    onSubmit,
    selected,
    onMouseEnter,
    onMouseLeave,
    suggestRefs,
}: SuggestionsPanelProps) => {
    let suggestionIndex = -1;
    suggestRefs.length = 0;
    return flatMap(Object.keys(suggestions), (suggestionGroupTitle) => {
        const suggestItems = suggestions[suggestionGroupTitle];
        const elements = suggestItems.map((item) => {
            sfwItemConvert(item);
            suggestionIndex++;
            const styleName = `suggestion${suggestionIndex === selected ? ' highlighted' : ''}`;
            return (
                <div
                    styleName={styleName}
                    key={item.dir.path}
                    onClick={() => onSubmit(item.dir, true)}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    ref={(ref) => {
                        if (ref) {
                            suggestRefs.push(ref);
                        }
                    }}
                >
                    {boldTitleFormat(item.Title)}
                    {item.thumbnail ? (
                        <img
                            alt={`Thumbnail for ${item.Title} search suggestion`}
                            src={processThumbnailSource(getThumbnail(item))}
                        />
                    ) : (
                        <> </>
                    )}
                </div>
            );
        });
        elements.unshift(
            <div styleName="groupTitle" key={suggestionGroupTitle}>
                {suggestionGroupTitle}
            </div>,
        );
        return elements;
    });
};

export default class SearchSuggestionsInput extends Component<
    SearchSuggestionsInputProps,
    SearchSuggestionsInputState
> {
    private inputToRequestDebouncer = new DebouncingExecutor();

    private asyncPromisFn: unknown;

    private suggestsList: Array<Suggestion>;

    private suggestRefsList: Array<HTMLDivElement> = [];

    private suggestionsContainerRef = createRef<HTMLDivElement>();

    private inputRef = createRef<HTMLInputElement>();

    constructor(props: SearchSuggestionsInputProps) {
        super(props);
        this.state = {
            inputFieldText: '',
            suggestionsQuery: null,
            suggestionIndexSelected: 0,
            hoveringOnSuggestion: false,
            isHidden: false,
        };
        this.asyncPromisFn = fetchSuggestions(props.suggestionFetcher, this.getMinChars(props));
    }

    componentDidUpdate(prevProps: Readonly<SearchSuggestionsInputProps>): void {
        const { suggestionFetcher } = this.props;
        if (prevProps.suggestionFetcher !== suggestionFetcher) {
            this.asyncPromisFn = fetchSuggestions(suggestionFetcher, this.getMinChars(this.props));
        }
    }

    getMinChars = (props: SearchSuggestionsInputProps): number => {
        return props.minText > 0 ? props.minText : 3;
    };

    onSubmit = (value: string | Dir, isDir?: boolean) => {
        const { onSubmit, dirChose } = this.props;
        if (isDir && dirChose) {
            dirChose((value as Dir));
        } else if (value.title) {
            onSubmit(value.title);
        } else {
            onSubmit(value as string);
        }
        this.suggestsList = null;
        this.setState((prevState) => ({
            ...prevState,
            inputFieldText: '',
            suggestionsQuery: null,
            suggestionIndexSelected: null,
            hoveringOnSuggestion: false,
        }));
    };

    inputFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputVal = event.target.value;
        this.setState((prevState) => ({
            ...prevState,
            inputFieldText: inputVal,
        }));
        this.inputToRequestDebouncer.bufferedExecute(() => {
            this.setState((prevState) => ({
                ...prevState,
                suggestionsQuery: prevState.inputFieldText,
            }));
        }, 350);
    };

    getScrollToMoveTo = (suggestionIndex: number): number => {
        if (suggestionIndex < 0) return null;
        const containerRef = this.suggestionsContainerRef.current;
        const suggestRef = this.suggestRefsList[suggestionIndex];
        const bodyRect = containerRef.getBoundingClientRect();
        const elemRect = suggestRef.getBoundingClientRect();
        const { scrollTop } = containerRef;
        const topOffset = elemRect.top - bodyRect.top + scrollTop;
        const bottomOffset = elemRect.top + elemRect.height - bodyRect.top + scrollTop;
        const containerHeight = bodyRect.height;
        if (scrollTop > topOffset) {
            return topOffset;
        }
        if (scrollTop + containerHeight < bottomOffset) {
            return bottomOffset - containerHeight;
        }
        return null;
    };

    adjustScroll = (): void => {
        const { suggestionIndexSelected } = this.state;
        const newScroll = this.getScrollToMoveTo(
            workOutActualSelectedIndex(this.suggestRefsList.length, suggestionIndexSelected),
        );
        if (newScroll) {
            this.suggestionsContainerRef.current.scrollTop = newScroll;
        }
    };

    keyListener = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Escape':
                e.preventDefault();
                let { inputFieldText, suggestionsQuery } = this.state;
                if (!this.getInputFieldValue()) {
                    this.inputRef.current.blur();
                } else if (!suggestionsQuery) {
                    inputFieldText = '';
                }
                this.suggestsList = null;
                this.setState((prevState) => ({
                    ...prevState,
                    inputFieldText,
                    suggestionIndexSelected: null,
                    suggestionsQuery: null,
                    hoveringOnSuggestion: false,
                }));
                break;
            case 'Enter':
                e.preventDefault();
                const { suggestionIndexSelected } = this.state;

                if (suggestionIndexSelected || suggestionIndexSelected === 0) {
                    this.onSubmit(this.getDirSelected(), true);
                } else {
                    this.onSubmit(this.getInputFieldValue());
                }
                break;
            case 'ArrowUp':
                this.setState(
                    (prevState) => ({
                        ...prevState,
                        suggestionIndexSelected: prevState.suggestionIndexSelected - 1,
                        hoveringOnSuggestion: false,
                    }),
                    this.adjustScroll,
                );
                break;
            case 'ArrowDown':
                this.setState(
                    (prevState) => ({
                        ...prevState,
                        suggestionIndexSelected: prevState.suggestionIndexSelected + 1,
                        hoveringOnSuggestion: false,
                    }),
                    this.adjustScroll,
                );
                break;
            default:
                if (this.state.suggestionIndexSelected !== null) {
                    this.setState((prevState) => ({
                        ...prevState,
                        suggestionIndexSelected: null,
                        inputFieldText: this.getInputFieldValue(),
                    }));
                }
                break;
        }
    };

    getDirSelected = () => {
        const { inputFieldText, suggestionIndexSelected } = this.state;
        const currentSelectedIndex = this.suggestsList
            ? workOutActualSelectedIndex(this.suggestsList.length, suggestionIndexSelected)
            : -1;
        const inputValue =
            this.suggestsList && currentSelectedIndex > -1
                ? this.suggestsList[currentSelectedIndex].dir
                : inputFieldText;
        return inputValue;
    };

    getInputFieldValue = () => {
        const { inputFieldText, suggestionIndexSelected } = this.state;
        const currentSelectedIndex = this.suggestsList
            ? workOutActualSelectedIndex(this.suggestsList.length, suggestionIndexSelected)
            : -1;
        const inputValue =
            this.suggestsList && currentSelectedIndex > -1
                ? this.suggestsList[currentSelectedIndex].Title
                : inputFieldText;
        return removeBoldTags(inputValue);
    };

    onMouseEnter = () => {
        this.setState((prevState) => ({ ...prevState, hoveringOnSuggestion: true }));
    };

    onMouseLeave = () => {
        this.setState((prevState) => ({ ...prevState, hoveringOnSuggestion: false }));
    };

    onBlur = () => {
        const { onFocused } = this.props;
        const { hoveringOnSuggestion } = this.state;
        if (!hoveringOnSuggestion) {
            this.setState((prevState) => ({ ...prevState, isHidden: true }));
        }
        if (onFocused) onFocused(false);
    };

    onFocus = () => {
        const { onFocused } = this.props;
        setTimeout(() => {
            this.setState((prevState) => ({ ...prevState, isHidden: false }));
        }, 200);
        if (onFocused) onFocused(true);
    };

    focusIn = () => {
        if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    blur = () => {
        if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.blur();
        }
    };

    areSuggestsShowing = () => {
        const { suggestionsQuery } = this.state;
        return suggestionsQuery && this.suggestsList && this.suggestsList.length > 0;
    };

    clicked = () => {
        this.setState((prevState) => ({
            ...prevState,
            suggestionsQuery: prevState.inputFieldText,
        }));
    };

    render() {
        const { suggestionFetcher, onSubmit, placeholder, style } = this.props;
        const {
            inputFieldText,
            suggestionsQuery,
            suggestionIndexSelected,
            hoveringOnSuggestion,
            isHidden,
        } = this.state;
        const suggestionsStyleName = `suggestions${isHidden ? ' ' : ' shownSuggestions'}`;
        return (
            <div styleName="container" style={style}>
                <input
                    type="search"
                    name="search"
                    value={this.getInputFieldValue()}
                    onChange={this.inputFieldChange}
                    onKeyDown={this.keyListener}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    ref={this.inputRef}
                    onClick={this.clicked}
                    placeholder={placeholder}
                    autoComplete="off"
                />
                <Async
                    promiseFn={this.asyncPromisFn}
                    input={suggestionsQuery}
                    watch={suggestionsQuery}
                >
                    {({ data, error, isPending }) => {
                        // console.log(countSuggestions(data));
                        if (data) {
                            this.suggestsList = getSuggestionsList(data);
                            const selectedIndex = hoveringOnSuggestion
                                ? -1
                                : workOutActualSelectedIndex(
                                    this.suggestsList.length,
                                      suggestionIndexSelected,
                                );
                            return (
                                <div
                                    styleName={suggestionsStyleName}
                                    ref={this.suggestionsContainerRef}
                                >
                                    <SuggestionsPanel
                                        suggestions={data}
                                        onSubmit={this.onSubmit}
                                        selected={selectedIndex}
                                        onMouseEnter={this.onMouseEnter}
                                        onMouseLeave={this.onMouseLeave}
                                        suggestRefs={this.suggestRefsList}
                                    />
                                </div>
                            );
                        }
                        return <></>;
                    }}
                </Async>
            </div>
        );
    }
}
