import * as React from 'react';
import './SwipeTip.scss';
import { Component } from 'react';

interface SwipeTipProps {
    isShown: boolean;
}

export default class SwipeTip extends Component<SwipeTipProps> {
    render() {
        const { isShown } = this.props;
        const styleName = `container${isShown ? ' shown' : ' hidden'}`;

        return (
            <div styleName={styleName}>
                <i className="icon-swipeTip" />
                <span>Swipe Gestures On Overlay</span>
            </div>
        );
    }
}
