import { AppState, RootState } from 'adult/state/AdultappStateStore';
import {
    LibDataKey,
    LibraryBrowserState,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { Sort, VideoItem } from 'common/types/AppTypes';
import { ContentViewerState } from 'adult/state/ContentViewerState/ContentViewerState';
import {libraryPathSeperator, numPerPage} from 'adult/confg/AppConfig';
import { possibleOrientations } from 'adult/components/OrientationsController/AvailableOrientations';
import {currentPage} from "common/components/PagingController/PagingCalculator";
import {LayoutState} from "adult/state/LayoutState/LayoutState";

export interface PageDetails {
    title: string;
    description: string;
}

function singlifyParent(name: string): string {
    let s = name.replace('ies', 'y');
    if (s.endsWith('s')) {
        s = s.substring(0, s.length - 1);
    }
    return s;
}

function searchDetails(
    search: string,
    vidsTitlePart: string,
    vidsDescrPart: string,
    filtersTitlePart: string,
    filtersDescrPart: string,
    orients: string,
    sortString: PageDetails,
    pagePart: string,
) {
    return {
        title: `${search}${pagePart} - ${filtersTitlePart} Top Video Selection${
            sortString.title
        }${vidsTitlePart ? ' | ' : ''}${vidsTitlePart} | YouLibs.com`,
        description: `Aggregation of ${search} videos ${filtersDescrPart}${sortString.description}from Youtube ${vidsDescrPart}`,
    };
}

function videoSelectionDetails(
    name: string,
    parent: string,
    vidsTitlePart: string,
    vidsDescrPart: string,
    filtersTitlePart: string,
    filtersDescrPart: string,
    orients: string,
    sortString: PageDetails,
    pagePart: string,
): PageDetails {
    if (!parent && name === 'All Videos') {
        return {
            title: `${name}${pagePart} - ${filtersTitlePart} Top Video Selection${
                sortString.title
            }${vidsTitlePart ? ' | ' : ''}${vidsTitlePart} | YouLibs.com`,
            description: `Videos ${filtersDescrPart}${sortString.description}from YouTube`,
        };
    }
    if (!parent) {
        return {
            title: `${name}${pagePart} - ${filtersTitlePart} Top${orients} Video Selection${
                sortString.title
            }${vidsTitlePart ? ' | ' : ''}${vidsTitlePart} | YouLibs.com`,
            description: `Aggregation of ${name} videos ${filtersDescrPart}${sortString.description}from youtube `,
        };
    }
    if(parent === 'Tags') {
        name = name.split('-').join(' ');
    }
    const sParent = singlifyParent(parent);
    return {
        title: `${name}${pagePart} - ${sParent} ${filtersTitlePart} Top Video Selection${
            sortString.title
        }${vidsTitlePart ? ' | ' : ''}${vidsTitlePart} | YouLibs.com`,
        description: `Aggregation of top ${name} ${sParent} videos ${filtersDescrPart}${sortString.description}from Youtube ${vidsDescrPart}`,
    };
}

function getSortString(sort: Sort) {
    if (!sort || (sort && sort.sortField.name === 'Popularity' && !sort.isAsc))
        return { title: ' ', description: ' ' };
    return {
        title: ` sorted by ${sort.sortField.name} ${sort.isAsc ? 'asc.' : 'desc'} `,
        description: ` sorted by ${sort.sortField.name} in ${
            sort.isAsc ? 'ascending order' : 'descending order'
        } `,
    };
}

function orientationsString(orientations: Array<string>) {
    if (!orientations || (orientations.length === 1 && orientations[0] === 's')) {
        return ' ';
    }
    if (orientations.length === 1) {
        return ` ${possibleOrientations[orientations[0]]} `;
    }
    const starting = orientations.slice(0, orientations.length - 1);

    return ` ${starting.map((or) => possibleOrientations[or]).join(', ')} and ${
        possibleOrientations[orientations[orientations.length - 1]]
    } `;
}

export function genInfo(key: LibDataKey, videos: Array<VideoItem>, page: number): PageDetails {
    let vidsTitlePart = '';
    let vidsDescrPart = '';
    let filtersTitlePart = '';
    let filtersDescrPart = '';
    if (videos && videos.length > 0) {
        vidsTitlePart = `${videos.length} videos open`;
        vidsDescrPart = `${videos.length} videos open in our multi video player.`;
    }

    if (key.filters && key.filters.length > 0) {
        if (key.filters.length === 1) {
            filtersTitlePart = `${
                key.filters[0].label ? key.filters[0].label : key.filters[0].field.name
            } Filtered`;
            filtersDescrPart = `with ${
                key.filters[0].label ? key.filters[0].label : key.filters[0].field.name
            } filter applied`;
        } else {
            filtersTitlePart = `Filtered (${key.filters.length})`;
            filtersDescrPart = `with ${key.filters.length} filters applied`;
        }
    }

    const orients = orientationsString(key.orientations);
    const sortString = getSortString(key.sort);

    let pagePart = ' ';
    if(page && page > 1) {
        // pagePart = {
        //     title: `Page ${page}`,
        //     description: `page ${page}`
        // };
        pagePart = ` Page ${page} `;
    }

    if (key.isHomePage) {
        return {
            title: `YouLibs.com - Media Library Demo`,
            description: `A demo of the Media Library with a bunch of youtube videos`,
        };
    }
    if (key.parentPath) {
        const parts = key.parentPath.split(libraryPathSeperator);
        const name = parts[parts.length - 1];
        const parent = parts.length > 2 ? parts[parts.length - 2] : null;
        return videoSelectionDetails(
            name,
            parent,
            vidsTitlePart,
            vidsDescrPart,
            filtersTitlePart,
            filtersDescrPart,
            orients,
            sortString,
            pagePart
        );
    }
    if ((key.videoName || (key.relatedTo && key.relatedTo.length === 1)) && videos.length === 1) {
        const videoTitle = videos[0].Title;
        const title = filtersTitlePart
            ? `${videoTitle} - Video, with ${filtersTitlePart}${orients}video selection${pagePart} | YouLibs.com`
            : `${videoTitle} - Video${pagePart} | YouLibs.com`;
        return {
            title,
            description: `Watch '${videoTitle}' video, browse related videos ${filtersDescrPart}. YouLibs.com.`,
        };
    }
    if (key.relatedTo && key.relatedTo.length > 1 && videos.length > 1) {
        const numPlayers = videos.length;
        return {
            title: `Multi Video Player (${numPlayers}) and a selection of related videos ${filtersTitlePart}${pagePart}`,
            description: `Watch ${numPlayers} videos and browse a selection of videos ${filtersDescrPart} related to them.`,
        };
    }
    if (key.searchTerm) {
        return searchDetails(
            key.searchTerm,
            vidsTitlePart,
            vidsDescrPart,
            filtersTitlePart,
            filtersDescrPart,
            orients,
            sortString,
            pagePart
        );
    }
    return { title: ``, description: `` };
}
export function removeQuotesFromDescription(details: PageDetails) {
    details.description = details.description.split('"').join(`'`);
    return details;
}

export function getPageDetails(
    key: LibDataKey,
    videos: Array<VideoItem>,
    page: number,
): PageDetails {
    return removeQuotesFromDescription(genInfo(key, videos, page));
}

export function stateToPageDetails(undoable: AppState, notUndoable: AppState): PageDetails {
    const libraryBrowserState = undoable as LibraryBrowserState;
    const contentViewerState = undoable as ContentViewerState;
    const layoutState = notUndoable as LayoutState;
    const page = currentPage(layoutState.gridStartIndex, numPerPage)
    return getPageDetails(
        libraryBrowserState.libraryBrowserDataKey,
        contentViewerState.videos,
        page,
    );
}
