import { HasId } from 'common/types/AppTypes';
import { Dispatch } from 'redux';
import { AppAction, RootState } from 'adult/state/AdultappStateStore';
import {
    closeAllVidsA,
    closeVideoA,
    ContentViewerState,
    openVideoA,
} from 'adult/state/ContentViewerState/ContentViewerState';
import { setPlayerFullScreen } from 'adult/state/LayoutState/LayoutState';
import { ThunkAction } from 'redux-thunk';
import {
    LibraryBrowserState,
    returnPreRelatedState,
    showRecommendations,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import UrlControllerInstance from 'common/client/UrlController';
import { sendRequest } from 'adult/components/ApiComs/ClientApiComs';
import {setAdState} from "adult/state/AdsState";

export const openVideo = (video: HasId): ThunkAction<void, RootState, HasId, AppAction> => (
    dispatch: Dispatch,
    getState: () => RootState,
) => {
    // sendRequest('validation', { videoId: video.id }, (res) => {
    //     // console.log(res);
    //     dispatch(setAdState(res));
    // });
    let videoGridState = getState().undoable.present as ContentViewerState;
    let libraryBrowserState = getState().undoable.present as LibraryBrowserState;
    const isCompound =
        videoGridState.videos.length === 0 ||
        (libraryBrowserState.libraryBrowserDataKey.relatedTo &&
            libraryBrowserState.libraryBrowserDataKey.relatedTo.length > 0);
    if (isCompound) UrlControllerInstance.startCompoundAction();
    dispatch(openVideoA(video));
    videoGridState = getState().undoable.present as ContentViewerState;
    libraryBrowserState = getState().undoable.present as LibraryBrowserState;
    if (
        videoGridState.videos.length === 1 ||
        (libraryBrowserState.libraryBrowserDataKey.relatedTo &&
            libraryBrowserState.libraryBrowserDataKey.relatedTo.length > 0)
    ) {
        UrlControllerInstance.stopCompoundAction(2);
        dispatch(showRecommendations(videoGridState.videos.map((vid) => vid.id)));
    }
};

export const closeVideo = (video: HasId) => (dispatch: Dispatch, getState: () => RootState) => {
    const { relatedTo } = (getState().undoable
        .present as LibraryBrowserState).libraryBrowserDataKey;
    const relatedToOpen = relatedTo && relatedTo.length > 0;
    let videoGridState = getState().undoable.present as ContentViewerState;
    let libraryBrowserState = getState().undoable.present as LibraryBrowserState;
    if (
        (videoGridState.videos.length === 1 && relatedToOpen) ||
        (libraryBrowserState.libraryBrowserDataKey.relatedTo &&
            libraryBrowserState.libraryBrowserDataKey.relatedTo.length > 1)
    )
        UrlControllerInstance.startCompoundAction();
    dispatch(closeVideoA(video));
    videoGridState = getState().undoable.present as ContentViewerState;
    libraryBrowserState = getState().undoable.present as LibraryBrowserState;
    if (videoGridState.videos.length === 0) {
        dispatch(setPlayerFullScreen(false));
        if (relatedToOpen) {
            UrlControllerInstance.stopCompoundAction(2);
            dispatch(returnPreRelatedState());
        }
    } else if (
        libraryBrowserState.libraryBrowserDataKey.relatedTo &&
        libraryBrowserState.libraryBrowserDataKey.relatedTo.length > 0
    ) {
        UrlControllerInstance.stopCompoundAction(2);
        dispatch(showRecommendations(videoGridState.videos.map((vid) => vid.id)));
    }
};

export const closeAllVideos = () => (dispatch: Dispatch, getState: () => RootState) => {
    const { relatedTo } = (getState().undoable
        .present as LibraryBrowserState).libraryBrowserDataKey;
    const relatedToOpen = relatedTo && relatedTo.length > 0;
    if (relatedToOpen) UrlControllerInstance.startCompoundAction();
    dispatch(closeAllVidsA());
    if (relatedToOpen) {
        UrlControllerInstance.stopCompoundAction(2);
        dispatch(returnPreRelatedState());
    }
};
