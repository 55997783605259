import * as React from 'react';
import { Component, ReactNode } from 'react';
import './TopLayout.scss';
import { connect } from 'react-redux';
import {LayoutState, setContentViewLayout, setIsServerSide} from 'adult/state/LayoutState/LayoutState';
import ReactResizeDetector from 'react-resize-detector';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';
import {
    getElementContentHeight,
    getElementContentWidth,
} from 'common/components/utils/ElementSizes';
import { Dims } from 'common/types/AppTypes';
import {RootState} from "adult/state/AdultappStateStore";
import {LibraryBrowserState} from "adult/state/LibraryBrowserState/LibraryBrowserState";

interface TopLayoutProps {
    children?: Array<ReactNode>;
    setContentViewLayout?: (dims: Dims, isRow: boolean) => void;
    setIsServerSide: (isIt: boolean) => void;
    isServerSide: boolean;
}

interface TopLayoutState {
    isServerSide: boolean;
}

export class TopLayoutComponent extends Component<TopLayoutProps, TopLayoutState> {
    private resizeDebouncer = new DebouncingExecutor();

    private mainSplitView: HTMLDivElement;

    private width: number;

    private height: number;

    constructor(props: TopLayoutProps) {
        super(props);
        this.state = {
            isServerSide: true,
        };
    }

    componentDidMount() {
        const { setIsServerSide } = this.props;
        // setIsServerSide(false);
        // this.setState({ isServerSide: false });
    }

    mainSplitViewRef = (ref: HTMLDivElement) => {
        this.mainSplitView = ref;
        this.updateContentViewLayout();
    };

    resizeDetected = () => {
        this.updateContentViewLayout();
    };

    updateContentViewLayout = () => {
        if (this.mainSplitView) {
            this.resizeDebouncer.bufferedExecute(
                () => {
                    const { setContentViewLayout } = this.props;
                    const width = getElementContentWidth(this.mainSplitView);
                    const height = getElementContentHeight(this.mainSplitView);
                    if (width !== this.width || height !== this.height) {
                        this.width = width;
                        this.height = height;
                        if (typeof window !== 'undefined') {
                            const computedStyle = window.getComputedStyle(this.mainSplitView);
                            const flexFlow = computedStyle.flexFlow || computedStyle.flexDirection;
                            const isRow = flexFlow.indexOf('row') >= 0;
                            setContentViewLayout({ width, height }, isRow);
                        }
                    }
                },
                250,
                true,
            );
        }
    };

    render() {
        const { children, isServerSide } = this.props;
        // const { isServerSide } = this.state;
        const innerChilds = children.slice(1);
        return (
            <div styleName="topLayout" className={isServerSide ? 'ss' : 'cs'}>
                {children[0]}
                <div styleName="mainSplitView" ref={this.mainSplitViewRef}>
                    <ReactResizeDetector handleWidth handleHeight onResize={this.resizeDetected} />
                    {innerChilds}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layout = state.notUndoable as LayoutState;
    return {
        isServerSide: layout.isServerSide,
    };
};

const mapDispatchToProps = {
    setContentViewLayout,
    setIsServerSide,
};

const TopLayout = connect(mapStateToProps, mapDispatchToProps)(TopLayoutComponent);

export default TopLayout;
