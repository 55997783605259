import ReactDOM from 'react-dom';
import React from 'react';

import renderers from '../server/testRenderers';

export default function runClient(app, hot, callback) {
    let HotApp = app;

    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV !== 'production' && isHot) {
        HotApp = hot(HotApp);
    }

    if (process.env.NODE_ENV !== 'production' && window.componentName) {
        const renderer = renderers[window.componentName];
        if (renderer) {
            ReactDOM.hydrate(renderer, document.getElementById('container'));
        }
    } else {
        ReactDOM.hydrate(<HotApp />, document.getElementById('root'), callback);
    }
}
