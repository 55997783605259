import * as React from 'react';
import { Component, createRef, ReactElement } from 'react';
import './HomePage.scss';
import 'common/styles/CommonStyles.scss';
import { HomePageSlidingView } from 'adult/components/ApiComs/ApiTypes';

import {
    contentToDom,
    PgoMediaItemData,
    starNameToLink,
} from 'adult/components/PgoMediaItem/PgoMediaItem';
import SlidingContentViewFeed, {
    SlidingViewParams,
} from 'common/components/SlidingContentViewFeed/SlidingContentViewFeed';
import { libraryPathSeperator } from 'adult/confg/AppConfig';
import {
    DataFetcher,
    VGContentRequest,
    VGContentResponse,
} from 'common/components/VirtualGrid/VirtualGridDataGetterCache/VirtualGridDataGetterCache';
import { HomePageCachedDataGetter } from 'adult/components/HomePage/HomePageCachedDataGetter';
import { connect } from 'react-redux';
import { RootState } from 'adult/state/AdultappStateStore';
import { LayoutState } from 'adult/state/LayoutState/LayoutState';
import AppLink from 'common/components/AppLink/AppLink';
import {
    getOrientationsPath,
    getPathString,
} from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import { setLibPathM } from 'common/components/AppLink/MemoActionCreators';
import { LibraryBrowserState } from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import Observable from 'common/functions/Observable/Observable';
import { Dims } from 'common/types/AppTypes';
import { sendRequest } from 'adult/components/ApiComs/ClientApiComs';
import SlidingContentView from 'common/components/SlidingContentView/SlidingContentView';

interface HomePageProps {
    isHidden?: boolean;
    initialNumItems: number;
    initialData: Array<HomePageSlidingView>;
    topFillerHeight: number;
    scrollListener?: (n: number) => void;
    widthListener?: (width: number) => void;
    isServerSide: boolean;
    orientations: Array<string>;
    scrollStream: Observable<number>;
}

interface HomePageState {
    numViews: number;
    isServerSide: boolean;
    dirsViewHeight: number;
}

const homeCachedDataGetter = new HomePageCachedDataGetter();

function viewTitle(apiParams: HomePageSlidingView, orientations: Array<string>) {
    const { path } = apiParams;
    const parts = path.split(libraryPathSeperator);
    const title = parts[parts.length - 1];
    if (path.indexOf(`root${libraryPathSeperator}Channels`) > -1) {
        return starNameToLink("Channel: " + title, orientations);
    }
    const type = parts[1] === 'Tags'
                ? 'Tag: '
                : parts[1] === 'Categories'
                    ? 'Category: '
                    : '';
    return (
        <AppLink
            key={title}
            href={`${getOrientationsPath(orientations)}/${getPathString(path)}`}
            stateAction={setLibPathM({ path, isMediaParent: true })}
        >
            {type}{title}
        </AppLink>
    );
}

export class HomePageComponent extends Component<HomePageProps, HomePageState> {
    private hasBeenRendered = false;

    private slidingViewsRef = createRef<SlidingContentViewFeed>();

    private needRefresh = false;

    constructor(props: HomePageProps) {
        super(props);
        this.state = {
            isServerSide: true,
            dirsViewHeight: 0,
            numViews: props.initialNumItems,
        };
        this.props.scrollStream.subscribe((scroll) => {
            const { isHidden } = this.props;
            if (!isHidden) {
                this.slidingViewsRef.current.scroll(scroll);
            }
        });
    }

    componentDidMount(): void {
        this.setState({ isServerSide: false });
    }

    componentDidUpdate(prevProps: Readonly<HomePageProps>): void {
        const { orientations, isHidden } = this.props;
        const viewFeed = this.slidingViewsRef.current;
        if (prevProps.orientations !== orientations && viewFeed) {
            if (!isHidden) {
                viewFeed.refresh();
            } else {
                this.needRefresh = true;
            }
        } else if (!isHidden && this.needRefresh) {
            viewFeed.refresh();
            this.needRefresh = false;
        }
    }

    updateDirsViewHeight = (height: number) => {
        this.setState((prevState) => ({ ...prevState, dirsViewHeight: height }));
    };

    createItemGetter = (apiParams: HomePageSlidingView) => {
        return (
            start: number,
            num: number,
            itemConsumer: (returnItems: Array<ReactElement>) => void,
        ) => {
            const { orientations } = this.props;
            const key = {
                homeViewPath: apiParams.path,
                startIndex: start,
                numToTake: num,
                orientations,
            };
            homeCachedDataGetter.getViewItems(key, (res) => {
                itemConsumer((res.content as Array<PgoMediaItemData>).map(contentToDom));
            });
        };
    };

    convertParams = (apiParams: HomePageSlidingView) => {
        const { orientations } = this.props;
        const parts = apiParams.path.split(libraryPathSeperator);
        const title = parts[parts.length - 1];
        return {
            dims: apiParams.dims,
            numItems: apiParams.numItems,
            key: title,
            title: viewTitle(apiParams, orientations),
            initialItems: apiParams.content.map(contentToDom),
            getItems: this.createItemGetter(apiParams),
        };
    };

    viewGetter = (
        start: number,
        num: number,
        itemConsumer: (returnItems: Array<SlidingViewParams>) => void,
    ) => {
        const { orientations } = this.props;
        const key = {
            startIndex: start,
            numToTake: num,
            orientations,
        };
        homeCachedDataGetter.getViews(key, (items: VGContentResponse) => {
            const { numViews } = this.state;
            const consumeItems = () => {
                itemConsumer(
                    ((items.content as unknown) as Array<HomePageSlidingView>).map(
                        this.convertParams,
                    ),
                );
            };
            if (numViews !== items.numItems) {
                this.setState(
                    (prevState) => ({ ...prevState, numViews: items.numItems }),
                    consumeItems,
                );
            } else {
                consumeItems();
            }
        });
    };

    render() {
        const {
            isHidden,
            initialData,
            topFillerHeight,
            widthListener,
            scrollListener,
            orientations,
            isServerSide,
        } = this.props;
        const { dirsViewHeight, numViews } = this.state;
        const styleName = `homePage${isHidden ? ' invisible' : ''}`;
        const initData = initialData ? initialData.map(this.convertParams) : [];
        const shouldRender = this.hasBeenRendered || !isHidden;
        this.hasBeenRendered = shouldRender;
        return shouldRender ? (
            <div styleName={styleName}>
                <SlidingContentViewFeed
                    ref={this.slidingViewsRef}
                    topFillerHeight={topFillerHeight + dirsViewHeight}
                    initialData={initData}
                    viewGetter={this.viewGetter}
                    isServerSide={isServerSide}
                    widthListener={widthListener}
                    scrollListener={scrollListener}
                    isShown={!isHidden}
                    numItems={numViews}
                >
                    {/* <TopDirsView */}
                    {/*    orientations={orientations} */}
                    {/*    heightListener={this.updateDirsViewHeight} */}
                    {/*    offset={topFillerHeight} */}
                    {/* /> */}
                </SlidingContentViewFeed>
            </div>
        ) : (
            <></>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layout = state.notUndoable as LayoutState;
    const libState = state.undoable.present as LibraryBrowserState;
    return {
        isServerSide: layout.isServerSide,
        orientations: libState.libraryBrowserDataKey.orientations,
    };
};

const HomePage = connect(mapStateToProps)(HomePageComponent);

export default HomePage;

//
// export function convertParams(apiParams: HomePageSlidingView){
//     // const { orientations } = this.props;
//     const parts = apiParams.path.split(libraryPathSeperator);
//     const title = parts[parts.length - 1];
//     return {
//         dims: apiParams.dims,
//         numItems: apiParams.numItems,
//         key: title,
//         title: viewTitle(apiParams, orientations),
//         initialItems: apiParams.content.map(contentToDom),
//         getItems: this.createItemGetter(apiParams),
//     };
// };

export function convertInitialData(initialHomeData: {
    dataKey: unknown;
    dims: Dims;
    maxNum: number;
    homePageSlidingViews: Array<unknown>;
}) {
    const views = initialHomeData.homePageSlidingViews;
    return {
        content: views,
        dataKey: {
            isHomePage: true,
            orientations: initialHomeData.dataKey.orientations,
            startIndex: 0,
            numToTake: views.length,
        },
        numItems: initialHomeData.maxNum,
        numPerRow: 1,
        dynHeight: true,
    };
}

export const heightStream = new Observable<number>();

export function slidingViewCreator(contentItem: Record<string, unknown>): ReactElement {
    return (
        <SlidingContentView
            data={contentItem}
            numItems={contentItem.numItems}
            dims={contentItem.dims}
            initialItems={contentItem.content}
            title={viewTitle(contentItem)}
            key={contentItem.path}
        />
    );
}

export const HomeViewsDataFetcher: DataFetcher = (
    key: VGContentRequest,
    callback: (res: VGContentResponse) => void,
) => {
    sendRequest('home-views', key, (res) => {
        const views = res.homePageSlidingViews as Array<HomePageSlidingView>;
        callback({
            content: (views as unknown) as Array<Record<string, unknown>>,
            numItems: res.maxNum,
            dims: null,
            numPerRow: 1,
        });
    });
};
