import { Reducer } from 'redux';
import { removeElement } from 'common/functions/Utils/ArrayHelpers';
import { HasId, VideoItem } from 'common/types/AppTypes';

export interface ContentViewerState {
    videos: Array<VideoItem>;
}

export const initialContentViewerState: ContentViewerState = {
    videos: [] as Array<VideoItem>,
};

export const openVideoA = (video: HasId) => ({
    type: 'OPEN_VIDEO',
    payload: video,
});

export const setOpenedVideos = (videos: Array<HasId>) => ({
    type: 'SET_OPENED_VIDEOS',
    payload: videos,
});

export const closeVideoA = (video: HasId) => ({
    type: 'CLOSE_VIDEO',
    payload: video,
});

export const closeAllVidsA = () => ({
    type: 'CLOSE_ALL_VIDS',
    payload: undefined as unknown,
});

export type ContentViewerStateActions = ReturnType<
    typeof openVideoA | typeof setOpenedVideos | typeof closeVideoA | typeof closeAllVidsA
>;

export const ContentViewerActionHandlers: Record<string, Reducer> = {
    OPEN_VIDEO: (state: ContentViewerState, action: ContentViewerStateActions) => {
        const videoRec = JSON.parse(JSON.stringify(action.payload));
        return {
            ...state,
            videos: state.videos.concat(videoRec),
        };
    },
    SET_OPENED_VIDEOS: (state: ContentViewerState, action: ReturnType<typeof setOpenedVideos>) => {
        return {
            ...state,
            videos: action.payload ? action.payload : [],
        };
    },
    CLOSE_VIDEO: (state: ContentViewerState, action: ContentViewerStateActions) => {
        return {
            ...state,
            videos: removeElement(state.videos.slice(), action.payload),
        };
    },
    CLOSE_ALL_VIDS: (state: ContentViewerState, action: ContentViewerStateActions) => {
        return {
            ...state,
            videos: [],
        };
    },
};
