export class AppLinkResolver {
    private currentUrl: string;

    setRequestedUrl(url: string) {
        this.currentUrl = url;
    }

    getRequestUrl(): string {
        return this.currentUrl;
    }
}

export const AppLinkResolverInstance = new AppLinkResolver();
