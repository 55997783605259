import * as React from 'react';
import {ChangeEvent, Component} from 'react';
import './SeperatedNumericField.scss';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';
import DynamicInputField from "common/components/DynamicInputField/DynamicInputField";
import {areNans} from "common/components/utils/Helpers";

function addSpaceEveryThirdDigit(val: string) {
    const numChars = val.length;
    if (numChars < 4) {
        return val;
    }
    let numCharsAdded = 0;
    let res = '';
    for (let i = 0; i < numChars; i++) {
        res = val.charAt(numChars - 1 - i) + res;
        numCharsAdded++;
        if (numCharsAdded > 2 && numCharsAdded % 3 === 0 && numCharsAdded < numChars) {
            res = ` ${res}`;
        }
    }
    return res;
}

interface SeperatedNumericFieldProps {
    value: number;
    onChange: (val: number) => void;
    minLength?: number;
}

interface SeperatedNumericFieldState {
    value: string;
}

export default class SeperatedNumericField extends Component<
    SeperatedNumericFieldProps,
    SeperatedNumericFieldState
> {
    private inputCorrectionExecutor = new DebouncingExecutor();

    constructor(props: SeperatedNumericFieldProps) {
        super(props);
        const { value } = this.props;
        const val = value || '0';
        this.state = {
            value: addSpaceEveryThirdDigit(`${val}`),
        };
    }

    componentDidUpdate(prevProps: SeperatedNumericFieldProps) {
        const { value } = this.props;
        if (prevProps.value !== value) {
            const val = value || '0';
            this.setValue(`${val}`);
        }
    }

    setValue = (val: string) => {
        this.setState((prevState: SeperatedNumericFieldState) => ({
            ...prevState,
            value: addSpaceEveryThirdDigit(`${val}`),
        }));
    };

    onChange = (event: ChangeEvent<HTMLInputElement>) => {
        let val = event.target.value;
        const match = /^(\d| )*$/.test(val);
        if (match) {
            val = val.split(' ').join('');
            this.setValue(val);
            const { onChange } = this.props;
            this.inputCorrectionExecutor.bufferedExecute(() => {
                onChange(parseInt(val) || 0);
            }, 500);
        }
    };

    onClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const element = event.target as HTMLInputElement;
        const numChars = element.value.length;
        element.setSelectionRange(0, numChars);
    };

    render() {
        const { minLength } = this.props;
        const { value } = this.state;
        return (
            <DynamicInputField
                type="text"
                value={value}
                onChange={this.onChange}
                onClick={this.onClick}
                minLength={minLength}
            />
        );
    }
}
