import {FilterCriteria, Sort} from 'common/types/AppTypes';
import { LibDataKey } from 'adult/state/LibraryBrowserState/LibraryBrowserState';

export interface SavedFilterSort {
    sort: Sort;
    filters: Array<FilterCriteria<unknown>>;
}

const emptyFilterSort: SavedFilterSort = {
    sort: null,
    filters: [],
};

export default class AppliedFilterSortsCache {
    private mediaFilterSorts: SavedFilterSort;

    private relatedFilterSorts: SavedFilterSort;

    private pathFilterSorts: Record<string, SavedFilterSort> = {};

    saveFilterSorts(dataKey: LibDataKey, isMediaParent: boolean) {
        const isRelatedTo = dataKey.relatedTo && dataKey.relatedTo.length > 0;
        const isCurrentMediaParent =
            (dataKey.searchTerm && !dataKey.parentPath) || (dataKey.parentPath && isMediaParent);
        const filterSorts = {
            sort: isRelatedTo ? null : dataKey.sort,
            filters: dataKey.filters? dataKey.filters.slice() : [],
        };
        if (isRelatedTo) {
            this.mediaFilterSorts = filterSorts;
        } else if (isCurrentMediaParent) {
            this.mediaFilterSorts = filterSorts;
        } else {
            this.pathFilterSorts[dataKey.parentPath] = filterSorts;
        }
    }

    getRelatedFilterSorts(): SavedFilterSort {
        return this.getMediaFilterSorts();
        // return this.relatedFilterSorts || emptyFilterSort;
    }

    getMediaFilterSorts(): SavedFilterSort {
        return this.mediaFilterSorts || emptyFilterSort;
    }

    getFilterSorts(path: string) {
        return this.pathFilterSorts[path] || emptyFilterSort;
    }
}
