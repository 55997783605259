import { Dir, FilterCriteria } from 'common/types/AppTypes';

export function mergeFilters(
    filters: Array<FilterCriteria<unknown>>,
    newFilter: FilterCriteria<unknown>,
) {
    if (
        (newFilter.field.type === 'MULTI_VALUE_STRING' || newFilter.field.type === 'STRING') &&
        newFilter.field.name !== 'Site'
    ) {
        filters = filters.slice();
    } else {
        filters = filters.filter((item) => item.field.name !== newFilter.field.name);
    }
    filters.push(newFilter);
    return filters;
}

export function removeFilterFromArray(
    filters: Array<FilterCriteria<unknown>>,
    filterToRemove: FilterCriteria<unknown>,
) {
    filters = filters.filter(
        (item) => item.field.name !== filterToRemove.field.name || item.min !== filterToRemove.min,
    );
    return filters;
}

export function arrangeTopDirs(dirs: Array<Dir>) {
    // TODO: Arrange top dirs
}
