import * as React from 'react';
import './SmoothImage.scss';
import { Component } from 'react';
import 'common/styles/CommonStyles.scss';

interface SmoothImageProps {
    src: string;
    className?: string;
    alt: string;
    onImageLoaded?: () => void;
    background?:boolean;
}

interface SmoothImageState {
    isHidden?: boolean;
}

export default class SmoothImage extends Component<SmoothImageProps, SmoothImageState> {
    private loaded = false;

    constructor(props: SmoothImageProps) {
        super(props);
        this.state = {
            isHidden: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<SmoothImageProps>): void {
        const { src } = this.props;
        if (prevProps.src !== src) {
            this.loaded = false;
            this.setState({ isHidden: true });
        }
    }

    checkIfImageLoaded = (ref: HTMLImageElement) => {
        if (ref && ref.complete) {
            this.imageLoaded();
        }
    };

    imageLoaded = () => {
        this.loaded = true;
        const { onImageLoaded } = this.props;
        if (onImageLoaded) {
            onImageLoaded();
        }
        this.setState({ isHidden: false });
    };

    isLoaded = () => {
        return this.loaded;
    };

    render() {
        const { src, className, alt, background } = this.props;
        const { isHidden } = this.state;
        // const isHidden = false;
        const styleName = `${isHidden ? 'hidden' : ''} ${background ? 'background' : ''}`;
        return (
            <>
                <img
                    styleName={styleName}
                    ref={this.checkIfImageLoaded}
                    src={src}
                    onError={this.imageLoaded}
                    onLoad={this.imageLoaded}
                    className={className}
                    alt={alt}
                />
            </>
        );
    }
}
