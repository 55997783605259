import { AppLinkResolverInstance } from 'common/functions/AppLinkResolver/AppLinkResolver';

function isValidClient() {
    return typeof window !== 'undefined' && window.history;
}

function getUrlNoOrigin() {
    return window.location.pathname + window.location.search;
}

export class UrlController {
    private onBackAction = () => {};

    private onBackFromInfoAction = () => {};

    private onForwardAction = () => {};

    private justEntered = true;

    private stateCounter = 0;

    private compoundAction = false;

    private numSingleActions = 1;

    private numActionsAtId: Record<number, number> = {};

    private currentPage = 1;

    private isInfoPage: Record<number, boolean> = {};

    constructor() {
        if (isValidClient()) {
            window.history.pushState(null, '', null);
            window.addEventListener('popstate', (event) => {
                event.preventDefault();
                if (this.justEntered && event.state && event.state.id === 1) {
                    window.location.reload();
                } else if (!event.state || this.stateCounter > event.state.id) {
                    // console.log(this.stateCounter);
                    if (this.isInfoPage[this.stateCounter]) {
                        this.isInfoPage[this.stateCounter] = false;
                        this.stateCounter = event.state ? event.state.id : 0;
                        this.onBackFromInfoAction();
                    } else {
                        const numActions = this.numActionsAtId[this.stateCounter] || 1;
                        this.stateCounter = event.state ? event.state.id : 0;
                        // console.log(numActions)
                        this.compoundAction = true;
                        for (let i = 0; i < numActions; i++) {
                            this.onBackAction();
                        }
                        this.compoundAction = false;
                    }
                } else if (this.stateCounter < event.state.id) {
                    const numActions = this.numActionsAtId[event.state.id] || 1;
                    this.stateCounter = event.state.id;
                    this.compoundAction = true;
                    for (let i = 0; i < numActions; i++) {
                        this.onForwardAction();
                    }
                    this.compoundAction = false;
                }
            });
        }
    }

    updateUrl = (newUrl: string) => {
        if (isValidClient() && !this.compoundAction) {
            let urlNow = window.location.href;
            urlNow = urlNow.replace(/[&?]page=\d+/, '');
            const nextUrl = `${window.location.origin}${encodeURI(newUrl)}`;
            if (urlNow !== nextUrl) {
                this.stateCounter++;
                const data = { id: this.stateCounter };
                window.history.pushState(data, '', nextUrl);
                // console.log(window.history)
                this.numActionsAtId[this.stateCounter] = this.numSingleActions;
                this.numSingleActions = 1;
                this.justEntered = false;
            }
        }
    };

    updateUrlPageNum = (pageNum: number) => {
        if (isValidClient()) {
            if (pageNum !== this.currentPage) {
                this.currentPage = pageNum;
                const urlNow = this.getCurrentUrlWithPage(pageNum);
                window.history.replaceState({ id: this.stateCounter }, '', urlNow);
            }
        }
    };

    updateUrlInfoPage = (pageUrl: string) => {
        if (isValidClient()) {
            if (this.isInfoPage[this.stateCounter]) {
                window.history.replaceState({ id: this.stateCounter }, '', pageUrl);
            } else {
                this.stateCounter++;
                this.isInfoPage[this.stateCounter] = true;
                window.history.pushState({ id: this.stateCounter }, '', pageUrl);
                this.numActionsAtId[this.stateCounter] = this.numSingleActions;
                this.numSingleActions = 1;
                this.justEntered = false;
            }
        }
    };

    infoPageClosed = () => {
        if (this.isInfoPage[this.stateCounter]) {
            window.history.back();
        }
    };

    onBackFromInfo = (action: () => void) => {
        this.onBackFromInfoAction = action;
    };

    startCompoundAction = () => {
        this.compoundAction = true;
    };

    stopCompoundAction = (numSingleActions: number) => {
        this.numSingleActions = numSingleActions;
        this.compoundAction = false;
    };

    onBack = (action: () => void) => {
        this.onBackAction = action;
    };

    onForward = (action: () => void) => {
        this.onForwardAction = action;
    };

    getCurrentUrlWithPage = (pageNum: number): string => {
        let urlNow = isValidClient() ? getUrlNoOrigin() : AppLinkResolverInstance.getRequestUrl();
        urlNow = urlNow.replace(/[&?]page=\d+/, '');
        if (pageNum > 1) {
            if (urlNow.indexOf('?') >= 0) {
                urlNow = `${urlNow}&page=${pageNum}`;
            } else {
                urlNow = `${urlNow}?page=${pageNum}`;
            }
        }
        return urlNow;
    };
}

const UrlControllerInstance = new UrlController();

export default UrlControllerInstance;
