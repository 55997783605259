import * as React from 'react';
import './LibDataFilterRangeInput.scss';
import { Component } from 'react';
import Async from 'react-async';
import LoadingIndicator from 'common/components/LoadingIndicator/LoadingIndicator';
import FilterRangeInput from 'common/components/LibraryController/FilteringController/FilterRangeInput/FilterRangeInput';
import {
    addFilter,
    LibDataKey,
    LibraryBrowserState,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { DataItemField, FilterCriteria } from 'common/types/AppTypes';
import { sendRequest } from 'adult/components/ApiComs/ClientApiComs';
import { connect } from 'react-redux';
import { RootState } from 'adult/state/AdultappStateStore';

interface LibDataFilterRangeInputProps {
    selectedFilter: DataItemField;
    addFilter: (f: FilterCriteria<unknown>) => void;
    libDataKey: LibDataKey;
}

function extractFilterIfExists(
    filters: Array<FilterCriteria<unknown>>,
    filter: DataItemField,
): unknown {
    if (filters) {
        return filters.find((filterCandidate) => filterCandidate.field.name === filter.name);
    }
    return null;
}

const filterRangeCache: Record<string, unknown> = {};

const getMinMaxVals = async (
    { dataKey, filter }: { dataKey: LibDataKey; filter: DataItemField },
    s: unknown,
) => {
    const promise = new Promise((resolve, reject) => {
        const appliedFilter = extractFilterIfExists(dataKey.filters, filter);
        if (appliedFilter) {
            resolve(appliedFilter);
        } else {
            const request = {
                dataConfig: dataKey,
                filterAttribute: filter,
            };
            const requestKey = JSON.stringify(request);
            const cachedResp = filterRangeCache[requestKey];
            if (cachedResp) {
                resolve(cachedResp);
            } else {
                sendRequest('filter-ranges', request, (resp) => {
                    filterRangeCache[requestKey] = resp;
                    resolve(resp);
                });
            }
        }
    });
    return promise;
};

export class LibDataFilterRangeInputComponent extends Component<LibDataFilterRangeInputProps> {
    render() {
        const { selectedFilter, addFilter, libDataKey } = this.props;
        const asyncWatchVal = JSON.stringify({ libDataKey, selectedFilter });
        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Async
                promiseFn={getMinMaxVals}
                dataKey={libDataKey}
                filter={selectedFilter}
                watch={asyncWatchVal}
            >
                {({ data, error, isPending }: Record<string, unknown>) => {
                    if (isPending) {
                        return <LoadingIndicator />;
                    }
                    if (data) {
                        return (
                            <FilterRangeInput
                                min={data.min}
                                max={data.max}
                                filter={selectedFilter}
                                applyFilter={addFilter}
                            />
                        );
                    }
                    return <>Error!</>;
                }}
            </Async>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const undoable = state.undoable.present as LibraryBrowserState;
    return {
        libDataKey: undoable.libraryBrowserDataKey,
    };
};

const mapPropsToDispatch = {
    addFilter,
};

const LibDataFilterRangeInput = connect(
    mapStateToProps,
    mapPropsToDispatch,
)(LibDataFilterRangeInputComponent);

export default LibDataFilterRangeInput;
