import * as React from 'react';
import {Component, createRef} from 'react';
import './SlidingArrowButton.scss';
import {calculateImageIndexToShow} from 'common/components/MediaItemGallery/GalleryCalc';
import {connect} from 'react-redux';
import {RootState} from 'adult/state/AdultappStateStore';
import {LayoutState} from 'adult/state/LayoutState/LayoutState';

interface SlidingArrowButtonProps {
    left: boolean;
    mouseOverOvserver: (n: number) => void;
    isMobile?: boolean;
}

export class SlidingArrowButtonComponent extends Component<SlidingArrowButtonProps> {
    private containerRef = createRef<HTMLDivElement>();

    private lastPerc: number = null;

    private isMouseDown = false;

    private touchedUp = false;

    constructor(props: SlidingArrowButtonProps) {
        super(props);
        if (typeof window !== 'undefined') {
            window.addEventListener('touchend', this.touchUp);
        }
    }

    componentWillUnmount(): void {
        if (typeof window !== 'undefined') {
            window.removeEventListener('touchend', this.touchUp);
        }
    }

    mouseMoved = (event: React.MouseEvent<HTMLDivElement>) => {
        const { left, mouseOverOvserver, isMobile } = this.props;
        if(!isMobile) {
            let perc = calculateImageIndexToShow(event.clientX, this.containerRef.current, 100);
            if (left) {
                perc = 100 - perc;
            }
            this.lastPerc = perc;
            if (!this.isMouseDown) {
                mouseOverOvserver(perc);
            }
        }
    };

    mouseLeft = () => {
        const { mouseOverOvserver, isMobile } = this.props;
        if (!isMobile) {
            mouseOverOvserver(null);
        }
    };

    mouseDown = () => {
        const { mouseOverOvserver, isMobile } = this.props;
        if (!isMobile) {
            this.isMouseDown = true;
            mouseOverOvserver(150);
        }
    };

    mouseUp = () => {
        const { mouseOverOvserver, isMobile } = this.props;
        if (!isMobile) {
            this.isMouseDown = false;
            if (this.touchedUp) {
                this.touchedUp = false;
                this.lastPerc = null;
            }
            mouseOverOvserver(this.lastPerc);
        }
    };

    touchUp = () => {
        const { mouseOverOvserver, isMobile } = this.props;
        if (!isMobile) {
            this.touchedUp = true;
            mouseOverOvserver(null);
        }
    };

    clicked = () => {
        const { mouseOverOvserver, isMobile } = this.props;
        if(isMobile) {
            mouseOverOvserver(-1);
        }
    }

    touchStart = () => {
        const { mouseOverOvserver, isMobile } = this.props;
        if (!isMobile) {
            mouseOverOvserver(100);
        }
    };

    render() {
        const { left } = this.props;
        const topStyle = left ? 'rightBottomLeftTop' : 'rightTopLeftBottom';
        const bottomStyle = left ? 'rightTopLeftBottom' : 'rightBottomLeftTop';
        return (
            <div
                styleName="container"
                onMouseMove={this.mouseMoved}
                onMouseLeave={this.mouseLeft}
                onMouseDown={this.mouseDown}
                onMouseUp={this.mouseUp}
                onTouchEnd={this.touchUp}
                onTouchStart={this.touchStart}
                onClick={this.clicked}
                ref={this.containerRef}
            >
                <div styleName={topStyle} />
                <div styleName={bottomStyle} />
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layoutState = state.notUndoable as LayoutState;
    return {
        isMobile: layoutState.isMobile,
    };
};

const SlidingArrowButton = connect(mapStateToProps)(SlidingArrowButtonComponent);

export default SlidingArrowButton;
