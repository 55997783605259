type ItemsAtIndicies = Record<number, unknown>;

export type VirtualGridDataCache = Record<string, ItemsAtIndicies>;

export function addItemsToCache(
    cache: VirtualGridDataCache,
    libKey: string,
    content: Array<unknown>,
    startIndex: number,
): void {
    // console.log(cache);
    // console.log(libKey);
    // console.log(content);
    // console.log(startIndex)
    let itemsAtIndicies = cache[libKey];
    if (!itemsAtIndicies) {
        itemsAtIndicies = {};
        cache[libKey] = itemsAtIndicies;
    }
    content.forEach((item, i) => {
        itemsAtIndicies[i + startIndex] = item;
    });
}

export function getItemsOutOfCache(
    cache: VirtualGridDataCache,
    libKey: string,
    startIndex: number,
    numToTake: number,
): Array<unknown> {
    const itemsAtIndicies = cache[libKey];
    const finalContent = [];
    for (let i = startIndex; i < startIndex + numToTake; i++) {
        if (itemsAtIndicies[i]) {
            finalContent[i - startIndex] = itemsAtIndicies[i];
        }
    }
    return finalContent;
}
function getQuadrantsMissing(
    itemsAtIndicies: ItemsAtIndicies,
    startIndex: number,
    lastIndex: number,
    numPerQuadrant: number,
): Array<number> {
    const quadrants = new Set<number>();
    for (let i = startIndex; i < lastIndex + 1; i++) {
        if (!itemsAtIndicies[i]) {
            quadrants.add(Math.floor(i / numPerQuadrant));
        }
    }
    return Array.from(quadrants);
}

export function getIndiciesToRequest(
    cache: VirtualGridDataCache,
    numPerQuadrant: number,
    libKey: string,
    startIndex: number,
    numToTake: number,
) {
    numPerQuadrant = numPerQuadrant < numToTake ? numToTake * 2 : numPerQuadrant;
    const lastIndex = startIndex + numToTake - 1;
    const quadrant1 = Math.floor(startIndex / numPerQuadrant);
    const quadrant2 = Math.floor(lastIndex / numPerQuadrant);

    const itemsAtIndicies = cache[libKey];

    let startIndexToFetch = null;
    let numToTakeToFetch = null;
    if (!itemsAtIndicies && quadrant1 === quadrant2) {
        startIndexToFetch = quadrant1 * numPerQuadrant;
        numToTakeToFetch = numPerQuadrant;
    } else if (!itemsAtIndicies && quadrant1 !== quadrant2) {
        startIndexToFetch = quadrant1 * numPerQuadrant;
        numToTakeToFetch = numPerQuadrant * 2;
    } else {
        const quadrantsToFetchArray = getQuadrantsMissing(
            itemsAtIndicies,
            startIndex,
            lastIndex,
            numPerQuadrant,
        );
        if (quadrantsToFetchArray.length === 2) {
            startIndexToFetch = Math.min.apply(null, quadrantsToFetchArray) * numPerQuadrant;
            numToTakeToFetch = numPerQuadrant * 2;
        } else if (quadrantsToFetchArray.length === 1) {
            startIndexToFetch = Math.min.apply(null, quadrantsToFetchArray) * numPerQuadrant;
            numToTakeToFetch = numPerQuadrant;
        }
    }
    return { startIndexToFetch, numToTakeToFetch };
}

export function isContentInCache(
    cache: VirtualGridDataCache,
    maxNumItems: number,
    libKey: string,
    startIndex: number,
    numToTake: number,
) {
    const itemsAtIndicies = cache[libKey];
    if (!itemsAtIndicies) {
        return false;
    }
    let lastIndex = startIndex + numToTake - 1;
    if (maxNumItems < lastIndex + 1) {
        lastIndex = maxNumItems - 1;
    }
    for (let i = startIndex; i < lastIndex + 1; i++) {
        if (!itemsAtIndicies[i]) {
            return false;
        }
    }
    return true;
}
