export function removeElement<T>(array: Array<T>, element: T): Array<T> {
    const index = array.indexOf(element, 0);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
}

export function flatMap<T>(array: Array<T>, func: (e: T) => Array<unknown>) {
    return Array.prototype.concat.apply([], array.map(func));
}

export function numRange(start: number, end: number) {
    const list = [];
    for (let i = start; i <= end; i++) {
        list.push(i);
    }
    return list;
}

export function takeNums(start: number, numToTake: number) {
    const list = [];
    for (let i = start; i < start + numToTake; i++) {
        list.push(i);
    }
    return list;
}
