import { urlToHeadingMap } from 'adult/resources/legalDisclaimers/LegalDisclaimerTexts';
import { PageDetails } from 'adult/state/StateToPageInfo';

export function pageInfoFromTitle(title: string): PageDetails {
    return {
        title: `${title} | YouLibs.com`,
        description: `${title} page at youlibs.com. `,
    };
}

export function getPageInfo(urlName: string): PageDetails {
    const title = urlToHeadingMap[urlName];
    return pageInfoFromTitle(title);
}
