import * as React from 'react';
import { Component } from 'react';
import App from 'common/components/App/App';
import TopLayout from 'adult/components/TopLayout/TopLayout';
import './AdultApp.scss';
import VirtualGridDataGetter, {
    InitialGridData,
} from 'common/components/VirtualGrid/VirtualGridDataGetter/VirtualGridDataGetter';
import { Provider } from 'react-redux';
import pgoStore from 'adult/state/AdultappStateStore';
import {
    LibDataKey,
    LibraryBrowserState,
    setFilterSortsConfig,
    setLibDataKey,
    setTopDirs,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { contentToDom } from 'adult/components/PgoMediaItem/PgoMediaItem';
import UrlControllerInstance from 'common/client/UrlController';
import { convertStateToUrl } from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import VideoPlayer from 'common/components/VideoPlayer/VideoPlayer';
import {
    ContentRequest,
    ContentResponse,
    HomePageSlidingView,
} from 'adult/components/ApiComs/ApiTypes';
import { clone } from 'common/components/utils/Helpers';
import {
    ContentViewerState,
    setOpenedVideos,
} from 'adult/state/ContentViewerState/ContentViewerState';
import { HasId } from 'common/types/AppTypes';
import { DataFetcher } from 'common/components/VirtualGrid/VirtualGridDataGetterCache/VirtualGridDataGetterCache';
import { sendGetRequest, sendRequest } from 'adult/components/ApiComs/ClientApiComs';
import {
    LayoutState,
    setIsMobile,
    setIsSinglePlayer,
    setNumVidsTotal,
    setPlayerFullScreen,
} from 'adult/state/LayoutState/LayoutState';
import LibraryBrowser from 'common/components/LibraryBrowser/LibraryBrowser';
import AppearingPreviewWindow from 'common/components/AppearingPreviewWindow/AppearingPreviewWindow';
import { ActionCreators } from 'redux-undo';
import { currentPage } from 'common/components/PagingController/PagingCalculator';
import { numPerPage } from 'adult/confg/AppConfig';
import { updatePageDetailsFromState } from 'common/client/PageDetailsController';
import MainTopBar from 'adult/components/MainTopBar/MainTopBar';
import { CSSPlugin } from 'gsap';
import fscreen from 'fscreen';
import {
    convertInitialData,
    heightStream,
    HomeViewsDataFetcher,
    slidingViewCreator,
} from 'adult/components/HomePage/HomePage';

const plugins = [CSSPlugin];

interface InitialStateContent extends InitialGridData<ContentRequest>, ContentResponse {}

interface AdultAppProps {
    initialState: {
        content?: InitialStateContent;
        topDirs: Array<undefined>;
        openedVideos: Array<HasId>;
        gridPage: number;
        homePageSlidingViews?: Array<HomePageSlidingView>;
        numVids?: number;
    };
    onLoad: () => void;
}

function extractLibDataKey(req: ContentRequest): LibDataKey {
    const key = clone(req);
    delete key.startIndex;
    delete key.numToTake;
    delete key.openedVideos;
    return key as LibDataKey;
}

const libraryContentFetcher: DataFetcher = (key, callback) => {
    sendRequest('content-new', key, (res) => {
        callback(res);
    });
};

const libConfigs = [
    {
        type: 'home',
        dataFetcher: HomeViewsDataFetcher,
        contentTransformer: slidingViewCreator,
        heightStream,
    },
    { type: 'lib', dataFetcher: libraryContentFetcher, contentTransformer: contentToDom },
];

export default class AdultApp extends Component<AdultAppProps> {
    private libDataGetter: VirtualGridDataGetter<ContentRequest, ContentResponse>;

    private clicks = 0;

    constructor(props: AdultAppProps) {
        super(props);
        const initState = props.initialState;
        // console.log(convertInitialData(initState))
        const initialData = initState.content || convertInitialData(initState);
        this.libDataGetter = new VirtualGridDataGetter<ContentRequest, ContentResponse>(
            initialData,
            libConfigs,
        );
        this.libDataGetter.setContentResponseListener((contentResponse) => {
            pgoStore.dispatch(setFilterSortsConfig(contentResponse.filterSortsConfig));
        });
        pgoStore.dispatch(setFilterSortsConfig(initialData.filterSortsConfig));
        delete initialData.dataKey.isMobile;
        const libDataKey = extractLibDataKey(initialData.dataKey);
        pgoStore.dispatch(setLibDataKey(libDataKey, initialData.isMediaParent));
        pgoStore.dispatch(setOpenedVideos(initState.openedVideos));
        pgoStore.dispatch(setTopDirs(initState.topDirs));
        UrlControllerInstance.onBack(() => {
            // console.log("back press")
            if (pgoStore.getState().notUndoable.isPlayerFullScreen && !fscreen.fullscreenEnabled) {
                pgoStore.dispatch(setPlayerFullScreen(false));
            } else {
                // console.log("undoing")
                pgoStore.dispatch(ActionCreators.undo());
            }
        });
        UrlControllerInstance.onForward(() => {
            pgoStore.dispatch(ActionCreators.redo());
        });

        pgoStore.dispatch(setIsSinglePlayer(true));
        pgoStore.dispatch(setIsMobile(initState.device === 'phone'));
        pgoStore.dispatch(setNumVidsTotal(props.initialState.numVids));
    }

    componentDidMount(): void {
        pgoStore.subscribe(() => {
            const state = pgoStore.getState().undoable.present as LibraryBrowserState;
            const noneUndoState = pgoStore.getState().notUndoable as LibraryBrowserState;
            const videosState = pgoStore.getState().undoable.present as ContentViewerState;
            const layoutState = pgoStore.getState().notUndoable as LayoutState;
            const dataKey = state.libraryBrowserDataKey;
            const { libBrowserConfig } = noneUndoState;
            this.libDataGetter.setDataKey(dataKey as ContentRequest);
            const newUrl = convertStateToUrl(
                dataKey,
                libBrowserConfig.defaultSort,
                videosState.videos,
            );
            UrlControllerInstance.updateUrl(newUrl);
            UrlControllerInstance.updateUrlPageNum(
                currentPage(layoutState.gridStartIndex, numPerPage),
            );
            updatePageDetailsFromState();
            // setPageDetails(stateToPageDetails(pgoStore.getState().undoable.present));
        });
        // window.addEventListener('click', () => {
        //     this.clicks++;
        //     if (this.clicks === 3) {
        //         sendGetRequest('clickproc', (res) => {
        //             if (res.should) {
        //                 window.open('/external');
        //             }
        //         });
        //     }
        // });
    }

    render() {
        const { initialState, onLoad } = this.props;
        return (
            <>
                <Provider store={pgoStore}>
                    <App>
                        <TopLayout>
                            <MainTopBar />
                            <VideoPlayer />
                            <LibraryBrowser
                                initialHomePageNumItems={initialState.maxNum}
                                initialHomePageData={initialState.homePageSlidingViews}
                                dataGetter={this.libDataGetter}
                                initialStartIndex={(initialState.gridPage - 1) * numPerPage}
                            />
                        </TopLayout>
                        <AppearingPreviewWindow />
                        {/* <Subliminal onLoad={onLoad} /> */}
                        {/* <AdDisplayAbsolute/> */}
                    </App>
                </Provider>
            </>
        );
    }
}
