function toUrlString(heading) {
    return heading
        .toLowerCase()
        .replace(/[^a-z\s]/g, '')
        .trim()
        .split(/\s/)
        .join('-');
}

export const LegalDisclaimerTexts = {
    "F.A.Q": "<h2>Frequently Asked Questions</h2>\n" +
    "<h3>What is this?</h3>\n" +
    "<p>This is a demo of a Media Library Management Web app that I made</p>",
    "Contacts": "<h2>Contact us:</h2>\n" +
    "        <h3>Not needed</h3>\n",
    "Terms Of Use": "<b>Last modified date:</b> March 26th, 2022" +
    "<h2>Terms of Use</h2>\n" +
    "        <p>This is a demo and isn't supposed to be used for anything else. Use at your own risk.</p>",
    "Privacy Policy": "<b>Last modified date:</b> March 26th, 2022" +
    "<h2>Privacy Policy</h2>\n" +
    " <h3>Everything is completely private, no data will be saved or used</h3>\n",
    "DMCA": "<h2>DMCA Notice & Takedown Policy and Procedures</h2>\n" +
    "        <p>This is just a demo that will not  be getting any traffic. All media is hosted by Youtube, any DMCA issues should be raised with Youtube</p>"

};

export const urlToHeadingMap = Object.keys(LegalDisclaimerTexts).reduce((map, heading) => {
    map[toUrlString(heading)] = heading;
    return map;
}, {});
