import * as React from 'react';
import './DurationInputField.scss';
import { Component, ChangeEvent } from 'react';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';
import DynamicInputField from "common/components/DynamicInputField/DynamicInputField";

function fillWithMissingZeros(val: string): string {
    const zerosNeeded = 2 - val.length;
    if (zerosNeeded > 0) {
        val = '0'.repeat(zerosNeeded) + val;
    }
    return val;
}

export function secondsToTimeString(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.round(totalSeconds % 60);
    return `${fillWithMissingZeros(`${hours}`)}:${fillWithMissingZeros(
        `${minutes}`,
    )}:${fillWithMissingZeros(`${seconds}`)}`;
}

function myParse(digits: string) {
    return digits.length === 0 ? 0 : parseInt(digits);
}
function timeStringToSeconds(timeString: string) {
    const vals = timeString.split(':');
    return myParse(vals[0]) * 3600 + myParse(vals[1]) * 60 + myParse(vals[2]);
}

interface DurationInputFieldProps {
    value: number;
    onChange: (val: number) => void;
}

interface DurationInputFieldState {
    value: string;
}

export default class DurationInputField extends Component<
    DurationInputFieldProps,
    DurationInputFieldState
> {
    private inputCorrectionExecutor = new DebouncingExecutor();

    constructor(props: DurationInputFieldProps) {
        super(props);
        let val = 0;
        if (props.value) {
            val = props.value;
        }
        this.state = {
            value: secondsToTimeString(val),
        };
    }

    componentDidUpdate(prevProps: DurationInputFieldProps) {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.setState({
                value: secondsToTimeString(value),
            });
        }
    }

    inputReceived = (event: ChangeEvent<HTMLInputElement>) => {
        const match = /^\d*:(\d?|[0-5]?\d):(\d?|[0-5]?\d)$/.test(event.target.value);
        if (match) {
            this.setState({
                value: event.target.value,
            });
        }
        this.inputCorrectionExecutor.bufferedExecute(() => {
            const { value } = this.state;
            const { onChange } = this.props;
            const vals = value.split(':');
            for (let i = 0; i < 3; i++) {
                vals[i] = fillWithMissingZeros(vals[i]);
            }
            this.setState({
                value: vals.join(':'),
            });
            onChange(timeStringToSeconds(value));
        }, 500);
    };

    clicked = (event: React.MouseEvent<HTMLInputElement>) => {
        const element = event.target as HTMLInputElement;
        const clickedCarretIndex = element.selectionStart;
        const numChars = element.value.length;
        const numFromEnd = numChars - clickedCarretIndex;
        element.setSelectionRange(0, 0);
        if (numFromEnd < 3) {
            element.setSelectionRange(numChars - 2, numChars);
        } else if (numFromEnd < 6) {
            element.setSelectionRange(numChars - 5, numChars - 3);
        } else {
            element.setSelectionRange(0, numChars - 6);
        }
    };

    render() {
        const { value } = this.state;
        return (
            <DynamicInputField
                type="text"
                value={value}
                onChange={this.inputReceived}
                onClick={this.clicked}
            />
        );
    }
}
