export function sendRequest(
    requestName: string,
    data: unknown,
    callback: (content: unknown) => void,
) {
    const req = new XMLHttpRequest();
    req.open('POST', `${window.location.protocol}//${window.location.host}/${requestName}`);
    req.setRequestHeader('Content-type', 'application/json');
    req.onreadystatechange = () => {
        if (req.readyState === 4 && req.status === 200) {
            const json = req.responseText ? JSON.parse(req.responseText) : {};
            callback(json);
        }
    };
    const body = JSON.stringify(data);
    req.send(body);
}

export function sendGetRequest(requestName: string, callback: (content: unknown) => void) {
    const req = new XMLHttpRequest();
    req.open('GET', `${window.location.protocol}//${window.location.host}/${requestName}`);
    req.setRequestHeader('Content-type', 'application/json');
    req.onreadystatechange = () => {
        if (req.readyState === 4 && req.status === 200) {
            const json = JSON.parse(req.responseText);
            callback(json);
        }
    };
    req.send();
}

export function debugMessage(data: string) {
    const req = new XMLHttpRequest();
    req.open('POST', `${window.location.protocol}//${window.location.host}/debug`);
    req.setRequestHeader('Content-type', 'application/json');
    req.send(JSON.stringify({ data }));
}

// export function sendRequest(
//     requestName: string,
//     data: unknown,
//     callback: (content: Record<string, unknown>) => void,
// ) {
//     // const url = `http://localhost:8443/${requestName}`;
//     const url = `${window.location.protocol}//${window.location.host}/${requestName}`;
//     fetch(url, {
//         method: 'POST',
//         headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//     })
//         .then((res) => {
//             return res.json();
//         })
//         .then((resObj) => {
//             callback(resObj);
//         });
// }
