function zeroPreAdd(date: number) {
    return `0${date}`.slice(-2);
}
function dateMinusDays(days: number) {
    const date = new Date();
    const pastDate = date.getDate() - days;
    date.setDate(pastDate);
    return date;
}

export function formatDate(date: Date) {
    return `${date.getFullYear()}-${zeroPreAdd(date.getMonth() + 1)}-${zeroPreAdd(date.getDate())}`;
}

export function getTodaysDate() {
    return formatDate(new Date());
}

export function getWeekAgo() {
    return formatDate(dateMinusDays(7));
}

export function getMonthAgo() {
    return formatDate(dateMinusDays(31));
}

export function getYearAgo() {
    let s = formatDate(dateMinusDays(365));
    return s;
}

export function yearRange(yearsAgoStart: number, numYears: number, notMobile: boolean) {
    const yearNow = new Date().getFullYear();
    const yearStart = yearNow - yearsAgoStart;
    const yearFinish = yearStart + numYears - 1;
    return {
        label: yearStart + (numYears > 1 ? `-${yearFinish}` : ''),
        values: [`${yearStart}-01-01`, `${yearFinish}-12-31`],
        notMobile,
    };
}
