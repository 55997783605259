import * as React from 'react';
import { Component } from 'react';
import './PgoMediaPlayer.scss';
import { PgoMediaItemData } from 'adult/components/PgoMediaItem/PgoMediaItem';
import { isSfw, sfwVids } from 'adult/confg/AppConfig';
import { connect } from 'react-redux';
import CloseButton from 'common/components/CloseButton/CloseButton';
import { closeVideo } from 'adult/state/ComposedActions';
import PhMobileEmbedOverlay from 'adult/components/PgoMediaPlayer/PhMobileEmbedOverlay/PhMobileEmbedOverlay';
import 'common/styles/CommonStyles.scss';
import AppIconButton from 'common/components/AppIconButton/AppIconButton';
import EpMobileOverlay from 'adult/components/PgoMediaPlayer/EpMobileEmbedOverlay/EpMobileOverlay';
import XvMobileEmbedOverlay from "adult/components/PgoMediaPlayer/XvMobileEmbedOverlay/XvMobileEmbedOverlay";
import EmbedOverlay from "adult/components/PgoMediaPlayer/EmbedOverlay/EmbedOverlay";

interface PgoMediaPlayerProps {
    mediaItem: PgoMediaItemData;
    closeVideo: (item: PgoMediaItemData) => void;
    style?: unknown;
    isHidden?: boolean;
    // onShowInfo?: () => void;
    // onHideInfo?: () => void;
    // onInfoShow?: (toShow: boolean) => void;
}

interface PgoMediaPlayerState {
    isBeingClosed: boolean;
}

function getPlayerOverlay(site: string) {
    switch (site) {
        case 'Eporner':
            break;
        case 'xVideos':
            break;
        case 'Pornhub':
        default:
    }
}

export class PgoMediaPlayerComponent extends Component<PgoMediaPlayerProps, PgoMediaPlayerState> {
    // private isInfoShown: boolean = false;
    // private isInfoButtonToggled: boolean = false;

    constructor(props: PgoMediaPlayerProps) {
        super(props);
        this.state = {
            isBeingClosed: false,
        };
    }

    closeVid = () => {
        const { closeVideo, mediaItem } = this.props;
        this.setState({ isBeingClosed: true });
        setTimeout(() => {
            closeVideo(mediaItem);
        }, 200);
    };

    // infoButtonClick = () => {
    //     const { onInfoShow } = this.props;
    //     this.isInfoButtonToggled = !this.isInfoButtonToggled;
    //     // onInfoShow(this.isInfoShown);
    // };
    //
    // infoButtonEnter = () => {
    //     const { onInfoShow } = this.props;
    //     if(!this.isInfoButtonToggled){
    //         this.isInfoShown = true;
    //         onInfoShow(this.isInfoShown);
    //     }
    // };
    //
    // infoButtonLeave = () => {
    //     // const { onInfoShow } = this.props;
    //     // if (!this.isInfoButtonToggled) {
    //     //     this.isInfoShown = false;
    //     //     onInfoShow(this.isInfoShown);
    //     // }
    // };

    getPlayerOverlay = (site: string) => {
        switch (site) {
            case 'Eporner':
                return <EpMobileOverlay onUpSwipe={this.closeVid} />;
                break;
            case 'xVideos':
                return <XvMobileEmbedOverlay onUpSwipe={this.closeVid} />;
                break;
            case 'Pornhub':
            default:
                return <PhMobileEmbedOverlay onUpSwipe={this.closeVid} />;
        }
    };

    render() {
        const { mediaItem, style, isHidden } = this.props;
        const { isBeingClosed } = this.state;
        const url = mediaItem.media;
        const styleName = `container${isHidden ? ' srvrHidden' : ''}`;
        // const overlay = this.getPlayerOverlay(mediaItem.Site);
        // console.log(this.props)
        return (
            <div styleName={styleName} {...{ style }}>
                <EmbedOverlay onUpSwipe={this.closeVid} site={mediaItem.Site} />
                <iframe
                    styleName={isBeingClosed ? ' vidBeingClosed' : ''}
                    title={mediaItem.Title}
                    src={isSfw ? sfwVids[0] : url}
                    allowFullScreen
                />
                <CloseButton onClick={this.closeVid} />
                {/* <AppIconButton */}
                {/*    iconName="information" */}
                {/*    text="info" */}
                {/*    onClick={this.infoButtonClick} */}
                {/*    onMouseEnter={this.infoButtonEnter} */}
                {/*    onMouseLeave={this.infoButtonLeave} */}
                {/* /> */}
            </div>
        );
    }
}

const mapDispatchToProps = {
    closeVideo,
};

const PgoMediaPlayer = connect(null, mapDispatchToProps, null, { forwardRef: true })(
    PgoMediaPlayerComponent,
);

export default PgoMediaPlayer;
