import {Reducer} from 'redux';
import {DataItemField, Dir, FilterCriteria, FilterSortsConfig, Sort} from 'common/types/AppTypes';
import AppliedFilterSortsCache from 'common/state/AppliedFilterSortsCache/AppliedFilterSortsCache';
import {mergeFilters, removeFilterFromArray,} from 'adult/state/LibraryBrowserState/LibBrowserStateHelpers';
import {clone} from 'common/components/utils/Helpers';
import {orientsSortingArray} from "adult/components/OrientationsController/AvailableOrientations";

const appliedFilterSortsCache = new AppliedFilterSortsCache();
let isCurrentMediaParent = false;

let filterSortsConfigsBeforeRelated: LibBrowserConfig = null;

export const homePageState: LibDataKey = {
    parentPath: null,
    orientations: ['s'],
    relatedTo: null,
    filters: [],
    sort: null,
    searchTerm: null,
    isHomePage: true,
};

export const initialLibraryBrowserState: LibraryBrowserState = {
    libraryBrowserDataKey: homePageState,
    libBrowserConfig: {
        topDirs: [],
        availableFilters: [],
        availableSorts: [],
        defaultSort: null,
    },
    previousNoneRelatedDataKey: null,
};

export interface LibDataKey {
    parentPath: string;
    searchTerm: string;
    relatedTo: Array<string>;
    sort: Sort;
    filters: Array<FilterCriteria<unknown>>;
    orientations: Array<string>;
    isHomePage: boolean;
}

export interface LibBrowserConfig {
    topDirs: Array<Dir>;
    defaultSort: Sort;
    availableSorts: Array<DataItemField>;
    availableFilters: Array<DataItemField>;
}

export interface LibraryBrowserState {
    libraryBrowserDataKey: LibDataKey;
    libBrowserConfig: LibBrowserConfig;
    previousNoneRelatedDataKey: LibDataKey;
}

export const submitSearch = (search: string) => ({
    type: 'SUBMIT_SEARCH',
    payload: search,
});

export const setLibDataKey = (libDataKey: LibDataKey, isMediaParent: boolean) => ({
    type: 'SET_LIB_DATA_KEY',
    payload: { libDataKey, isMediaParent },
});

export const setLibraryPath = (path: Dir) => ({
    type: 'SET_LIBRARY_PATH',
    payload: path,
    hasLibPathChanged: false,
});

export const setTopDirs = (topDirs: Array<Dir>) => ({
    type: 'SET_TOP_DIRS',
    payload: topDirs,
});

export const setFilterSortsConfig = (filterSorts: FilterSortsConfig) => ({
    type: 'SET_FILTER_SORTS_CONFIG',
    payload: filterSorts,
});

export const setSort = (sort: Sort) => ({
    type: 'SET_SORT',
    payload: sort,
});

export const addFilter = (filterCriteria: FilterCriteria<unknown>) => ({
    type: 'ADD_FILTER',
    payload: filterCriteria,
});

export const removeFilter = (filterCriteria: FilterCriteria<unknown>) => ({
    type: 'REMOVE_FILTER',
    payload: filterCriteria,
});

export const clearAllFilters = () => ({
    type: 'CLEAR_ALL_FILTERS',
    payload: null as unknown,
});

export const setOrientations = (orientations: Array<string>) => ({
    type: 'SET_ORIENTATIONS',
    payload: orientations,
});

export const showRecommendations = (recommendations: Array<string>) => ({
    type: 'SHOW_RECOMMENDATIONS',
    payload: recommendations,
});

export const returnPreRelatedState = () => ({
    type: 'RETURN_PRE_RELATED_STATE',
    payload: null as unknown,
});

export const openHomePage = () => ({
    type: 'OPEN_HOME_PAGE',
    payload: null as unknown,
});

export type LibraryBrowserActions = ReturnType<
    | typeof submitSearch
    | typeof setLibDataKey
    | typeof setLibraryPath
    | typeof setTopDirs
    | typeof setFilterSortsConfig
    | typeof setSort
    | typeof addFilter
    | typeof removeFilter
    | typeof clearAllFilters
    | typeof setOrientations
    | typeof showRecommendations
    | typeof openHomePage
>;

export const LibraryBrowserStateActionHandlers: Record<string, Reducer> = {
    SUBMIT_SEARCH: (state: LibraryBrowserState, action: LibraryBrowserActions) => {
        appliedFilterSortsCache.saveFilterSorts(state.libraryBrowserDataKey, isCurrentMediaParent);
        isCurrentMediaParent = true;
        const filterSortsToApply = appliedFilterSortsCache.getMediaFilterSorts();
        return {
            ...state,
            libraryBrowserDataKey: {
                ...state.libraryBrowserDataKey,
                searchTerm: action.payload,
                parentPath: null,
                relatedTo: null,
                sort: filterSortsToApply.sort,
                filters: filterSortsToApply.filters,
                isHomePage: false,
            },
        };
    },
    SET_LIB_DATA_KEY: (state: LibraryBrowserState, action: ReturnType<typeof setLibDataKey>) => {
        isCurrentMediaParent = action.payload.isMediaParent;
        return {
            ...state,
            libraryBrowserDataKey: action.payload.libDataKey,
        };
    },
    SET_LIBRARY_PATH: (state: LibraryBrowserState, action: ReturnType<typeof setLibraryPath>) => {
        appliedFilterSortsCache.saveFilterSorts(state.libraryBrowserDataKey, isCurrentMediaParent);
        const dir = action.payload as Dir;
        isCurrentMediaParent = dir.isMediaParent;
        const filterSortsToApply = dir.isMediaParent
            ? appliedFilterSortsCache.getMediaFilterSorts()
            : appliedFilterSortsCache.getFilterSorts(dir.path);
        action.hasLibPathChanged = dir.path !== state.libraryBrowserDataKey.parentPath;
        return {
            ...state,
            libraryBrowserDataKey: {
                ...state.libraryBrowserDataKey,
                parentPath: dir.path,
                filters: filterSortsToApply.filters,
                sort: filterSortsToApply.sort,
                relatedTo: null,
                searchTerm: null,
                isHomePage: false,
            },
        };
    },
    SET_SORT: (state: LibraryBrowserState, action: LibraryBrowserActions) => {
        return {
            ...state,
            libraryBrowserDataKey: {
                ...state.libraryBrowserDataKey,
                sort: action.payload,
            },
        };
    },
    ADD_FILTER: (state: LibraryBrowserState, action: LibraryBrowserActions) => {
        return {
            ...state,
            libraryBrowserDataKey: {
                ...state.libraryBrowserDataKey,
                filters: mergeFilters(
                    state.libraryBrowserDataKey.filters || [],
                    action.payload as FilterCriteria<unknown>,
                ),
            },
        };
    },
    REMOVE_FILTER: (state: LibraryBrowserState, action: LibraryBrowserActions) => {
        return {
            ...state,
            libraryBrowserDataKey: {
                ...state.libraryBrowserDataKey,
                filters: removeFilterFromArray(
                    state.libraryBrowserDataKey.filters || [],
                    action.payload as FilterCriteria<unknown>,
                ),
            },
        };
    },
    CLEAR_ALL_FILTERS: (state: LibraryBrowserState, action: LibraryBrowserActions) => {
        return {
            ...state,
            libraryBrowserDataKey: {
                ...state.libraryBrowserDataKey,
                filters: [],
            },
        };
    },
    SET_ORIENTATIONS: (state: LibraryBrowserState, action: ReturnType<typeof setOrientations>) => {
        action.payload.sort(function (a, b) {
            return orientsSortingArray.indexOf(a) - orientsSortingArray.indexOf(b);
        });
        return {
            ...state,
            libraryBrowserDataKey: {
                ...state.libraryBrowserDataKey,
                orientations: action.payload,
            },
        };
    },
    SHOW_RECOMMENDATIONS: (
        state: LibraryBrowserState,
        action: ReturnType<typeof showRecommendations>,
    ) => {
        appliedFilterSortsCache.saveFilterSorts(state.libraryBrowserDataKey, isCurrentMediaParent);
        isCurrentMediaParent = true;
        const previousNoneRelatedDataKey =
            state.libraryBrowserDataKey.relatedTo === null
                ? clone(state.libraryBrowserDataKey)
                : state.previousNoneRelatedDataKey;
        const filterSortsToApply = appliedFilterSortsCache.getRelatedFilterSorts();
        return {
            ...state,
            libraryBrowserDataKey: {
                ...state.libraryBrowserDataKey,
                searchTerm: null,
                parentPath: null,
                relatedTo: action.payload,
                sort: null,
                filters: filterSortsToApply.filters,
                isHomePage: false,
            },
            previousNoneRelatedDataKey,
        };
    },
    RETURN_PRE_RELATED_STATE: (
        state: LibraryBrowserState,
        action: ReturnType<typeof returnPreRelatedState>,
    ) => {
        appliedFilterSortsCache.saveFilterSorts(state.libraryBrowserDataKey, isCurrentMediaParent);
        return {
            ...state,
            libraryBrowserDataKey: state.previousNoneRelatedDataKey
                ? state.previousNoneRelatedDataKey
                : homePageState,
            previousNoneRelatedDataKey: null,
        };
    },
    OPEN_HOME_PAGE: (state: LibraryBrowserState, action: ReturnType<typeof openHomePage>) => {
        appliedFilterSortsCache.saveFilterSorts(state.libraryBrowserDataKey, isCurrentMediaParent);
        isCurrentMediaParent = false;
        return {
            ...state,
            libraryBrowserDataKey: {
                // ...state.libraryBrowserDataKey,
                // searchTerm: null,
                // parentPath: null,
                // relatedTo: null,
                // sort: null,
                // filters: [],
                isHomePage: true,
                orientations: state.libraryBrowserDataKey.orientations,
            },
        };
    },
};

export const LibBrowserNoneUndoableActionHandlers: Record<string, Reducer> = {
    SET_TOP_DIRS: (state: LibraryBrowserState, action: ReturnType<typeof setTopDirs>) => {
        const copy = action.payload ? [...action.payload] : [];
        // if (copy.length === 4) {
        //     const ps = action.payload[3];
        //     copy.splice(1, 0, ps);
        //     copy.splice(4, 1);
        // }
        return {
            ...state,
            libBrowserConfig: {
                ...state.libBrowserConfig,
                topDirs: copy,
            },
        };
    },
    SET_FILTER_SORTS_CONFIG: (state: LibraryBrowserState, action: LibraryBrowserActions) => {
        const filterSorts = (action.payload || {}) as FilterSortsConfig;
        return {
            ...state,
            libBrowserConfig: {
                ...state.libBrowserConfig,
                availableSorts: filterSorts.availableSorts ? filterSorts.availableSorts : [],
                availableFilters: filterSorts.availableFilters ? filterSorts.availableFilters : [],
                defaultSort: filterSorts.defaultSort,
            },
        };
    },
    SET_LIBRARY_PATH: (state: LibraryBrowserState, action: ReturnType<typeof setLibraryPath>) => {
        if (!action.hasLibPathChanged) {
            return state;
        }
        return state;
        // console.log("here");
        // return {
        //     ...state,
        //     libBrowserConfig: {
        //         ...state.libBrowserConfig,
        //         defaultSort: null,
        //         availableSorts: [],
        //         availableFilters: [],
        //     },
        // };
    },
    // OPEN_HOME_PAGE: (state: LibraryBrowserState, action: ReturnType<typeof setLibraryPath>) => {
    //     return {
    //         ...state,
    //         libBrowserConfig: {
    //             ...state.libBrowserConfig,
    //             defaultSort: null,
    //             availableSorts: [],
    //             availableFilters: [],
    //         },
    //     };
    // },
    SHOW_RECOMMENDATIONS: (
        state: LibraryBrowserState,
        action: ReturnType<typeof showRecommendations>,
    ) => {
        filterSortsConfigsBeforeRelated = clone(state.libBrowserConfig);
        return state;
    },
    RETURN_PRE_RELATED_STATE: (
        state: LibraryBrowserState,
        action: ReturnType<typeof returnPreRelatedState>,
    ) => {
        if (!filterSortsConfigsBeforeRelated) {
            return state;
        }
        const { defaultSort, availableSorts, availableFilters } = filterSortsConfigsBeforeRelated;
        return {
            ...state,
            libBrowserConfig: {
                ...state.libBrowserConfig,
                defaultSort,
                availableSorts,
                availableFilters,
            },
        };
    },
};
