import * as React from 'react';
import { Component } from 'react';
import './SortingController.scss';
import { connect } from 'react-redux';
import { DataItemField, Sort } from 'common/types/AppTypes';
import CollapsableMenu from 'common/components/CollapsableMenu/CollapsableMenu';
import { RootState } from 'adult/state/AdultappStateStore';
import { setSort } from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import 'common/styles/CommonStyles.scss';
import AppLink from 'common/components/AppLink/AppLink';
import { addSortToUrl } from 'common/components/LibraryController/SortingController/SortToUrl';
import AppIconButton from 'common/components/AppIconButton/AppIconButton';
import {setSortM} from "common/components/AppLink/MemoActionCreators";

interface SortingControllerProps {
    availableSorts: Array<DataItemField>;
    currentSort: Sort;
    defaultSort: Sort;
    setSort: (sort: Sort) => void;
    isHomePage: boolean;
}

function changeSortOrder(sort: Sort): Sort {
    if (!sort) return null;
    return { sortField: sort.sortField, isAsc: !sort.isAsc };
}

export class SortingControllerComponent extends Component<SortingControllerProps> {
    changeOrderAction = () => {
        const appliedSort = this.getAppliedSort();
        if (!appliedSort) return null;
        return setSortM({ ...appliedSort, isAsc: !appliedSort.isAsc });
    };

    resolveSortFromField = (sort: DataItemField) => {
        const appliedSort = this.getAppliedSort();
        return { sortField: sort, isAsc: appliedSort.isAsc };
    };

    changeSort = (sort: DataItemField) => {
        return setSortM(this.resolveSortFromField(sort));
    };

    getAppliedSort = () => {
        const { currentSort, defaultSort } = this.props;
        return currentSort || defaultSort;
    };

    render() {
        const { availableSorts, defaultSort, isHomePage } = this.props;
        const appliedSort = this.getAppliedSort();
        const sorts = availableSorts.map((s) => (
            <AppLink
                key={s.name}
                href={addSortToUrl(this.resolveSortFromField(s), defaultSort)}
                stateAction={this.changeSort(s)}
            >
                {s.name}
            </AppLink>
        ));
        const isHidden = !appliedSort || isHomePage;
        const pickerStyle = `sortPicker${isHidden ? ' hidden' : ''}`;
        return (
            <>
                <AppLink
                    href={addSortToUrl(changeSortOrder(appliedSort), defaultSort)}
                    stateAction={this.changeOrderAction()}
                    hidden={isHidden}
                >
                    <AppIconButton
                        iconName={`sort${appliedSort && appliedSort.isAsc ? 'Asc' : 'Desc'}`}
                    />
                </AppLink>
                <CollapsableMenu isRightOriented>
                    <span styleName={pickerStyle}>
                        {appliedSort ? appliedSort.sortField.name : 'Sorts: '}
                    </span>
                    <div styleName="availableSorts">{sorts}</div>
                </CollapsableMenu>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    defaultSort: state.notUndoable.libBrowserConfig.defaultSort,
    availableSorts: state.notUndoable.libBrowserConfig.availableSorts,
    currentSort: state.undoable.present.libraryBrowserDataKey.sort,
    isHomePage: state.undoable.present.libraryBrowserDataKey.isHomePage,
});

const mapPropsToDispatch = {
    setSort,
};

const SortingController = connect(mapStateToProps, mapPropsToDispatch)(SortingControllerComponent);

export default SortingController;
