import * as React from 'react';
import { Component, createRef, ReactNode } from 'react';
import './AppliedFiltersController.scss';
import { FilterCriteria } from 'common/types/AppTypes';
import { secondsToTimeString } from 'common/components/inputs/DurationInputField/DurationInputField';
import { RootState } from 'adult/state/AdultappStateStore';
import {
    addFilter,
    clearAllFilters,
    LibraryBrowserState,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { connect } from 'react-redux';

import { getElementContentHeight } from 'common/components/utils/ElementSizes';
import ReactResizeDetector from 'react-resize-detector';
import AppLink from 'common/components/AppLink/AppLink';
import AppIconButton from 'common/components/AppIconButton/AppIconButton';
import { removeFilterM } from 'common/components/AppLink/MemoActionCreators';

interface AppliedFiltersControllerProps {
    appliedFilters: Array<FilterCriteria<unknown>>;
}

function getFilterText(filter: FilterCriteria<unknown>): string {
    let filterVals;
    if (filter.field.type === 'TIME') {
        filterVals = `${secondsToTimeString(filter.min as number)} : ${secondsToTimeString(
            filter.max as number,
        )}`;
    } else {
        filterVals =
            filter.min +
            (filter.min !== filter.max && filter.max !== null ? ` : ${filter.max}` : '');
    }
    return `${filter.field.name}: (${filterVals})`;
}

const clearAllFiltersAction = clearAllFilters();
export class AppliedFiltersControllerComponent extends Component<AppliedFiltersControllerProps> {
    private containerRef = createRef<HTMLUListElement>();

    createAppliedFilterControl = (appliedFilter: FilterCriteria<unknown>): ReactNode => {
        // const { removeFilter } = this.props;
        const filterText = appliedFilter.label ? appliedFilter.label : getFilterText(appliedFilter);
        return (
            <AppLink key={filterText} stateAction={removeFilterM(appliedFilter)} stateActionLink>
                <span styleName="appliedFilterText">{filterText}</span>
                <span className="icon-closeFilter" />
            </AppLink>
        );
    };

    render() {
        const { appliedFilters } = this.props;
        let filters: Array<ReactNode> = [];
        if (appliedFilters) {
            filters = appliedFilters.map(this.createAppliedFilterControl);
            if (filters.length > 0) {
                filters.unshift(
                    <AppLink
                        key="clearAllButton"
                        stateAction={clearAllFiltersAction}
                        stateActionLink
                    >
                        <AppIconButton iconName="removeAllFilters" />
                    </AppLink>,
                );
            }
        }
        return (
            <ul styleName="appliedFilters" ref={this.containerRef}>
                {filters}
            </ul>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const present = state.undoable.present as LibraryBrowserState;
    return {
        appliedFilters: present.libraryBrowserDataKey.filters,
    };
};

const mapPropsToDispatch = {
    addFilter,
};

const AppliedFiltersController = connect(mapStateToProps, mapPropsToDispatch, null, {
    forwardRef: true,
})(AppliedFiltersControllerComponent);

export default AppliedFiltersController;
