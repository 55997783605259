import * as React from 'react';
import { Component, createRef, ReactElement } from 'react';
import './CollapsableMenu.scss';
import 'common/styles/CommonStyles.scss';

interface CollapsableMenuProps {
    children: ReactElement[];
    isRightOriented?: boolean;
}

interface CollapsableMenuState {
    isShowing: boolean;
}

export default class CollapsableMenu extends Component<CollapsableMenuProps, CollapsableMenuState> {
    private triggerRef = createRef<HTMLDivElement>();

    private containerRef = createRef<HTMLDivElement>();

    private menuRef = createRef<HTMLDivElement>();

    constructor(props: CollapsableMenuProps) {
        super(props);
        this.state = { isShowing: false };
    }

    handleDocTouch = (event: MouseEvent) => {
        if (this.containerRef.current && !this.containerRef.current.contains(event.target)) {
            document.removeEventListener('mousedown', this.handleDocClick);
            document.removeEventListener('touchstart', this.handleDocTouch);
            this.setState((prevState) => ({ isShowing: !prevState.isShowing }));
        }
    };

    handleDocClick = (event: MouseEvent) => {
        if (this.triggerRef.current && !this.triggerRef.current.contains(event.target)) {
            document.removeEventListener('mousedown', this.handleDocClick);
            document.removeEventListener('touchstart', this.handleDocTouch);
            this.setState((prevState) => ({ isShowing: !prevState.isShowing }));
        }
    };

    onClick = () => {
        const { isShowing } = this.state;
        if (!isShowing) {
            document.addEventListener('mousedown', this.handleDocClick);
            document.addEventListener('touchstart', this.handleDocTouch);
        } else {
            document.removeEventListener('mousedown', this.handleDocClick);
            document.removeEventListener('touchstart', this.handleDocTouch);
        }
        this.setState((prevState) => ({ isShowing: !prevState.isShowing }));
    };

    reRender = () => {
        this.setState(this.state);
    };

    render() {
        const menuStyle = {};
        if (typeof window !== 'undefined' && this.menuRef && this.menuRef.current) {
            menuStyle.maxHeight =
                window.innerHeight - this.menuRef.current.getBoundingClientRect().y;
        }
        const { children, isRightOriented } = this.props;
        const { isShowing } = this.state;
        const menuProp = children[1];
        const className = `${menuProp.props.className} cmm${isShowing ? ' cmmS' : ''}`;
        const trigger = React.cloneElement(children[0], {
            onClick: this.onClick,
            ref: this.triggerRef,
            onMouseOver: this.reRender,
        });
        const menu = React.cloneElement(menuProp, {
            className,
            ref: this.menuRef,
            style: menuStyle,
        });
        let scrollVisible = false;
        if (
            this.menuRef &&
            this.menuRef.current &&
            this.menuRef.current.scrollHeight - this.menuRef.current.clientHeight > 0
        ) {
            scrollVisible = true;
        }
        const styleName = `collapsableMenu${isRightOriented ? ' rightOriented' : ''}${
            scrollVisible && isShowing ? ' scrollVisible' : ''
        }`;
        const left =
            this.menuRef && this.menuRef.current
                ? this.menuRef.current.getBoundingClientRect().width
                : '0';
        const scrollIndStyle = { top: menuStyle.maxHeight};
        if(isRightOriented) {
            scrollIndStyle.right = left;
        } else {
            scrollIndStyle.left = left;
        }
        return (
            <div ref={this.containerRef} styleName={styleName}>
                {trigger}
                {menu}
                <div styleName="scrollIndicator" style={scrollIndStyle}>
                    &#9660;
                </div>
            </div>
        );
    }
}
