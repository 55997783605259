export function isNumDefined(n: number): boolean {
    return n === 0 || !!n;
}

export function clone<T>(item: T): T {
    return JSON.parse(JSON.stringify(item));
}

export function padWithZeros(number) {
    const dec = number.split('.')[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    return Number(number).toFixed(len);
}