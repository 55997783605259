export default class Observable<T> {
    private subscribers: Array<(item: T) => void> = [];

    private lastItem: T = null;

    private isDisabled = false;

    subscribe(action: (item: T) => void): void {
        this.subscribers.push(action);
    }

    unsubscribe(action: (item: T) => void): void {
        const index = this.subscribers.indexOf(action);
        if (index > -1) {
            this.subscribers.splice(index, 1);
        }
    }

    getLast(): T {
        return this.lastItem;
    }

    disable(): void {
        this.isDisabled = true;
    }

    enable(): void {
        this.isDisabled = false;
        this.subscribers.forEach((action) => {
            action(this.lastItem);
        });
    }

    next(item: T): void {
        this.lastItem = item;
        if (!this.isDisabled) {
            this.subscribers.forEach((action) => {
                action(item);
            });
        }
    }
}
