import {
    addFilter, openHomePage,
    removeFilter,
    returnPreRelatedState,
    setLibraryPath, setSort
} from "adult/state/LibraryBrowserState/LibraryBrowserState";
import memoize from "fast-memoize";
import {openVideo} from "adult/state/ComposedActions";

export const setLibPathM = memoize(setLibraryPath);

export const returnPreRelatedStateM = returnPreRelatedState();

export const openVideoM = memoize(openVideo);

export const addFilterM = memoize(addFilter);

export const removeFilterM = memoize(removeFilter);

export const setSortM = memoize(setSort);

export const openHomePageAction = openHomePage();
