import * as React from 'react';
import './InfoPage.scss';
import { Component } from 'react';
import UrlControllerInstance from 'common/client/UrlController';
import rtaLogo from 'adult/resources/legalDisclaimers/rtaImage.gif';
import {setPageDetails} from "common/client/PageDetailsController";
import {pageInfoFromTitle} from "adult/components/InformationPanel/InfoApp/PageInfoForUrl";

interface InfoPageProps {
    data: Record<string, string>;
    initialPage?: string;
}

interface InfoPageState {
    infoPageShown: string;
}

function toUrlString(heading: string) {
    return heading
        .toLowerCase()
        .replace(/[^a-z\s]/g, '')
        .trim()
        .split(/\s/)
        .join('-');
}

export default class InfoPage extends Component<InfoPageProps, InfoPageState> {
    constructor(props: InfoPageProps) {
        super(props);
        const { initialPage } = props;
        this.state = {
            infoPageShown: initialPage || null,
        };
    }

    componentDidMount(): void {
        const { data } = this.props;
        const { infoPageShown } = this.state;
        const headings = Object.keys(data);
        const heading = infoPageShown || headings[0];
        setPageDetails(pageInfoFromTitle(heading));
        UrlControllerInstance.updateUrlInfoPage(`/${toUrlString(heading)}`);
    }

    headingClicked = (heading: string) => () => {
        this.setState({ infoPageShown: heading });
        setPageDetails(pageInfoFromTitle(heading));
        UrlControllerInstance.updateUrlInfoPage(`/${toUrlString(heading)}`);
    };

    render() {
        const { data } = this.props;
        const { infoPageShown } = this.state;
        const headings = Object.keys(data);
        const elems = headings.map((heading) => (
            <a
                key={heading}
                href={`/${toUrlString(heading)}`}
                onClick={this.headingClicked(heading)}
            >
                {heading}
            </a>
        ));
        const datum = data[infoPageShown] || data[headings[0]];
        return (
            <div styleName="infoPage">
                <div styleName="headings">{elems}</div>
                <div styleName="infoContent" dangerouslySetInnerHTML={{ __html: datum }} />
                <div styleName="infoFooter">
                    {/*<a href="http://www.rtalabel.org">*/}
                    {/*    <img src={rtaLogo} alt="RTA logo" />*/}
                    {/*</a>*/}
                    Ⓒ 2022 Youlibs.com. All rights reserved.
                </div>
            </div>
        );
    }
}
