import { Reducer } from 'redux';

export interface AdsState {
    id: string;
    banner: string;
    triggerSwitch: boolean;
}

export const initialAdsState: AdsState = {
    id: null,
    banner: null,
    triggerSwitch: false,
};

export const setAdState = (ad: { id: string; refresh: boolean}) => ({
    type: 'SET_AD_STATE',
    payload: ad,
});

export type AdsStateActions = ReturnType<typeof setAdState>;

export const AdsStateActionHandlers: Record<string, Reducer> = {
    SET_AD_STATE: (state: AdsState, action: AdsStateActions) => {
        return {
            ...state,
            id: action.payload.id,
            triggerSwitch: action.payload.refresh ? !state.triggerSwitch : state.triggerSwitch,
        };
    },
};
