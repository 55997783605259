import * as React from 'react';
import './StringFilterInput.scss';
import { Component, createRef } from 'react';
import { DataItemField, FilterCriteria } from 'common/types/AppTypes';
import SearchField from 'common/components/SearchField/SearchField';
import AppIconButton from 'common/components/AppIconButton/AppIconButton';
import SearchSuggestionsInput, {
    Suggestion,
    SuggestionsFetcher,
} from 'common/components/SearchSuggestionsInput/SearchSuggestionsInput';
import { sendRequest } from 'adult/components/ApiComs/ClientApiComs';

interface StringFilterInputProps {
    filter: DataItemField;
    applyFilter: (filter: FilterCriteria<string>) => void;
}

const suggestionsFetcher = (name: string) => (
    input: string,
    consumer: (r: Record<string, Array<Suggestion>>) => void,
) => {
    sendRequest('autocomplete', { name, input }, (data) => {
        if(data.Tags){
            data.Tags.forEach(t => {
                t.thumbnail = null;
            });
        }
        consumer(data);
    });
};

const flexGrow = { flexGrow: 1 };

export default class StringFilterInput extends Component<StringFilterInputProps> {
    private searchFieldRef = createRef<SearchSuggestionsInput>();

    applyFilter = (input: string) => {
        const { filter, applyFilter } = this.props;
        const filterCriteria = {
            field: filter,
            min: input,
            max: null as string,
        };
        applyFilter(filterCriteria);
    };

    applyClicked = () => {
        this.applyFilter(this.searchFieldRef.current.getInputFieldValue());
    };

    focusIn = () => {
        if (this.searchFieldRef.current) {
            this.searchFieldRef.current.focusIn();
        }
    };

    componentDidMount(): void {
        this.focusIn();
    }

    componentDidUpdate(prevProps: Readonly<StringFilterInputProps>): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.focusIn();
        }
    }

    render() {
        const { filter } = this.props;
        return (
            <>
                <AppIconButton iconName="apply" onClick={this.applyClicked} />
                <SearchSuggestionsInput
                    style={flexGrow}
                    placeholder={`${filter.name} to show...`}
                    suggestionFetcher={suggestionsFetcher(filter.name)}
                    onSubmit={this.applyFilter}
                    ref={this.searchFieldRef}
                />
            </>
        );
    }
}
