import * as React from 'react';
import {Component, createRef} from 'react';
import './InformationPanel.scss';
import AppIconButton from 'common/components/AppIconButton/AppIconButton';
import 'common/styles/CommonStyles.scss';
import LoadingIndicator from 'common/components/LoadingIndicator/LoadingIndicator';
import Async from 'react-async';
import { sendGetRequest, sendRequest } from 'adult/components/ApiComs/ClientApiComs';
import InfoPage from "adult/components/InformationPanel/InfoPage/InfoPage";
import UrlControllerInstance from "common/client/UrlController";
import CloseButton from "common/components/CloseButton/CloseButton";
import {updatePageDetailsFromState} from "common/client/PageDetailsController";

interface InformationPanelProps {}

interface InformationPanelState {
    isShowing: boolean;
}

let info: Record<string, string> = null;
const getInfo = async () => {
    const promise = new Promise((resolve, reject) => {
        if (info) {
            resolve(info);
        } else {
            sendGetRequest('info', (resp) => {
                info = resp as Record<string, string>;
                resolve(resp);
            });
        }
    });
    return promise;
};

export default class InformationPanel extends Component<
    InformationPanelProps,
    InformationPanelState
> {
    private buttonRef = createRef<HTMLAnchorElement>();

    private divRef = createRef<HTMLDivElement>();

    constructor(props: InformationPanelProps) {
        super(props);
        this.state = { isShowing: false };
        UrlControllerInstance.onBackFromInfo(() => {
            this.setState((prevState) => ({ isShowing: false }));
        });
    }

    buttonClicked = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const { isShowing } = this.state;
        if(event.target === this.divRef.current || !isShowing ) {
            this.setState((prevState) => ({ isShowing: !prevState.isShowing }));
            if (isShowing) {
                this.closePage();
            }
        }
    };

    closePanel = () => {
        this.setState((prevState) => ({ isShowing: false }));
        this.closePage();
    }

    closePage = () => {
        UrlControllerInstance.infoPageClosed();
        updatePageDetailsFromState();
    }

    render() {
        const { isShowing } = this.state;
        const styleName = `informationPanel${isShowing ? '' : ' hidden'}`;
        return (
            <>
                <a href="/faq" onClick={this.buttonClicked} ref={this.buttonRef}>
                    <AppIconButton iconName="information" text="info" />
                </a>
                <div styleName={styleName} onClick={this.buttonClicked} ref={this.divRef}>
                    <CloseButton onClick={this.closePanel} />
                    {isShowing ? (
                        <Async promiseFn={getInfo}>
                            {({ data, error, isPending }: Record<string, unknown>) => {
                                if (isPending) {
                                    return <LoadingIndicator />;
                                }
                                if (data) {
                                    return <InfoPage data={data as Record<string, string>} />;
                                }
                                return <>Error!</>;
                            }}
                        </Async>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }
}
