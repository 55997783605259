import * as React from 'react';
import { Component } from 'react';
import './NavPath.scss';
import {
    LibraryBrowserState,
    setLibraryPath,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { connect } from 'react-redux';
import { libraryPathSeperator } from 'adult/confg/AppConfig';
import { Dir } from 'common/types/AppTypes';
import { RootState } from 'adult/state/AdultappStateStore';
import AppLink from 'common/components/AppLink/AppLink';
import {
    getOrientationsPath,
    getPathString,
} from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import { returnPreRelatedStateM, setLibPathM } from 'common/components/AppLink/MemoActionCreators';

interface NavPathProps {
    parentPath: string;
    searchTerm: string;
    relatedTo: Array<string>;
    prevPath: string;
    prevSearch: string;
    prevHome: boolean;
    setLibraryPath: (path: Dir) => void;
    orientations: Array<string>;
}

function prevStateText(prevPath: string, prevSearch: string, prevHome: boolean): string {
    if (prevPath) {
        const dirs = prevPath.split(libraryPathSeperator);
        return dirs[dirs.length - 1];
    }
    if (prevSearch) {
        return `Search: "${prevSearch}"`;
    }
    if (prevHome) {
        return 'Home';
    }
    return '(previous)';
}

export class NavPathComponent extends Component<NavPathProps> {
    render() {
        const {
            parentPath,
            searchTerm,
            relatedTo,
            prevPath,
            prevSearch,
            prevHome,
            orientations,
        } = this.props;
        if (parentPath) {
            const path = parentPath.replace(`root${libraryPathSeperator}`, '').replace('root', '');
            const dirs = path.split(libraryPathSeperator);
            const links = [];
            for (let i = 0; i < dirs.length; i++) {
                if (i !== dirs.length - 1) {
                    const subDirs = dirs.slice(0, i + 1);
                    const subPath = `root${libraryPathSeperator}${subDirs.join(
                        libraryPathSeperator,
                    )}`;
                    links.push(
                        <AppLink
                            key={subPath}
                            href={`${getOrientationsPath(orientations)}/${getPathString(subPath)}`}
                            stateAction={setLibPathM({ path: subPath, isMediaParent: false })}
                        >
                            {dirs[i]}
                        </AppLink>,
                    );
                    links.push(<span key={`arrow${i}`}>{'\u21E8'}</span>);
                } else {
                    links.push(
                        <h1 key={path}>
                            {dirs[i]}
                        </h1>,
                    );
                }
            }
            return <>{links}</>;
        }
        if (searchTerm) {
            return <h1>{`Search: ${searchTerm}`}</h1>;
        }
        if (relatedTo) {
            return (
                <>
                    <h1>Related</h1>
                    {prevPath || prevSearch || prevHome ? (
                        <AppLink stateAction={returnPreRelatedStateM} stateActionLink>
                            {`  (Return to: ${prevStateText(prevPath, prevSearch, prevHome)})`}
                        </AppLink>
                    ) : (
                        <></>
                    )}
                </>
            );
        }
        return <h1>Home Page</h1>;
    }
}

const mapStateToProps = (state: RootState) => {
    const libState = state.undoable.present as LibraryBrowserState;
    const dataKey = libState.libraryBrowserDataKey;
    const prevState = libState.previousNoneRelatedDataKey;
    return {
        parentPath: dataKey.parentPath,
        searchTerm: dataKey.searchTerm,
        relatedTo: dataKey.relatedTo,
        prevPath: prevState ? prevState.parentPath : null,
        prevSearch: prevState ? prevState.searchTerm : null,
        prevHome: prevState ? prevState.isHomePage : null,
        orientations: libState.libraryBrowserDataKey.orientations,
    };
};

const mapDispatchToProps = { setLibraryPath };

const NavPath = connect(mapStateToProps, mapDispatchToProps)(NavPathComponent);

export default NavPath;
