import { PageDetails, stateToPageDetails } from 'adult/state/StateToPageInfo';
import pgoStore from 'adult/state/AdultappStateStore';

let currentPageDetails: string = null;

export function setPageDetails(details: PageDetails): void {
    const stringified = JSON.stringify(details);
    if (currentPageDetails !== stringified || document.title !== details.title) {
        document.title = details.title;
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', details.description);
        currentPageDetails = stringified;
    }
}

export function updatePageDetailsFromState() {
    setPageDetails(stateToPageDetails(pgoStore.getState().undoable.present, pgoStore.getState().notUndoable));
}
