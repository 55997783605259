import {RefObject} from "react";

const SwipeListener = {
    detectswipe(ele, func, ref: RefObject<HTMLDivElement>) {
        const swipe_det = {};
        swipe_det.sX = 0;
        swipe_det.sY = 0;
        swipe_det.eX = 0;
        swipe_det.eY = 0;
        const min_x = 30; // min x swipe for horizontal swipe
        const max_x = 50; // max x difference for vertical swipe
        const min_y = 40; // min y swipe for vertical swipe
        const max_y = 60; // max y difference for horizontal swipe
        const maxSwipeInterval = 500;
        let direc = '';
        let startTime;
        ele.addEventListener(
            'touchstart',
            function (e) {
                startTime = Date.now();
                const t = e.touches[0];
                swipe_det.sX = t.screenX;
                swipe_det.sY = t.screenY;
            },
            false,
        );
        ele.addEventListener(
            'touchmove',
            function (e) {
                if(!ref  || !ref.current || !ref.current.contains(e.target)){
                    e.preventDefault();
                }
                const t = e.touches[0];
                swipe_det.eX = t.screenX;
                swipe_det.eY = t.screenY;
            },
            false,
        );
        ele.addEventListener(
            'touchend',
            function (e) {
                if (Date.now() - startTime < maxSwipeInterval) {
                    // horizontal detection
                    if (
                        (swipe_det.eX - min_x > swipe_det.sX ||
                            swipe_det.eX + min_x < swipe_det.sX) &&
                        swipe_det.eY < swipe_det.sY + max_y &&
                        swipe_det.sY > swipe_det.eY - max_y &&
                        swipe_det.eX > 0
                    ) {
                        if (swipe_det.eX > swipe_det.sX) direc = 'r';
                        else direc = 'l';
                    }
                    // vertical detection
                    else if (
                        (swipe_det.eY - min_y > swipe_det.sY ||
                            swipe_det.eY + min_y < swipe_det.sY) &&
                        swipe_det.eX < swipe_det.sX + max_x &&
                        swipe_det.sX > swipe_det.eX - max_x &&
                        swipe_det.eY > 0
                    ) {
                        if (swipe_det.eY > swipe_det.sY) direc = 'd';
                        else direc = 'u';
                    }
                }

                if (direc !== '') {
                    if (typeof func === 'function') func(direc);
                }
                direc = '';
                swipe_det.sX = 0;
                swipe_det.sY = 0;
                swipe_det.eX = 0;
                swipe_det.eY = 0;
            },
            false,
        );
    },
};

export default SwipeListener;
