import { DataItemField, FilterCriteria } from 'common/types/AppTypes';

export function readFilterConfigs(
    suggests: Record<string, FilterConfig>,
): Array<FilterCriteria<unknown>> {
    let filterConfigs: Array<FilterCriteria<unknown>> = [];
    Object.keys(suggests).forEach((key) => {
        const filterField = suggests[key].filter;
        filterConfigs = filterConfigs.concat(
            suggests[key].suggests.map((suggest) => ({
                label: suggest.label,
                field: filterField,
                min: suggest.values[0],
                max: suggest.values.length > 1 ? suggest.values[1] : null,
                notMobile: suggest.notMobile,
            })),
        );
    });
    return filterConfigs;
}

export function getFilterCriteria(
    availableFilters: Array<DataItemField>,
    appliedFilters: Array<FilterCriteria<unknown>>,
    suggests: Record<string, FilterConfig>,
): Array<FilterCriteria<unknown>> {
    const availableFilterNames = availableFilters.map((filterObj) => filterObj.name);
    return readFilterConfigs(suggests).filter(
        (filterCriteria) =>
            availableFilterNames.indexOf(filterCriteria.field.name) > -1 &&
            !appliedFilters.find((appliedFilter) => appliedFilter.label === filterCriteria.label),
    );
}

export function filtersPerLabel(
    filterConfigs: Array<FilterCriteria<unknown>>,
): Record<string, FilterCriteria<unknown>> {
    const map = {} as Record<string, FilterCriteria<unknown>>;
    filterConfigs.forEach((filter) => {
        map[filter.label] = filter;
    });
    return map;
}

interface FilterConfig {
    filter: DataItemField;
    suggests: Array<SuggestValue>;
}

interface SuggestValue {
    values: Array<unknown>;
    label: string;
    notMobile?:boolean;
}
