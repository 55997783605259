import * as React from 'react';
import {Component} from 'react';
import './XvMobileEmbedOverlay.scss';
import SwipeListener from "common/functions/SwipeListener/SwipeListener";

interface XvMobileEmbedOverlayProps {
    onUpSwipe?: () => void;
}

export default class XvMobileEmbedOverlay extends Component<XvMobileEmbedOverlayProps> {
    setUpSwipeListener = (ref: HTMLDivElement) => {
        if (ref) {
            const { onUpSwipe } = this.props;
            SwipeListener.detectswipe(ref, (swipe: string) => {
                if (swipe === 'u' && onUpSwipe) {
                    onUpSwipe();
                }
            });
        }
    };

    render() {
        return (
            <div styleName="xvMobileEmbedOverlay" ref={this.setUpSwipeListener}>
                <div />
                <div />
                <div />
            </div>
        );
    }
}