import {Coord, Dims} from 'common/types/AppTypes';

export function getSpaceTakenUpByPlayerBoxes(boxes: Array<Coord>): Dims {
    let maxX = 0;
    let maxY = 0;
    let maxXY = 0;
    let maxYX = 0;
    for (const box of boxes) {
        const x = box.width + box.x;
        const y = box.height + box.y;
        // console.log(x + " : "+ y);
        if (x >= maxX) {
            if (maxX !== x) {
                maxXY = 0;
            }
            maxX = x;
            if (maxXY < y) {
                maxXY = y;
            }
        }
        if (y >= maxY) {
            if (maxY !== y) {
                maxYX = 0;
            }
            maxY = y;
            if (maxYX < x) {
                maxYX = x;
            }
        }
    }
    const newVar = { width: maxX, height: maxY, subX: maxYX, subY: maxXY };
    // console.log(newVar);
    return newVar;
}

export function emptySpaceOnGrid(gridSize: Dims, sizeTakenUp: Dims) {
    const widthLeft = gridSize.width - sizeTakenUp.width;
    const heightLeft = gridSize.height - sizeTakenUp.height;
    const toRight = widthLeft > heightLeft;
    const top = toRight ? 0 : sizeTakenUp.height;
    const left = toRight ? sizeTakenUp.width : 0;
    const subspace = {
        width: toRight ? widthLeft : sizeTakenUp.width,
        height: toRight ? sizeTakenUp.height : heightLeft,
        top,
        left,
    };
    return subspace;
}