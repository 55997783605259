import * as React from 'react';
import { Component } from 'react';
import './PagingController.scss';
import { getPageNumSplit } from 'common/components/PagingController/PagingCalculator';
import UrlControllerInstance from 'common/client/UrlController';

interface PagingControllerProps {
    numPages: number;
    currentPage: number;
    pagesAround: number;
}

export default class PagingController extends Component<PagingControllerProps> {
    constructor(props: PagingControllerProps) {
        super(props);
    }

    pageLinkCreator = (index: number) => {
        const url = UrlControllerInstance.getCurrentUrlWithPage(index);
        return (
            <a key={index} href={url}>
                {index}
            </a>
        );
    };

    dots = () => {
        return <span>...</span>;
    };

    render() {
        const { numPages, currentPage, pagesAround } = this.props;
        if(numPages === 0) return <div styleName="pagingController"></div>;
        const pageSplit = getPageNumSplit(numPages, pagesAround, currentPage);
        const firstNums = pageSplit.startGroup.map(this.pageLinkCreator);
        const secondNums = pageSplit.middleGroup.map(this.pageLinkCreator);
        const thirdNums = pageSplit.endGroup.map(this.pageLinkCreator);
        return (
            <div styleName="pagingController">
                <span>‹</span>
                {firstNums}
                {secondNums.length > 0 || thirdNums.length > 0 ? this.dots() : <></>}
                {secondNums.length > 0 ? secondNums : thirdNums.length > 0 ? thirdNums : <></>}
                {secondNums.length > 0 && thirdNums.length > 0 ? (
                    <>
                        {this.dots()} {thirdNums}
                    </>
                ) : (
                    <></>
                )}
                <span>›</span>
            </div>
        );
    }
}
