import * as React from 'react';
import './AppearingPreviewWindow.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'adult/state/AdultappStateStore';
import { LayoutState, PreviewParams } from 'adult/state/LayoutState/LayoutState';

interface AppearingPreviewWindowProps {
    previewParams: PreviewParams;
}

export class AppearingPreviewWindowComponent extends Component<AppearingPreviewWindowProps> {
    render() {
        const { previewParams } = this.props;
        if (!previewParams) return <></>;
        return (
            <div styleName="appearingWindow" style={previewParams.style}>
                {previewParams.content}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layoutState = state.notUndoable as LayoutState;
    return {
        previewParams: layoutState.previewParams,
    };
};

const AppearingPreviewWindow = connect(mapStateToProps)(AppearingPreviewWindowComponent);

export default AppearingPreviewWindow;
