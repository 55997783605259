import * as React from 'react';
import {Component, ReactElement} from 'react';
import './FilterRangeInput.scss';
import {DataItemField, FilterCriteria} from 'common/types/AppTypes';
import SeperatedNumericField from 'common/components/inputs/SeperatedNumericField/SeperatedNumericField';
import DurationInputField from 'common/components/inputs/DurationInputField/DurationInputField';
import RangeSlider from 'common/components/RangeSlider/RangeSlider';
import AppIconButton from 'common/components/AppIconButton/AppIconButton';
import DynamicInputField from 'common/components/DynamicInputField/DynamicInputField';
import {padWithZeros} from 'common/components/utils/Helpers';
import DateInputField from "common/components/inputs/DateInputField/DateInputField";

type RangeValue = string | number | Date;

interface FilterRangeInputTypeParams {
    sliderToInput: (val: number) => RangeValue;
    inputToSlider: (val: RangeValue) => number;
    inputCreator: (
        val: RangeValue,
        onChange: (val: RangeValue) => void,
        minLength?: number,
    ) => ReactElement;
    inputBounce: number;
}

function formatDate(date: Date) {
    return (
        `${date.getFullYear()}` +
        `-${`0${date.getMonth() + 1}`.slice(-2)}` +
        `-${`0${date.getDate()}`.slice(-2)}`
    );
}

const typeParams: Record<string, FilterRangeInputTypeParams> = {
    INT: {
        sliderToInput(val: number) {
            return Math.round(val);
        },
        inputToSlider(val: number) {
            return val;
        },
        inputCreator(val: number, onChange: (val: number) => void, minLength?: number) {
            return <SeperatedNumericField value={val} onChange={onChange} minLength={minLength} />;
        },
        inputBounce: 500,
    },
    DOUBLE: {
        sliderToInput(val: number) {
            return padWithZeros(`${Math.round(val * 100) / 100}`);
        },
        inputToSlider(val: number) {
            return parseFloat(val);
        },
        inputCreator(val: number, onChange: (val: number) => void, minLength?: number) {
            return (
                <DynamicInputField
                    type="text"
                    value={val}
                    onChange={(e) => onChange(parseFloat(e.target.value))}
                    minLength={minLength}
                />
            );
        },
        inputBounce: 500,
    },
    DATE: {
        sliderToInput(val: number) {
            return formatDate(new Date(Math.round(val)));
        },
        inputToSlider(val: string) {
            return new Date(val).getTime();
        },
        inputCreator(val: string, onChange: (val: string) => void) {
            return (
                <DateInputField value={val} onChange={onChange} />
            );
        },
        inputBounce: 20,
    },
    TIME: {
        sliderToInput(val: number) {
            return Math.round(val);
        },
        inputToSlider(val: number) {
            return val;
        },
        inputCreator(val: number, onChange: (val: number) => void) {
            return <DurationInputField value={val} onChange={onChange} />;
        },
        inputBounce: 500,
    },
};

interface FilterRangeInputProps {
    min: RangeValue;
    max: RangeValue;
    filter: DataItemField;
    applyFilter: (filter: FilterCriteria<RangeValue>) => void;
}

interface FilterRangeInputState {
    val1: RangeValue;
    val2: RangeValue;
}

export default class FilterRangeInput extends Component<
    FilterRangeInputProps,
    FilterRangeInputState
> {
    private typeParams: FilterRangeInputTypeParams;

    constructor(props: FilterRangeInputProps) {
        super(props);
        const { min, max, filter } = this.props;
        this.typeParams = typeParams[filter.type] as FilterRangeInputTypeParams;
        this.state = {
            val1: this.typeParams.sliderToInput(this.typeParams.inputToSlider(min)),
            val2: this.typeParams.sliderToInput(this.typeParams.inputToSlider(max)),
        };
    }

    componentDidUpdate(prevProps: Readonly<FilterRangeInputProps>): void {
        const { min, max } = this.props;
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({
                val1: min,
                val2: max,
            });
        }
    }

    applyFilter = () => {
        const { filter, applyFilter } = this.props;
        const { val1, val2 } = this.state;
        const filterCriteria = {
            field: filter,
            min: val1,
            max: val2,
        };
        applyFilter(filterCriteria);
    };

    onSlide = (value: Array<number>) => {
        this.setState({
            val1: this.typeParams.sliderToInput(value[0]),
            val2: this.typeParams.sliderToInput(value[1]),
        });
    };

    val1Change = (val: RangeValue) => {
        this.setState((prevState) => ({ ...prevState, val1: val }));
    };

    val2Change = (val: RangeValue) => {
        this.setState((prevState) => ({ ...prevState, val2: val }));
    };

    render() {
        const { val1, val2 } = this.state;
        const { min, max, filter } = this.props;
        this.typeParams = typeParams[filter.type] as FilterRangeInputTypeParams;
        const val1c = this.typeParams.inputToSlider(val1);
        const val2c = this.typeParams.inputToSlider(val2);
        const minf = this.typeParams.inputToSlider(min);
        const maxf = this.typeParams.inputToSlider(max);
        const t = this.typeParams.sliderToInput(this.typeParams.inputToSlider(max));
        return (
            <>
                <AppIconButton iconName="apply" onClick={this.applyFilter} />
                {this.typeParams.inputCreator(val1, this.val1Change, `${t}`.length)}
                <RangeSlider range={[minf, maxf]} values={[val1c, val2c]} onUpdate={this.onSlide} />
                {this.typeParams.inputCreator(val2, this.val2Change)}
            </>
        );
    }
}
