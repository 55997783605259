import * as React from 'react';
import './ArrowButton.scss';
import { Component } from 'react';

interface ArrowButtonProps {
    left?: boolean;
    onClick?: () => void;
    style?: unknown;
}

export default class ArrowButton extends Component<ArrowButtonProps> {
    render() {
        const { left, onClick, style } = this.props;
        const topStyle = left ? 'rightBottomLeftTop' : 'rightTopLeftBottom';
        const bottomStyle = left ? 'rightTopLeftBottom' : 'rightBottomLeftTop';
        return (
            <div styleName="container" onClick={onClick} style={style}>
                <div styleName={topStyle} />
                <div styleName={bottomStyle} />
            </div>
        );
    }
}
