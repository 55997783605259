import * as React from 'react';
import { Component, createRef, ReactElement } from 'react';
import './PgoMediaItem.scss';
import { isSfw, libraryPathSeperator, sfwThumbs } from 'adult/confg/AppConfig';
import { connect } from 'react-redux';
import {
    LibraryBrowserState,
    setLibraryPath,
} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import { Dims, Dir, HasId, HasThumb } from 'common/types/AppTypes';
import { secondsToTimeString } from 'common/components/inputs/DurationInputField/DurationInputField';
import { formatDate, formatViews } from 'common/components/MediaItem/MediaItemFormatter';
import { openVideoA } from 'adult/state/ContentViewerState/ContentViewerState';
import {
    getOrientationsPath,
    getPathString,
    singleVideoLink,
    titleToUrlString,
} from 'adult/state/LibraryBrowserState/LibBrowserStateToUrl';
import AppLink from 'common/components/AppLink/AppLink';
import { AppAction, RootState } from 'adult/state/AdultappStateStore';
import MediaItemGallery from 'common/components/MediaItemGallery/MediaItemGallery';
import 'common/styles/CommonStyles.scss';
import { calculateImageIndexToShow } from 'common/components/MediaItemGallery/GalleryCalc';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';
import LinkWithPreview from 'common/components/LinkWithPreview/LinkWithPreview';
import { LayoutState } from 'adult/state/LayoutState/LayoutState';
import { openVideoM, setLibPathM } from 'common/components/AppLink/MemoActionCreators';
import { getTodaysRandom } from 'common/functions/Utils/RandomHelpers';
import memoize from 'fast-memoize';
import EpLogo from 'adult/components/logos/EpLogo/EpLogo';
import PhLogo from 'adult/components/logos/PhLogo/PhLogo';
import XvLogo from 'adult/components/logos/XvLogo/XvLogo';

export function processThumbnailSource(source: string): string {
    if (!source) return '';

    const parts = source.split('|');
    if (parts[1] === 'P') return `/image/${parts[0]}.jpg`;
    return parts[0].replace('/ai.', '/ci.');
}

export function getImageDims(imageString: string): Dims {
    const parts = imageString.split('|');
    if (parts.length > 2) {
        const dims = parts[2];
        return { width: parseInt(dims.split(':')[0]), height: parseInt(dims.split(':')[1]) };
    }
    return null;
}

export function getThumbnail(data: PgoMediaItemData): string {
    if (data.thumbnail && data.thumbnail.split('|')[0] !== 'null') return data.thumbnail;
    if (data.gallery) {
        const randomIndex = getTodaysRandom(data.Title, data.gallery.length - 1);
        const galleryImageUrl = data.gallery[randomIndex];
        return `${galleryImageUrl}|D|415:220`;
    }
    return null;
}

export function getThumbnailSource(data: PgoMediaItemData): string {
    return processThumbnailSource(getThumbnail(data));
}

export function isValidThumbnail(data: PgoMediaItemData): boolean {
    return true;
}

export function sfwItemConvert(contentItem: Record<string, unknown>): Record<string, unknown> {
    if (isSfw) {
        if (contentItem.thumbnail) contentItem.thumbnail = sfwThumbs[0];
        contentItem.gallery = sfwThumbs;
    }
    return contentItem;
}

export function contentToDom(contentItem: Record<string, unknown>): ReactElement {
    // console.log(contentItem)
    sfwItemConvert(contentItem);
    // console.log(contentItem);

    // return <SlidingContentView
    //     // ref={contentItem.processVieRefs(startIndex + i, i === 0)}
    //     title={contentItem.title}
    //     numItems={contentItem.numItems}
    //     dims={contentItem.dims}
    //     initialItems={contentItem.initialItems}
    //     getItems={contentItem.getItems}
    //     // isServerSide={isServerSide}
    //     key={contentItem.key}
    //     // parentWidth={width}
    //     // onStartIndexChange={this.viewStartIndexChange(startIndex + i)}
    //     // autoplay={autoplayIndex === bufferIndex + i}
    // />;
    const id = contentItem.id as string;
    // console.log(id)
    return <PgoMediaItem key={id} data={(contentItem as unknown) as PgoMediaItemData} />;
}

export interface PgoMediaItemData extends HasId, HasThumb {
    Views: string;
    Duration: number;
    Title: string;
    gallery: Array<string>;
    fullPath: string;
    isParent: boolean;
    isMediaParent: boolean;
    media?: string;
    Pornstars?: Array<string>;
    parentPaths?: Array<string>;
}

interface PgoMediaItemProps {
    data: PgoMediaItemData;
    style?: unknown;
    setLibraryPath: (path: Dir) => void;
    openVideo: (v: PgoMediaItemData) => void;
    isServerSide: boolean;
    orientations: Array<string>;
    autoplay?: boolean;
    serverHide?: boolean;
}

interface PgoMediaItemState {
    hasImageLoaded: boolean;
    galleryIndex: number;
}

export function getDuration(mediaItem: PgoMediaItemData): string {
    let timeString = secondsToTimeString(mediaItem.Duration);
    if (timeString.startsWith('00:')) {
        timeString = timeString.substring(3, timeString.length);
    }
    return timeString;
}

export function starNameToLink(name: string, orientations: Array<string>) {
    let dirName = name.replace("Channel: ", "");
    const path = `root>Channels>${dirName}`;
    return (
        <LinkWithPreview
            key={name}
            href={`/channels/${titleToUrlString(dirName)}`}
            itemPath={path}
            stateAction={setLibPathM({ path, isMediaParent: true })}
        >
            {name}
        </LinkWithPreview>
    );
}

function getShapeStyleName(mediaItem: PgoMediaItemData): string {
    const paths = mediaItem.parentPaths;
    if (paths) {
        const parent = paths[0];
        if (parent.endsWith('Pornstars')) {
            return 'tallItem';
        }
        if (parent.endsWith('Categories')) {
            return 'tallItem';
            // return 'squareItem';
        }
    }
    return '';
}

function getFontSizeStyleName(mediaItem: PgoMediaItemData): string {
    // let parentPaths = mediaItem.parentPaths;
    const { parentPaths } = mediaItem;
    if (parentPaths) {
        const [first] = parentPaths;
        const parent = first;
        if (
            parent.endsWith('Pornstars') ||
            parent.endsWith('Categories') ||
            parent.endsWith('Tags')
        ) {
            return 'biggerText';
        }
    }
    return '';
}

function siteFilter(siteName: string) {
    if (!siteName) return <></>;
    let siteIcon = null;
    switch (siteName) {
        case 'Eporner':
            siteIcon = <EpLogo />;
            break;
        case 'xVideos':
            siteIcon = <XvLogo />;
            break;
        case 'Pornhub':
            siteIcon = <PhLogo />;
            break;
        default:
            siteIcon = <></>;
    }

    return (
        <AppLink
            key={siteName}
            stateAction={setLibPathM({
                path: `root${libraryPathSeperator}Sites${libraryPathSeperator}${siteName}`,
                isMediaParent: true,
            })}
            href={`/sites/${siteName.toLowerCase()}`}
        >
            {siteIcon}
        </AppLink>
    );
}

function styleToDims(style: unknown) {
    if (style && style.width && style.height) {
        return {
            width: parseInt(style.width.replace('px', '')),
            height: parseInt(style.height.replace('px', '')),
        };
    }
    return null;
}

const siteFilterM = memoize(siteFilter);

export class PgoMediaItemComponent extends Component<PgoMediaItemProps, PgoMediaItemState> {
    private boxRef = createRef<HTMLAnchorElement>();

    private executor = new DebouncingExecutor();

    private cameFromServer = false;

    constructor(props: PgoMediaItemProps) {
        super(props);
        const { isServerSide } = this.props;
        this.cameFromServer = isServerSide;
        this.state = {
            hasImageLoaded: false,
            galleryIndex: null,
        };
    }

    getClickAction = (data: PgoMediaItemData): AppAction => {
        if (data.isParent === '1') {
            return setLibPathM({ path: data.fullTitlePath, isMediaParent: data.isMediaParent });
        }
        return openVideoM(data);
    };

    imageLoaded = () => {
        this.setState((prevState) => ({ ...prevState, hasImageLoaded: true }));
    };

    mouseMoved = (event: React.MouseEvent<unknown>) => {
        const { clientX } = event;
        this.executor.bufferedExecute(
            () => {
                const { data } = this.props;
                if (this.boxRef.current && data.gallery) {
                    const galleryIndex = calculateImageIndexToShow(
                        clientX,
                        this.boxRef.current,
                        data.gallery?.length,
                    );
                    if (this.state.galleryIndex !== galleryIndex) {
                        this.setState((prevState) => ({ ...prevState, galleryIndex }));
                    }
                }
            },
            50,
            true,
        );
    };

    mouseLeave = () => {
        this.executor.bufferedExecute(
            () => {
                this.setState((prevState) => ({ ...prevState, galleryIndex: null }));
            },
            5,
            true,
        );
    };

    render() {
        const { data, style, isServerSide, orientations, autoplay, serverHide } = this.props;
        const { hasImageLoaded, galleryIndex } = this.state;
        // console.log(data)
        const shouldHideImage = !hasImageLoaded && isValidThumbnail(data) && !isServerSide;
        const shapeStyleName = getShapeStyleName(data);
        const fontSizeStyleName = getFontSizeStyleName(data);
        const styleName = `pgoMediaItem ${shapeStyleName} ${fontSizeStyleName} ${
            this.cameFromServer ? '' : shouldHideImage ? 'invisible' : 'appearingItem'
        }`;
        const itemLink =
            data.isParent === '1'
                ? `${getOrientationsPath(orientations)}/${getPathString(data.fullPath)}`
                : `/${singleVideoLink(data)}`;
        const thumbnailSource = getThumbnailSource(data);
        const siteBadge = siteFilterM(data.Site);
        const thumbDims = getImageDims(getThumbnail(data));
        // console.log(thumbDims)
        const itemDims = styleToDims(style);
        // console.log(itemDims)
        // console.log("render")
        // console.log(data)
        return (
            <li styleName={styleName} style={style}>
                <AppLink
                    isVidOpen={data.isParent !== '1'}
                    href={itemLink}
                    stateAction={this.getClickAction(data)}
                    onMouseMove={this.mouseMoved}
                    onMouseLeave={this.mouseLeave}
                    htmlRef={this.boxRef}
                >
                    {data.Title}
                </AppLink>
                {data.isParent === '1' ? (
                    <>
                        <span styleName="bottomLeft">
                            {data.Subscribers ? (
                                <span styleName="subs" title={"Subscribers"}>
                                    <i className="icon-subs" />
                                    {formatViews(data.Subscribers)}
                                </span>) : <></>}
                            <span styleName="views" title={"Views indexed on youlibs"}>
                                <i className="icon-views" />
                                {formatViews(data['Total Views'] as string)}
                            </span>
                        </span>
                        <span styleName="bottomRight">
                            {`${formatViews(data['Total Videos'])} Videos`}
                        </span>
                    </>
                ) : (
                    <>
                        {data.Channel ? (
                            <span styleName="bottomCenter">
                                {starNameToLink(data.Channel, orientations)}
                            </span>
                        ) : (
                            <></>
                        )}
                        <span styleName="bottomRight">
                            {/* <span>{`${data.Quality > 700 ? 'HD' : ''} ${data.Quality}p`}</span> */}
                            <span styleName="duration">
                                <i className="icon-duration" />
                                {getDuration(data)}
                            </span>
                            <span styleName="dateCreated">
                                <i className="icon-date" />
                                {formatDate(data['Date Created'] as string)}
                            </span>
                        </span>
                        <span styleName="bottomLeft">
                            <span styleName="views" title={"Views"}>
                                <i className="icon-views" />
                                {/* data.Popularity +":"+ */ formatViews(data.Views)}
                            </span>
                            {siteBadge}
                        </span>
                    </>
                )}
                {!serverHide ? (
                    <MediaItemGallery
                        itemDims={itemDims}
                        thumbnail={thumbnailSource}
                        alt={`video thumbnail for: ${data.Title}`}
                        onImageLoad={this.imageLoaded}
                        imageIndex={galleryIndex}
                        gallery={data.gallery}
                        autoplay={autoplay && !isServerSide}
                        thumbDims={thumbDims}
                    />
                ) : (
                    <></>
                )}
            </li>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const layoutState = state.notUndoable as LayoutState;
    const libBrowserState = state.undoable.present as LibraryBrowserState;
    return {
        orientations: libBrowserState.libraryBrowserDataKey.orientations,
        isServerSide: layoutState.isServerSide,
    };
};

const mapDispatchToProps = { setLibraryPath, openVideo: openVideoA };

const PgoMediaItem = connect(mapStateToProps, mapDispatchToProps)(PgoMediaItemComponent);

export default PgoMediaItem;
