import { ContentResponse } from 'adult/components/ApiComs/ApiTypes';
import {
    addItemsToCache,
    getIndiciesToRequest,
    getItemsOutOfCache,
    isContentInCache,
    VirtualGridDataCache,
} from 'common/components/VirtualGrid/VirtualGridDataGetterCache/VirtualGridDataCache';
import { gridCacheCleanAfterReqs, gridNumPerRequest } from 'adult/confg/AppConfig';
import { InitialGridData } from 'common/components/VirtualGrid/VirtualGridDataGetter/VirtualGridDataGetter';
import { Dims } from 'common/types/AppTypes';

export interface VGContentRequest {
    startIndex: number;
    numToTake: number;
}

export interface VGContentResponse {
    content: Array<Record<string, unknown>>;
    numItems: number;
    dims: Dims;
}

export type DataFetcher = (
    key: VGContentRequest,
    callback: (res: VGContentResponse) => void,
) => void;

export function getCacheKey(key: VGContentRequest): string {
    const { startIndex, numToTake } = key;
    delete key.startIndex;
    delete key.numToTake;
    const keyString = JSON.stringify(key);
    key.startIndex = startIndex;
    key.numToTake = numToTake;
    return keyString;
}

export default class VirtualGridDataGetterCache {
    private cache: VirtualGridDataCache = {};

    private contentResponseCache: Record<string, VGContentResponse> = {};

    private keyTasks: Record<string, Array<(res: VGContentResponse) => void>> = {};

    private numRequestsDone = 0;

    private numPerRequest = gridNumPerRequest;

    private dataFetcher: DataFetcher;

    constructor(dataFetcher: DataFetcher, numPerReq?: number) {
        this.dataFetcher = dataFetcher;
        if (numPerReq) {
            this.numPerRequest = numPerReq;
        }
    }

    private cleanIfNeeded = () => {
        this.numRequestsDone++;
        if (this.numRequestsDone > gridCacheCleanAfterReqs) {
            this.cache = {};
            this.contentResponseCache = {};
            this.numRequestsDone = 0;
        }
    };

    private getResponseFromCache = (
        cacheKey: string,
        startIndex: number,
        numToTake: number,
    ): VGContentResponse => {
        const items = getItemsOutOfCache(this.cache, cacheKey, startIndex, numToTake) as Array<
            Record<string, unknown>
        >;
        const response = this.contentResponseCache[cacheKey];
        response.content = items;
        // console.log(response)
        return response;
    };

    getContent = (key: VGContentRequest, consumer: (r: VGContentResponse) => void): void => {
        const cacheKey = getCacheKey(key);
        const { startIndex, numToTake } = key;
        const numItems = this.contentResponseCache[cacheKey]
            ? this.contentResponseCache[cacheKey].numItems
            : null;
        if (isContentInCache(this.cache, numItems, cacheKey, startIndex, numToTake)) {
            consumer(this.getResponseFromCache(cacheKey, startIndex, numToTake));
        } else {
            const { startIndexToFetch, numToTakeToFetch } = getIndiciesToRequest(
                this.cache,
                this.numPerRequest,
                cacheKey,
                startIndex,
                numToTake,
            );
            key.startIndex = startIndexToFetch;
            key.numToTake = numToTakeToFetch;

            const requestKey = JSON.stringify(key);
            if (!this.keyTasks[requestKey]) {
                this.keyTasks[requestKey] = [];
            }
            this.keyTasks[requestKey].push(consumer);
            if (this.keyTasks[requestKey].length === 1){
                this.dataFetcher(key, (res) => {
                    const contentResponse = res as ContentResponse;
                    addItemsToCache(this.cache, cacheKey, contentResponse.content, key.startIndex);
                    this.contentResponseCache[cacheKey] = contentResponse;
                    const consumers = this.keyTasks[requestKey];
                    for (let i = 0; i < consumers.length; i++) {
                        consumers[i](this.getResponseFromCache(cacheKey, startIndex, numToTake));
                    }
                    this.cleanIfNeeded();
                });
            }
        }
    };

    addInitialData = (initialData: InitialGridData<VGContentRequest>) => {
        const cacheKey = getCacheKey(initialData.dataKey);
        addItemsToCache(this.cache, cacheKey, initialData.content, initialData.dataKey.startIndex);
        this.contentResponseCache[cacheKey] = initialData;
    };

    clearCache = (keyBit: string) => {
        const keys = Object.keys(this.contentResponseCache);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i].indexOf(keyBit) > -1) {
                delete this.contentResponseCache[keys[i]];
                delete this.cache[keys[i]];
            }
        }
    };
}
