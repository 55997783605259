import * as React from 'react';
import './EpLogo.scss';
import { Component } from 'react';

interface EpLogoProps {}

export default class EpLogo extends Component<EpLogoProps> {
    render() {
        return <span styleName="ep">Eporner</span>;
    }
}
