import { getElementContentWidth } from 'common/components/utils/ElementSizes';

function getGalleryIndexOnImage(width: number, offset: number, numItems: number) {
    return Math.floor(offset / (width / numItems));
}

export function calculateImageIndexToShow(clientX: number, div: HTMLElement, num: number) {
    const offset = clientX - div.getBoundingClientRect().left;
    const width = getElementContentWidth(div);
    let imageIndex = getGalleryIndexOnImage(width, offset, num);
    if (imageIndex < 0) imageIndex = 0;
    if (imageIndex >= num) imageIndex = num - 1;
    return imageIndex;
}
