import * as React from 'react';
import './DateInputField.scss';
import { Component } from 'react';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';

interface DateInputFieldProps {
    value?: string;
    onChange?: (val: string) => void;
}

interface DateInputFieldState {
    valueShown: string;
}

export default class DateInputField extends Component<DateInputFieldProps, DateInputFieldState> {
    private inputCorrectionExecutor = new DebouncingExecutor();

    constructor(props: DateInputFieldProps) {
        super(props);
        this.state = {
            valueShown: props.value,
        };
    }

    componentDidUpdate(
        prevProps: Readonly<DateInputFieldProps>,
        prevState: Readonly<DateInputFieldState>,
        snapshot?: any,
    ): void {
        if (prevProps !== this.props) {
            const { value } = this.props;
            this.setState({ ...prevState, valueShown: value });
        }
    }

    onChange = (e) => {
        const valueInput = e.target.value;
        this.setState((prevState) => ({ ...prevState, valueShown: valueInput }));

        this.inputCorrectionExecutor.bufferedExecute(() => {
            const { valueShown } = this.state;
            const { onChange } = this.props;
            if (valueShown) {
                onChange(valueShown);
            }
        }, 500);
    };

    render() {
        const { valueShown } = this.state;
        return (
            <input styleName="dateInput" type="date" value={valueShown} onChange={this.onChange} />
        );
    }
}
