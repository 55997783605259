import * as React from 'react';
import {Component, createRef, ReactNode} from 'react';
import './FilteringController.scss';
import {connect} from 'react-redux';
import {RootState} from 'adult/state/AdultappStateStore';
import {DataItemField, FilterCriteria} from 'common/types/AppTypes';
import CollapsableMenu from 'common/components/CollapsableMenu/CollapsableMenu';
import StringFilterInput from 'common/components/inputs/StringFilterInput/StringFilterInput';
import {addFilter, LibraryBrowserState,} from 'adult/state/LibraryBrowserState/LibraryBrowserState';
import AppliedFiltersController, {
    AppliedFiltersControllerComponent,
} from 'common/components/LibraryController/FilteringController/AppliedFiltersController/AppliedFiltersController';
import 'common/styles/CommonStyles.scss';
import FilterConfigsPanel
    from 'common/components/LibraryController/FilteringController/FilterConfigs/FilterConfigsPanel/FilterConfigsPanel';
import LibDataFilterRangeInput
    from 'common/components/LibraryController/FilteringController/LibDataFilterRangeInput/LibDataFilterRangeInput';

interface FilteringControllerProps {
    availableFilters: Array<DataItemField>;
    shouldShow: boolean;
    isHomePage: boolean;
    addFilter: (f: FilterCriteria<unknown>) => void;
    className?: string;
}

interface FilteringControllerState {
    selectedFilter: DataItemField;
}

function isFilterAvailable(avaialble: Array<DataItemField>, filter: DataItemField): boolean {
    for (const candidate of avaialble) {
        if (JSON.stringify(candidate) === JSON.stringify(filter)) {
            return true;
        }
    }
    return false;
}

export class FilteringControllerComponent extends Component<
    FilteringControllerProps,
    FilteringControllerState
> {
    private topFiltersPanel = createRef<HTMLDivElement>();

    private appliedFiltersPanel = createRef<AppliedFiltersControllerComponent>();

    constructor(props: FilteringControllerProps) {
        super(props);
        this.state = {
            selectedFilter: null,
        };
    }

    componentDidUpdate(prevProps: FilteringControllerProps): void {
        const { availableFilters } = this.props;
        if (JSON.stringify(prevProps.availableFilters) !== JSON.stringify(availableFilters)) {
            this.setState((prevState) => ({ ...prevState, selectedFilter: null }));
        }
    }

    filterPicked = (filter: DataItemField) => () => {
        this.setState((prevState) => ({ ...prevState, selectedFilter: filter }));
    };

    getFilterInputs = (
        selectedFilter: DataItemField,
        applyFilter: (fc: FilterCriteria<unknown>) => void,
    ): ReactNode => {
        if (!selectedFilter) {
            return <FilterConfigsPanel />;
        }
        if (['MULTI_VALUE_STRING', 'STRING'].indexOf(selectedFilter.type) !== -1) {
            return <StringFilterInput filter={selectedFilter} applyFilter={applyFilter} />;
        }
        return <LibDataFilterRangeInput selectedFilter={selectedFilter} />;
    };

    render() {
        const {
            availableFilters,
            shouldShow,
            addFilter,
            isHomePage,
            className,
        } = this.props;
        if (!isFilterAvailable(availableFilters, this.state.selectedFilter))
            this.state.selectedFilter = null;
        const { selectedFilter } = this.state;
        let hidden = false;
        if (!availableFilters || availableFilters.length === 0 || isHomePage) hidden = true;
        const filters = availableFilters.map((filter) => (
            <span key={filter.name} onClick={this.filterPicked(filter)}>
                {filter.name}
            </span>
        ));
        if (selectedFilter) {
            filters.unshift(
                <span key="standard" onClick={this.filterPicked(null)}>
                    Filters:
                </span>,
            );
        }
        const fullStyleName = `filteringController ${
            shouldShow ? 'shownFilters' : 'hiddenFilters'
        }${hidden ? ' hidden' : ''}`;
        return (
            <>
                {hidden ? <div styleName="filteringController" /> : <></>}
                <div className={className} styleName={fullStyleName}>
                    <div styleName="filtersTopPanel" ref={this.topFiltersPanel}>
                        <CollapsableMenu>
                            <span styleName="filtersBox">
                                {selectedFilter ? selectedFilter.name : 'Filters:'}
                            </span>
                            <div styleName="availableFilters">{filters}</div>
                        </CollapsableMenu>

                        {this.getFilterInputs(selectedFilter, addFilter)}
                    </div>
                    <AppliedFiltersController
                        ref={this.appliedFiltersPanel}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const undoable = state.undoable.present as LibraryBrowserState;
    const notUndoable = state.notUndoable as LibraryBrowserState;
    return {
        isHomePage: undoable.libraryBrowserDataKey.isHomePage,
        availableFilters: notUndoable.libBrowserConfig.availableFilters,
    };
};

const mapPropsToDispatch = {
    addFilter,
};

const FilteringController = connect(
    mapStateToProps,
    mapPropsToDispatch,
)(FilteringControllerComponent);

export default FilteringController;
