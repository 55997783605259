import * as React from 'react';
import './LoadingIndicator.scss';
import { FunctionComponent } from 'react';

interface LoadingIndicatorProps {
    style?: unknown;
}

const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = ({
    style,
}: LoadingIndicatorProps) => {
    return (
        <div className="li" styleName="loadingIndicator" style={style}>
            <div />
            <div />
            <div />
        </div>
    );
};

export default LoadingIndicator;
