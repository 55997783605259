import * as React from 'react';
import {Component, createRef} from 'react';
import './LibraryController.scss';
import NavPath from 'common/components/LibraryController/NavPath/NavPath';
import SortingController from 'common/components/LibraryController/SortingController/SortingController';
import FilteringController from 'common/components/LibraryController/FilteringController/FilteringController';
import Observable from 'common/functions/Observable/Observable';
import FoldingTopDirsPanel, {FoldingTopDirsPanelComponent,} from 'adult/components/FoldingTopDirsPanel/FoldingTopDirsPanel';

interface LibraryControllerProps {
    showFiltersStream: Observable<boolean>;
    scrollStream: Observable<number>;
    isMobile: boolean;
    scrollListener: Observable<number>;
    shouldCollapse: boolean;
}

interface LibraryControllerState {
    shouldShowFilters: boolean;
    isExpanded: boolean;
    showFilterButton: boolean;
}

export default class LibraryController extends Component<
    LibraryControllerProps,
    LibraryControllerState
> {
    private topDirsRef = createRef<FoldingTopDirsPanelComponent>();

    private lastScroll = 0;

    private firstScroll = false;

    private expandScrollTimer = 0;
    private expandScrollTimer2 = 0;

    private isExpanding = false;

    private ignoreScroll = false;

    constructor(props: LibraryControllerProps) {
        super(props);
        this.state = { shouldShowFilters: true, isExpanded: true, showFilterButton: false };
        props.showFiltersStream.subscribe((shouldShow) => {
            const { shouldShowFilters } = this.state;
            if (!this.ignoreScroll && shouldShow !== shouldShowFilters && !this.isExpanding) {
                this.setState((prevState) => ({
                    ...prevState,
                    shouldShowFilters: shouldShow,
                    isExpanded: !shouldShow ? false : prevState.isExpanded,
                }));
            }
        });
        props.scrollStream.subscribe((n) => {
            if(this.ignoreScroll) return;
            if (!this.firstScroll) {
                this.firstScroll = true;
            } else if (n === 0) {
                this.setDirsExpandedScrollCatcher();
                this.setState((prevState) => ({
                    ...prevState,
                    isExpanded: true,
                    showFilterButton: false,
                    shouldShowFilters: true,
                }));
            } else if (
                n !== 0 &&
                n !== this.lastScroll &&
                this.state.isExpanded &&
                !this.isExpanding
            ) {
                this.setDirsExpandedScrollCatcher();
                this.setState((prevState) => ({
                    ...prevState,
                    isExpanded: false,
                    showFilterButton: true,
                }));
            }
            this.lastScroll = n;
        });
    }

    dirsExpanded = (expand: boolean) => {
        const lastScroll = this.props.scrollStream.getLast();
        if ((lastScroll !== 0 && lastScroll !== null) || expand) {
            this.setDirsExpandedScrollCatcher();
            this.setState((prevState) => ({
                ...prevState,
                isExpanded: expand,
                shouldShowFilters: true,
            }));
        }
    };

    setDirsExpandedScrollCatcher = () => {
        const { scrollStream } = this.props;
        scrollStream.disable();
        this.ignoreScroll = true;
        clearTimeout(this.expandScrollTimer);
        clearTimeout(this.expandScrollTimer2);
        this.expandScrollTimer = (setTimeout(() => {
            scrollStream.enable();
            this.expandScrollTimer2 = setTimeout(() => {
                this.ignoreScroll = false;
            }, 500);
        }, 500) as unknown) as number;
    };

    render() {
        const { shouldCollapse } = this.props;
        const { shouldShowFilters, isExpanded, showFilterButton } = this.state;
        const expand = isExpanded || !shouldCollapse;
        const flex = expand ? {} : { display: 'flex' };
        const styleName = `libraryController ${expand ? 'expanded' : 'folded'}`;
        return (
            <>
                <div styleName={styleName} style={{ ...flex }}>
                    <FoldingTopDirsPanel
                        isExpanded={expand}
                        onShowHide={this.dirsExpanded}
                        ref={this.topDirsRef}
                    />
                    {expand ? <br /> : <></>}
                    <div styleName="navSort">
                        <NavPath />
                        <SortingController />
                    </div>
                </div>
                <FilteringController
                    className="fcg"
                    shouldShow={shouldShowFilters}
                />
            </>
        );
    }
}
