import { DynamicInputFieldState } from 'common/components/DynamicInputField/DynamicInputField';

export function getTextWidth(text: string, font: string): number {
    if (typeof document !== 'undefined' && document) {
        const canvas =
            getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }
    return null;
}

export function getComputedMaxWidth(element: Element) {
    if (typeof window !== 'undefined' && window) {
        const computedStyle = window.getComputedStyle(element, null);
        const size = computedStyle.getPropertyValue('max-width');
        return parseInt(size);
    }
    return null;
}

export function workOutNewFontSize(
    text: string,
    element: Element,
    maxWidth: number,
    width: number,
    initSize: number,
): number {
    if (typeof window !== 'undefined' && window) {
        const computedStyle = window.getComputedStyle(element, null);
        const family = computedStyle.getPropertyValue('font-family');
        const weight = computedStyle.getPropertyValue('font-weight');
        const sizeString = computedStyle.getPropertyValue('font-size');
        const multiple = maxWidth / width;
        const newSize = initSize * multiple;
        const newSizeString = sizeString.replace(/[0-9\\.]*/, `${newSize}`);
        const newFont = `${weight} ${newSizeString} ${family}`;
        const newWidth = getTextWidth(text, newFont);
        if (newWidth <= maxWidth) {
            return newSize;
        }
        return workOutNewFontSize(text, element, maxWidth, newWidth, newSize);
    }
    return null;
}

export function getFontSize(element: Element) {
    if (typeof window !== 'undefined' && window) {
        const computedStyle = window.getComputedStyle(element, null);
        const size = computedStyle.getPropertyValue('font-size');
        return parseInt(size);
    }
    return null;
}

export function getParams(
    element: Element,
    text: string,
    initialFontSize: number,
): DynamicInputFieldState {
    if (typeof window !== 'undefined' && window) {
        const computedStyle = window.getComputedStyle(element, null);
        const family = computedStyle.getPropertyValue('font-family');
        const weight = computedStyle.getPropertyValue('font-weight');
        const sizeString = computedStyle.getPropertyValue('font-size');
        const sizeStringInitial = sizeString.replace(/[0-9\\.]*/, `${initialFontSize}`);
        const font = `${weight} ${sizeStringInitial} ${family}`;
        const width = getTextWidth(text, font);
        const maxWidth = getComputedMaxWidth(element);
        if (width <= maxWidth) {
            return { textWidth: width, fontSize: initialFontSize };
        }
        const newSize = workOutNewFontSize(text, element, maxWidth, width, initialFontSize);
        const sizeStringNew = sizeString.replace(/[0-9\\.]*/, `${newSize}`);
        const newFont = `${weight} ${sizeStringNew} ${family}`;
        const newWidth = getTextWidth(text, newFont);
        return { textWidth: newWidth, fontSize: newSize };
    }
    return null;
}
