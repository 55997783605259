import { Dims } from 'common/types/AppTypes';
import { getPageWidth } from 'common/components/utils/ElementSizes';

export interface Coord {
    x: number;
    y: number;
}

export interface GridItemDimsStyle {
    width?: string;
    height?: string;
    // width?: string;
    // paddingTop?: string;
}

export interface GridItemDims {
    dimsPx: Dims;
    dimsStyle: GridItemDimsStyle;
}

export interface IndicesInView {
    visualStart?: number;
    visualContentNum?: number;
    startIndex: number;
    contentNum: number;
    numPerRow?: number;
    autoplayIndex?: number;
}

export function roundDown(n: number): number {
    return Math.floor(n * 100) / 100;
}

export function itemDimsNoGridSize(itemDims: Dims) {}

function mediaQuery(query: string) {
    return window.matchMedia(query).matches;
}

export function getNumPerRow(gridWidth: number, roughDims: Dims): number {
    if (roughDims.height > roughDims.width) {
    }
    let pageWidth = getPageWidth();
    if (pageWidth > gridWidth * 1.3) pageWidth = gridWidth;
    let numPerRow = 5;
    let tallPerRow = 7;
    if (pageWidth < 600) {
        numPerRow = 1;
        tallPerRow = 2;
    } else if (pageWidth < 1180) {
        numPerRow = 2;
        tallPerRow = 4;
    } else if (pageWidth < 1500) {
        numPerRow = 3;
        tallPerRow = 5;
    } else if (pageWidth < 2100) {
        numPerRow = 4;
        tallPerRow = 6;
    }
    // let numPerRow = Math.round(gridDims.width / roughDims.width) || 1;
    // numPerRow = numPerRow === 1 ? 1 : 4;
    return roughDims.height > roughDims.width ? tallPerRow : numPerRow;
}

export function calcGridItemDims(roughDims: Dims, gridDims: Dims): GridItemDims {
    let numPerRow = getNumPerRow(gridDims.width, roughDims);
    if (roughDims.width === '100%') {
        numPerRow = 1;
    }
    const width = roundDown(gridDims.width / numPerRow);
    const height =
        roughDims.height === 0 ? 0 : roundDown((width / roughDims.width) * roughDims.height);
    const widthPercentage = 100 / numPerRow;
    return {
        dimsPx: {
            width,
            height,
        },
        dimsStyle: {
            width: `${width}px`,
            height: `${height}px`,
            // width: `${roundDown(widthPercentage)}%`,
            // paddingTop: `${roundDown((widthPercentage / width) * height)}%`,
        },
    };
}

export function calcNumPerRow(fullWidth: number, itemWidth: number): number {
    if (fullWidth < itemWidth || fullWidth === itemWidth) return 1;
    return Math.round(fullWidth / itemWidth);
}

export function calcGridHeight(numItems: number, itemDims: Dims, fullWidth: number): number {
    const numPerRow = calcNumPerRow(fullWidth, itemDims.width);
    const numRows = Math.floor(numItems / numPerRow);
    return numRows * itemDims.height + (numItems % numPerRow > 0 ? 1 : 0) * itemDims.height;
}

export function calcWhereToPlaceIndex(index: number, itemDims: Dims, fullWidth: number): Coord {
    const numPerRow = calcNumPerRow(fullWidth, itemDims.width);
    const contentColumn = index % numPerRow;
    const contentRow = Math.floor(index / numPerRow);
    const x = itemDims.width * contentColumn;
    const y = contentRow * itemDims.height;
    return { x, y };
}

export function calcStartIndex(minY: number, itemHeight: number, numPerRow: number): number {
    const rowsInvisible = Math.floor(minY / itemHeight);
    return numPerRow * rowsInvisible;
}

function calcShowingRows(minY: number, maxY: number, itemHeight: number): number {
    const viewportHeight = maxY - minY + 1;
    if (viewportHeight <= 0) return 0;
    const topRowMissing = minY % itemHeight;
    const withoutTopRow = viewportHeight - itemHeight + topRowMissing;
    if (withoutTopRow <= 0) return 1;
    if (withoutTopRow <= itemHeight) return 2;
    const numFullRows = Math.floor(withoutTopRow / itemHeight);
    const bottomRowShowing = withoutTopRow % itemHeight;
    return 1 + numFullRows + (bottomRowShowing === 0 ? 0 : 1);
}

export function calcNumItemsInView(
    itemWidth: number,
    itemHeight: number,
    viewWidth: number,
    viewHeight: number,
) {
    const numPerRow = calcNumPerRow(viewWidth, itemWidth);
    const numRows = Math.ceil(viewHeight / itemHeight);
    return numRows * numPerRow;
}

export function calcIndicesInView(
    minY: number,
    maxY: number,
    fullWidth: number,
    itemWidth: number,
    itemHeight: number,
): IndicesInView {
    if (itemWidth === 0 || itemHeight === 0) return { startIndex: 0, contentNum: 0, numPerRow: 0 };
    const numPerRow = calcNumPerRow(fullWidth, itemWidth);
    const startIndex = calcStartIndex(minY, itemHeight, numPerRow);
    const showingRows = calcShowingRows(minY, maxY, itemHeight);
    const contentNum = showingRows * numPerRow;
    let autoplayIndex = null;
    if (numPerRow === 1) {
        const topItemShowing = minY % itemHeight;
        if (topItemShowing / itemHeight > 0.2) {
            autoplayIndex = startIndex + 1;
        } else {
            autoplayIndex = startIndex;
        }
    }
    return { startIndex, contentNum, numPerRow, autoplayIndex };
}

export function calculateGridIndicesInView(
    scroll: number,
    gridSize: Dims,
    itemDims: Dims,
    numItems: number,
): IndicesInView {
    const calculatedIndices = calcIndicesInView(
        scroll,
        scroll + gridSize.height,
        gridSize.width,
        itemDims.width,
        itemDims.height,
    );
    calculatedIndices.contentNum =
        calculatedIndices.startIndex + calculatedIndices.contentNum > numItems
            ? numItems - calculatedIndices.startIndex
            : calculatedIndices.contentNum;
    return calculatedIndices;
}

export function mostVisibleIndex(
    scroll: number,
    gridSize: Dims,
    indiciesShown: IndicesInView,
    itemDims: Dims,
): number {
    const index = indiciesShown.startIndex - (indiciesShown.startIndex % indiciesShown.numPerRow);
    const indexTop = Math.floor(index / indiciesShown.numPerRow) * itemDims.height;
    const topItemHidden = scroll - indexTop;
    const ratioTopItemHidden = topItemHidden / itemDims.height;
    const indexOffset = ratioTopItemHidden > 0.6 ? indiciesShown.numPerRow : 0;
    return index + indexOffset;
}

export function calculateNewScrollPosition(
    gridWidth: number,
    tileDims: Dims,
    firstVisItemIndex: number,
): number {
    const numPerRow = calcNumPerRow(gridWidth, tileDims.width);
    const numRows = Math.floor(firstVisItemIndex / numPerRow);
    return numRows * tileDims.height;
}

export function adjustForIndexSlide(index: number, width: number, itemDims: Dims) {
    const numPerRow = Math.floor(width / itemDims.width);
    const columnOfIndex = index % numPerRow;
    if (columnOfIndex > numPerRow / 2) {
        return index + (numPerRow - columnOfIndex);
    }
    return index;
}
