import * as React from 'react';
import './EmbedOverlay.scss';
import { Component, createRef } from 'react';
import SwipeListener from 'common/functions/SwipeListener/SwipeListener';

interface EmbedOverlayProps {
    onUpSwipe?: () => void;
    site?: string;
}

interface EmbedOverlayState {
    hidden: boolean;
    flashStyle: string;
}

export default class EmbedOverlay extends Component<EmbedOverlayProps, EmbedOverlayState> {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            flashStyle: '',
        };
    }

    setUpSwipeListener = (ref: HTMLDivElement) => {
        if (ref) {
            this.containerRef = ref;
            const { onUpSwipe } = this.props;
            SwipeListener.detectswipe(ref, (swipe: string) => {
                if (swipe === 'u' && onUpSwipe) {
                    onUpSwipe();
                }
            });
        }
    };

    switchClick = () => {
        this.setState((prevState) => ({ ...prevState, hidden: !prevState.hidden }));
    };

    clickedOn = () => {
        this.flash();
    };

    flash = () => {
        this.setState(
            (prevState) => ({ ...prevState, flashStyle: 'flash1' }),
            () => {
                setTimeout(() => {
                    this.setState(
                        (prevState) => ({ ...prevState, flashStyle: 'flash2' }),
                        () => {
                            setTimeout(() => {
                                this.setState((prevState) => ({ ...prevState, flashStyle: '' }));
                            }, 250);
                        },
                    );
                }, 250);
            },
        );
    };

    render() {
        const { site } = this.props;
        const { hidden, flashStyle } = this.state;
        const styleName = `embedOverlay ${hidden ? 'folded' : ''} ${flashStyle} ${
            site ? site.toLowerCase() : ''
        }`;
        return (
            <div styleName={styleName} ref={this.setUpSwipeListener} onClick={this.clickedOn}>
                <div />
                <div />
                <div />
                <div />
                <div styleName="switch" onClick={this.switchClick} />
                <span styleName="overlayText">Remove Touch Overlay</span>
            </div>
        );
    }
}
