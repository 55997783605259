import { Coord, Dims, PosStyle } from 'common/types/AppTypes';

function getMaxDims(
    viewWidth: number,
    viewHeight: number,
    orientWidth: number,
    orientHeight: number,
): Dims {
    if (viewHeight < 0) return { width: 0, height: 0 };
    let maxHeight;
    let maxWidth;
    const r1 = orientWidth / orientHeight;
    const r2 = viewWidth / viewHeight;
    if (r1 < r2) {
        maxHeight = viewHeight;
        maxWidth = (viewHeight * orientWidth) / orientHeight;
    } else {
        maxWidth = viewWidth;
        maxHeight = (viewWidth * orientHeight) / orientWidth;
    }
    return { width: maxWidth, height: maxHeight };
}

function getOptimalAxisOffset(
    tileAxisPos: number,
    tileAxisSize: number,
    previewAxisSize: number,
    fullAxisSize: number,
): number {
    const tileCenter = tileAxisPos + tileAxisSize / 2;
    let offset;
    if (tileCenter - previewAxisSize / 2 < 0) {
        offset = 0;
    } else if (tileCenter + previewAxisSize / 2 > fullAxisSize) {
        offset = fullAxisSize - previewAxisSize;
    } else {
        offset = tileCenter - previewAxisSize / 2;
    }
    return offset;
}

function calculatePosition(
    tileX: number,
    tileY: number,
    viewWidth: number,
    viewHeight: number,
    tileWidth: number,
    tileHeight: number,
    previewWidth: number,
    previewHeight: number,
): PosStyle {
    const dims = [
        { x: 0, y: 0, width: viewWidth, height: tileY, pos: 'top' },
        {
            x: tileX + tileWidth,
            y: 0,
            width: viewWidth - tileX - tileWidth,
            height: viewHeight,
            pos: 'right',
        },
        {
            x: 0,
            y: tileY + tileHeight,
            width: viewWidth,
            height: viewHeight - tileY - tileHeight,
            pos: 'bottom',
        },
        { x: 0, y: 0, width: tileX, height: viewHeight, pos: 'left' },
    ];
    let maxDims = { width: -1, height: -1 };
    let index = -1;
    for (let i = 0; i < dims.length; i++) {
        const dim = getMaxDims(dims[i].width, dims[i].height, previewWidth, previewHeight);
        if (maxDims.width < dim.width) {
            maxDims = dim;
            index = i;
        }
    }
    const quadrant = dims[index];
    if (maxDims.width > previewWidth) {
        maxDims.width = previewWidth;
        maxDims.height = previewHeight;
    }
    const res: PosStyle = { top: 0, left: 0, height: 0, width: 0 };
    const optimalHorizonOffset = getOptimalAxisOffset(tileX, tileWidth, maxDims.width, viewWidth);
    const optimalVertOffset = getOptimalAxisOffset(tileY, tileHeight, maxDims.height, viewHeight);
    switch (quadrant.pos) {
        case 'top':
            res.top = tileY - previewHeight;
            res.left = optimalHorizonOffset;
            break;
        case 'right':
            res.top = optimalVertOffset;
            res.left = tileX + tileWidth;
            break;
        case 'left':
            res.top = optimalVertOffset;
            res.left = tileX - previewWidth;
            break;
        case 'bottom':
            res.left = optimalHorizonOffset;
            res.top = tileY + tileHeight;
            break;
        default:
            break;
    }

    res.width = maxDims.width;
    res.height = maxDims.height;
    return res;
}

export function calcPreviewPosition(
    areaDims: Dims,
    triggerDims: Coord,
    previewImageDims: Dims,
): PosStyle {
    const areaToPut = calculatePosition(
        triggerDims.x,
        triggerDims.y,
        areaDims.width,
        areaDims.height,
        triggerDims.width,
        triggerDims.height,
        previewImageDims.width,
        previewImageDims.height,
    );
    return areaToPut;
}
