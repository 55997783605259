import * as React from 'react';
import { ChangeEvent, Component } from 'react';
import './DynamicInputField.scss';
import { getFontSize, getParams } from 'common/components/DynamicInputField/TextSizeFunctions';

export interface DynamicInputFieldState {
    textWidth: number;
    fontSize: number;
}

interface DynamicInputFieldProps {
    type: string;
    value?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    minLength?: number;
}

function valueWithMinLength(value: string, length: number) {
    if (!length) return value;
    if (value.length < length) {
        value = new Array(length).join(value[0]);
    }
    return value;
}

export default class DynamicInputField extends Component<
    DynamicInputFieldProps,
    DynamicInputFieldState
> {
    private initialFontSize: number = null;

    private elementRef: HTMLInputElement = null;

    constructor(props: DynamicInputFieldProps) {
        super(props);
        this.state = {
            textWidth: 0,
            fontSize: null,
        };
    }

    fieldRef = (ref: HTMLInputElement) => {
        if (ref) {
            this.initialFontSize = getFontSize(ref);
            this.elementRef = ref;
            this.applyResize(ref);
        }
    };

    valueChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.applyResize(event.target);
        const { onChange } = this.props;
        if (onChange) {
            onChange(event);
        }
    };

    applyResize = (el: HTMLInputElement) => {
        const { minLength } = this.props;
        const minString = valueWithMinLength(el.value, minLength);
        const params = getParams(el, minString, this.initialFontSize);
        this.setState(params);
    };

    render() {
        const { onClick, onChange, value, type, minLength } = this.props;
        const minString = valueWithMinLength(value, minLength);
        const { textWidth, fontSize } = this.state;
        let onChangeFunc = this.valueChange;
        const style = {};
        if (textWidth) style.width = textWidth;
        if (fontSize) style.fontSize = fontSize;
        if (onChange) {
            onChangeFunc = onChange;
            if (this.elementRef) {
                const params = getParams(this.elementRef, minString, this.initialFontSize);
                if (params.textWidth) style.width = params.textWidth;
                if (params.fontSize) style.fontSize = params.fontSize;
            }
        }
        return (
            <input
                styleName="dynamicInput"
                type={type}
                ref={this.fieldRef}
                onChange={onChangeFunc}
                style={style}
                onClick={onClick}
                value={value}
            />
        );
    }
}
