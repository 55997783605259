export function adjustScrollForFiller(scroll: number, filler: number) {
    let scrollWithFiller = scroll - (filler || 0);
    scrollWithFiller = scrollWithFiller < 0 ? 0 : scrollWithFiller;
    return scrollWithFiller;
}

export function getItemsStartIndex(startIndexShown: number, buffer: number): number {
    const bufferedStart = startIndexShown - buffer;
    const startToTake = bufferedStart < 0 ? 0 : bufferedStart;
    return startToTake;
}

export function getBufferedIndicies(
    startIndex: number,
    numToTake: number,
    buffer: number,
    max: number,
) {
    const bufferedStart = getItemsStartIndex(startIndex, buffer);
    const bufferedNum = numToTake + buffer * 2;
    const num = bufferedStart + bufferedNum > max ? max - bufferedStart : bufferedNum;
    return { startIndex: bufferedStart, numToTake: num };
}

export function getBufferedOffset(startIndex: number, buffers: number, length: number) {
    const posIndexStart = startIndex - buffers;
    const topOffset = (posIndexStart < 0 ? 0 : posIndexStart) * length;
    return topOffset;
}

export function getQuadOffset(startIndex: number, numPerQuad: number, length: number) {
    const quadNum = Math.floor(startIndex / numPerQuad);
    const topOffset = (quadNum * numPerQuad) * length;
    return topOffset;
}

export function getQuadrantIndicies(
    startIndex: number,
    numToTake: number,
    numPerQuad: number,
    max: number,
) {
    const quadNum = Math.floor(startIndex / numPerQuad);
    const start = quadNum * numPerQuad;
    let num = numPerQuad;
    if (startIndex + numToTake > start + numPerQuad) {
        num *= 2;
    }
    if (start + num > max) {
        const diff = start + num - max;
        num -= diff;
    }
    return { startIndex: start, numToTake: num };
}

export function areCurrentlyShown(
    startToShow: number,
    numToShow: number,
    startShown: number,
    numShown: number,
) {
    const lastIndexToShow = startToShow + numToShow - 1;
    const lastIndexShown = startShown + numShown - 1;
    return (
        startToShow >= startShown &&
        startToShow <= lastIndexShown &&
        lastIndexToShow >= startShown &&
        lastIndexToShow <= lastIndexShown
    );
}
