import * as React from 'react';
import {createRef, ReactElement} from 'react';
import {VirtualGridCanvas} from 'common/components/VirtualGridAsync/VirtualGridCanvas/VirtualGridCanvas';
import {
    calcNumPerRow,
    IndicesInView,
} from 'common/components/VirtualGrid/VirtualGridCanvas/calculators/VirtualGridCanvasCalculators';
import DebouncingExecutor from 'common/functions/DebouncingExecutor/DebouncingExecutor';
import {VGDataGetter} from 'common/components/VirtualGrid/VirtualGrid';
import VirtualGridIndexDisplay
    from 'common/components/VirtualGrid/VirtualGridIndexDisplayComponent/VirtualGridIndexDisplay';
import Observable from 'common/functions/Observable/Observable';
import {Dims} from 'common/types/AppTypes';
import PagingController from 'common/components/PagingController/PagingController';
import {currentPage, numPages} from 'common/components/PagingController/PagingCalculator';
import {numPerPage} from 'adult/confg/AppConfig';
import {
    addBufferIndexes,
    getFullItemSize,
    getGridParams,
    getIndiciesInView,
    getNearestStartIndex,
    getNumItemsInView,
} from 'common/components/VirtualGridAsync/VirtualGridControllerCalculator';
import './VirtualGridCanvas.scss';
import {injectAds, showingAds} from "common/components/IntegratedAd/IntegrationCalcs";

const bufferRows = 1;
// const bufferRows = 0;

interface VirtualGridControllerProps {
    itemGetter: VGDataGetter;
    scrollStream: Observable<number>;
    viewPort: HTMLElement;
    horizontal?: boolean;
    vidsOpen?: boolean;
    onResize?: (dims: Dims) => void;
    indexStream?: Observable<number>;
    initialStartIndex: number;
    startIndexListener?: (n: number) => void;
    noPaging?: boolean;
    autoplayDisable?: boolean;
}

interface VirtualGridControllerState {
    childItems: Array<ReactElement>;
    indicesInView: IndicesInView;
    numItems: number;
    aspectRatio?: number;
    itemHeight?: number;
    dynHeight?: boolean;
}

export class VirtualGridController extends React.PureComponent<
    VirtualGridControllerProps,
    VirtualGridControllerState
> {
    private gridRef = createRef<HTMLDivElement>();

    private lastScroll = 0;

    private debouncer = new DebouncingExecutor();

    private scrollStreamTimeout = 0;

    private lastDataRequest = 0;

    constructor(props: VirtualGridControllerProps) {
        super(props);
        const initialData = props.itemGetter.getInitialData();
        const childItems = initialData ? initialData.items : [];
        const startIndex = props.initialStartIndex || 0;
        const contentNum = childItems.length;

        if (showingAds(props.itemGetter.dataKey)){
            injectAds({ startIndex, contentNum }, childItems);
        }
        this.state = {
            childItems,
            indicesInView: {
                startIndex: props.initialStartIndex || 0,
                contentNum: childItems.length,
                numPerRow: initialData.config.numPerRow,
                autoplayIndex: props.initialStartIndex || 0,
            },
            numItems: initialData ? initialData.config.numItems : 0,
            aspectRatio:
                initialData && initialData.config.dims
                    ? initialData.config.dims.width / initialData.config.dims.height
                    : undefined,
            itemHeight: initialData.config.itemHeight,
            dynHeight: initialData.config.dynHeight,
        };
    }

    componentDidMount(): void {
        this.componentDidUpdate({}, {});
    }

    componentDidUpdate(
        prevProps: Readonly<VirtualGridControllerProps>,
        prevState: Readonly<VirtualGridControllerState>,
        snapshot?: any,
    ): void {
        const {
            itemGetter,
            scrollStream,
            indexStream,
            startIndexListener,
            horizontal,
        } = this.props;
        const prevIndices = prevState.indicesInView;
        const prevStartIndex = prevIndices?.visualStart
            ? prevIndices?.visualStart
            : prevIndices?.startIndex;
        const currentStartIndex = this.getStartIndex();

        if (prevStartIndex !== currentStartIndex && startIndexListener) {
            startIndexListener(this.getStartIndex());
        }
        if (indexStream && indexStream !== prevProps.indexStream) {
            indexStream.subscribe((index) => {
                this.scrollToItem(index, false);
            });
        }
        if (scrollStream && scrollStream !== prevProps.scrollStream) {
            scrollStream.subscribe((scroll) => {
                this.debouncer.bufferedExecute(
                    () => {
                        this.lastScroll = scroll;
                        this.updateGrid();
                    },
                    100,
                    true,
                );
            });
        }
        if (itemGetter && itemGetter !== prevProps.itemGetter) {
            itemGetter.onDataChange((config) => {
                this.setState(
                    (fromState) => ({
                        ...fromState,
                        indicesInView: {
                            startIndex: 0,
                            numPerRow: config.numPerRow,
                            contentNum: 0,
                        },
                        childItems: [],
                        numItems: config.numItems,
                        dynHeight: config.dynHeight,
                        aspectRatio: config.dims
                            ? config.dims.width / config.dims.height
                            : undefined,
                    }),
                    () => {
                        this.scrollToItem(config.startIndex, true);
                    },
                );
            });
        }
    }

    updateGrid = async (dataChangeEvent?: boolean) => {
        const { horizontal } = this.props;
        const { numItems } = this.state;
        const indices = getIndiciesInView(
            this.getGridParams(),
            horizontal,
            this.lastScroll,
            numItems,
        );
        this.drawGrid(indices, dataChangeEvent);
    };

    roundFloat = (num: number) => {
        return Math.round(num * 100) / 100;
    };

    drawGrid = async (indices: IndicesInView, dataChangeEvent: boolean, callback?: () => void) => {
        if (
            indices &&
            (indices.startIndex !== this.getStartIndex() ||
                indices.contentNum > this.getContentNum() ||
                dataChangeEvent)
        ) {
            indices = addBufferIndexes(indices, this.getGridParams(), bufferRows);
            const { itemGetter, horizontal } = this.props;
            const dataRequest = Date.now();
            this.lastDataRequest = dataRequest;
            const childItems = await itemGetter.getItemsAsync(indices);
            // const childItems = await itemGetter.getItemsAsync(indices);
            if (this.lastDataRequest === dataRequest) {
                this.setState(
                    (prevState) => ({
                        ...prevState,
                        indicesInView: indices,
                        childItems,
                    }),
                    () => {
                        const { dynHeight, itemHeight } = this.state;
                        const fullItemSize = getFullItemSize(this.gridRef.current);
                        if (
                            dynHeight &&
                            this.roundFloat(itemHeight) !== this.roundFloat(fullItemSize.height)
                        ) {
                            this.setState(
                                (newPrevState) => ({
                                    ...newPrevState,
                                    itemHeight: fullItemSize.height,
                                    aspectRatio: undefined,
                                }),
                                () => {
                                    const { indicesInView } = this.state;

                                    this.scrollToItem(indicesInView.visualStart);
                                    if (callback) {
                                        callback();
                                    }
                                },
                            );
                        } else if (callback) {
                            callback();
                        }
                    },
                );
            }
        } else if (indices.autoplayIndex !== this.state.indicesInView.autoplayIndex) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    indicesInView: {
                        ...prevState.indicesInView,
                        autoplayIndex: indices.autoplayIndex,
                    },
                }),
                () => {
                    if (callback) {
                        callback();
                    }
                },
            );
        } else if (callback) {
            callback();
        }
    };

    noScrollsAfterResize = () => {
        const { scrollStream } = this.props;
        clearTimeout(this.scrollStreamTimeout);
        scrollStream.disable();
        this.scrollStreamTimeout = setTimeout(() => {
            scrollStream.enable();
        }, 50);
    };

    onResize = () => {
        this.debouncer.bufferedExecute(
            async () => {
                const { itemHeight, dynHeight } = this.state;
                const { horizontal } = this.props;
                if (this.gridRef.current) {
                    const fullItemSize = getFullItemSize(this.gridRef.current);
                    this.noScrollsAfterResize();
                    if (dynHeight && itemHeight !== fullItemSize.height) {
                        this.setState(
                            (prevState) => ({ ...prevState, itemHeight: fullItemSize.height }),
                            () => {
                                this.scrollToItem(this.getStartIndex());
                            },
                        );
                    } else {
                        this.scrollToItem(this.getStartIndex());
                    }
                }
            },
            100,
            true,
        );
    };

    areValidGridParams = (gridParams: ReturnType<typeof getGridParams>) => {
        if (!gridParams || !gridParams.itemHeight || !gridParams.itemWidth) {
            return false;
        }
        return true;
    };

    scrollToItem = async (index: number, dataChangeEvent?: boolean) => {
        if (!this.gridRef.current) return;
        const { horizontal, scrollStream } = this.props;
        const { numItems, indicesInView } = this.state;
        const gridParams = this.getGridParams();
        if (!this.areValidGridParams(gridParams)) return;
        const contentNum = getNumItemsInView(gridParams, horizontal);
        index = getNearestStartIndex(this.getGridParams(), index, horizontal, numItems);
        const { numPerRow } = this.state.indicesInView;
        const indices = {
            startIndex: index,
            contentNum,
            numPerRow,
            autoplayIndex: index,
        };
        scrollStream.disable();
        this.drawGrid(indices, dataChangeEvent, () => {
            this.goToFirstElem();
            scrollStream.enable();
        });
    };

    getGridParams = () => {
        const { viewPort } = this.props;
        const gridParams = getGridParams(this.gridRef.current, viewPort);
        return gridParams;
    };

    getStartIndex = () => {
        const { indicesInView } = this.state;
        return indicesInView.visualStart ? indicesInView.visualStart : indicesInView.startIndex;
    };

    getContentNum = () => {
        const { indicesInView } = this.state;
        return indicesInView.visualContentNum
            ? indicesInView.visualContentNum
            : indicesInView.contentNum;
    };

    goToFirstElem = () => {
        const { viewPort, horizontal } = this.props;
        const { indicesInView } = this.state;
        if (this.getStartIndex() === 0) {
            if (horizontal) {
                viewPort.scrollLeft = 0;
            } else {
                viewPort.scrollTop = 0;
            }
        } else {
            const gridItems = this.gridRef.current.children;
            if (gridItems.length > 3) {
                this.noScrollsAfterResize();
                if (horizontal) {
                    const firstChild = gridItems[1 + bufferRows] as HTMLDivElement;
                    if (firstChild) {
                        const newScroll = firstChild.offsetLeft;
                        viewPort.scrollLeft = newScroll;
                    }
                } else {
                    // const gridParams = this.getGridParams();
                    const numPerRow = this.getNumPerRow();
                    const firstChild = gridItems[1 + bufferRows * numPerRow] as HTMLDivElement;
                    if (firstChild) {
                        const newScroll = firstChild.offsetTop;
                        viewPort.scrollTop = newScroll;
                    }
                }
            }
        }
    };

    getNumPerRow = () => {
        const gridParams = this.getGridParams();
        const numPerRow = calcNumPerRow(gridParams.gridWidth, gridParams.itemWidth);
        return numPerRow;
    };

    render() {
        const { horizontal, vidsOpen, noPaging, autoplayDisable } = this.props;
        const {
            indicesInView,
            numItems,
            childItems,
            aspectRatio,
            itemHeight,
            dynHeight,
        } = this.state;
        const heightToSet = dynHeight && itemHeight ? `${itemHeight}px` : undefined;
        const showStart = this.getStartIndex();
        const showContentNum = this.getContentNum();
        let childrenWithAuto = childItems;
        if (!autoplayDisable && this.getNumPerRow() === 1) {
            const autoplayArrayIndex = indicesInView.autoplayIndex - indicesInView.startIndex;
            childrenWithAuto = childItems.map((item, i) => {
                if (i === autoplayArrayIndex) {
                    return React.cloneElement(item, { autoplay: true });
                }
                return item;
            });
        }
        return (
            <>
                {numItems === 0 ? <div styleName="noItems">No Results!</div> : <></>}
                <VirtualGridCanvas
                    startIndex={indicesInView.startIndex}
                    numItems={numItems}
                    ref={this.gridRef}
                    onResize={this.onResize}
                    horizontal={horizontal}
                    ratio={aspectRatio}
                    vidOpen={vidsOpen}
                    numPerRow={dynHeight ? indicesInView.numPerRow : undefined}
                    itemHeight={heightToSet}
                >
                    {childrenWithAuto}
                </VirtualGridCanvas>
                {horizontal ? (
                    <></>
                ) : (
                    <VirtualGridIndexDisplay
                        startIndex={showStart + 1}
                        numItems={numItems}
                        endIndex={Math.min(showStart + showContentNum, numItems)}
                        onValueChange={this.scrollToItem}
                    />
                )}
                {noPaging ? (
                    <></>
                ) : (
                    <PagingController
                        numPages={numPages(numItems, numPerPage)}
                        currentPage={currentPage(indicesInView.startIndex, numPerPage)}
                        pagesAround={2}
                    />
                )}
            </>
        );
    }
}
