import {
    VideoPlayerController,
    VideoPlayerPositioning,
} from 'common/components/VideoPlayer/VideoPlayerController';
import { Coord, Dims } from 'common/types/AppTypes';
import { ContentViewerConfig } from 'common/components/VideoPlayer/VideoPlayer';
import distributeArea from 'common/components/VideoPlayer/MultiVideoPlayerController/AreaDistributionCalculator';
import { getSpaceTakenUpByPlayerBoxes } from 'common/components/VideoPlayer/VideoPlayerCalculator';

export default class MultiVideoPlayerController implements VideoPlayerController {
    getPlayerPositionings = (
        maxDims: Dims,
        config: ContentViewerConfig,
        numPlayers: number,
        shownPlayerIndex: number,
        centerCoords: boolean,
    ): VideoPlayerPositioning => {
        const playerCoords = distributeArea(maxDims, 1.6, numPlayers, centerCoords);
        const playerStyles = playerCoords.map((coord, i) => ({
            transform: `translate(${coord.x}px,${coord.y}px)`,
            width: coord.width,
            height: coord.height,
        }));
        const areaTakenUp = getSpaceTakenUpByPlayerBoxes(playerCoords);
        return { playerCoords, playerStyles, areaTakenUp };
    };
}
